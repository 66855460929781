.page404 {
	min-height: 100svh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;

	&__bg {
		@include fill();
		position: fixed;

		&:after {
			content: "";
			@include fill();
			background: linear-gradient(180deg, rgba(40, 41, 54, 0.70) -5.94%, rgba(40, 41, 54, 0.00) 106.27%);
		}
	}

	&-content {
		position: relative;
		padding-bottom: 4rem;

		&__title {
			svg {
				width: 80%;
				aspect-ratio: 58.4/19.8;
			}

			span {
				font-family: $F_main;
				font-weight: 500;
			}
		}

		&__subtitle {
			position: absolute;
			left: 40%;
			bottom: 7rem;
			color: #fff;
			font-size: var(--fs-h1-small);
			font-family: $F_title;
			width: 15rem;
		}

		.arrow-link {
			position: absolute;
			left: 40%;
			bottom: 4rem;
		}
	}
}


@include for-tablet {
	.page404 {
		&-content {
			padding-bottom: 0;

			&__title {
				svg {
					width: 100%;
				}
			}

			&__subtitle {
				font-size: var(--fs-h2);
				bottom: 2.725rem;
				left: 50%;
			}

			.arrow-link {
				left: 50%;
				bottom: 0;
			}
		}
	}
}

@include for-phone {
	.page404 {
		&-content {
			padding-bottom: 0;

			&__title {
				svg {
					width: 100%;
				}
			}

			&__subtitle {
				font-size: var(--fs-h1-small);
				bottom: 1.5rem;
				width: 8.25rem;
			}

			.arrow-link {
				font-size: var(--fs-h6);
				white-space: nowrap;

				svg {
					width: 0.5rem;
					height: 0.5rem;
				}
			}
		}
	}
}