.page-delivery {
	.content-grid {
		gap: 2rem 2.5rem;
	}

	.content-grid-desc, .content-grid-main-right {
		&:not(:last-child) {
			margin-bottom: 3rem;
		}
	}
}

@include for-tablet {
	.page-delivery {
		.h1-small {
			font-size: var(--fs-h4);
			margin-bottom: 1rem;
			margin-top: 3rem;
		}

		.content-grid-full:first-child {
			.h1-small {
				margin-top: 0;
			}
		}

		.content-grid-desc, .content-grid-main-right {
			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}
		}

		.p2 {
			font-size: var(--fs-p3);
			line-height: 130%;
			letter-spacing: 0.02rem;
		}
	}

	.delivery-contact-itm {
		.h3 {
			margin-bottom: .25rem;
		}
	}

}

@include for-phone {
	.delivery-advantages {
		margin-top: 1rem;
	}

	.page-delivery {
		.content-grid-desc, .content-grid-main-right {
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}

		.h1-small {
			font-size: var(--fs-h3);
		}

		.h3 {
			font-size: var(--fs-h4);
		}

	}

	.delivery-contact-itm {
		.h3 {
			margin-top: 1rem;
		}
	}
}