html {
	font-size: 1.1713vw;
	background: var(--ui-bg-light, #F0F3FA);
	overflow-anchor: none;

	@include for-desktop-big {
		font-size: 22.48px;
	}

	@include for-tablet {
		font-size: 2.666667vw;
	}

	@include for-phone {
		font-size: 5vw;
	}

	&.scroll-block:not(.fp-enabled) {
		overflow: hidden !important;
		padding-right: var(--scrollWidth);

		body {
			pointer-events: none;
		}

		.header, .fly-chat-block {
			padding-right: var(--scrollWidth);
		}
	}

}

body{
	overflow-anchor: none;
}

.compensate-for-scrollbar {
	.header {
		padding-right: var(--scrollWidth);
	}
}

.animate-line {
	overflow: hidden;
}

.js-tab-content {
	height: 0;
	overflow: hidden;
}

.preloader {
	&.load {
		pointer-events: none;
	}
}

.wrapper {
	width: 100%;
	min-height: 100%;

	&:has(.footer-help) {
		.main {
			min-height: calc(100vh - 43.9rem);
		}
	}
}

.main {
	padding-top: 6.5rem;
	min-height: calc(100vh - 16.4rem);
}

.sticky {
	position: sticky;
	top: 6rem;
}

.main-bg-blur {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--bg-blur-dark, rgba(49, 60, 70, 0.50));
	z-index: 8;
	backdrop-filter: blur(16px);
	opacity: 0;
	cursor: pointer;
	pointer-events: none;
	will-change: opacity;
	transition: all .1s;

	@include for-retina {
		background: rgba(40, 41, 54, 0.8);
		backdrop-filter: none;
	}

	&.active {
		pointer-events: auto;
	}
}

.fill-img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: cover;
}

.column-sb {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.arrow-link {
	display: inline-flex;
	align-items: center;
	color: #fff;
	text-decoration: none;
	transition: color .2s ease-in-out;
	font-family: $F_title;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		fill: #fff;
		margin-left: .5rem;
		transition: all .2s ease-in-out;
	}

	@include hover {
		color: var(--ui-accent-1);

		svg {
			fill: var(--ui-accent-1);
		}
	}

	&--accent {
		color: var(--ui-accent-1);

		svg {
			fill: var(--ui-accent-1);
		}

		@include hover {
			color: var(--ui-accent-2);
			svg {
				fill: var(--ui-accent-2);
			}
		}
	}

	&--stroke {
		svg {
			stroke: var(--ui-accent-1);
		}

		@include hover {
			svg {
				stroke: var(--ui-accent-2);
			}
		}
	}

	&--document-link {
		svg {
			width: 1rem;
			height: 1rem;
			align-self: flex-end;
			margin-bottom: .25rem;
		}
	}

	&.disabled {
		pointer-events: none;
		color: rgba(0, 115, 99, 0.3);

		svg {
			fill: rgba(0, 115, 99, 0.3);
		}
	}
}

.owl-stage {
	display: flex;
	height: 100%;

	&-outer {
		height: 100%;
		overflow: hidden;
		width: 100%;
	}
}

.progress-bar {
	position: relative;
	width: 100%;
	height: 4px;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 4px;
		background: #fff;
	}

	&__val {
		position: absolute;
		right: 100%;
		top: 0;
		height: 100%;
		width: 100%;
		background: var(--ui-accent-1);
		transform: translate(0%, 0px);
	}
}

.form-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 2.5rem;

	&--sticky {
		position: sticky;
		bottom: -.5rem;
		left: 0;
		margin-bottom: -.5rem;
		margin-top: 4rem;
		z-index: 1;

		.check_box__item {
			background: var(--ui-bg-3);
			border-color: var(--ui-bg-3);
		}

		&:before {
			content: "";
			position: absolute;
			left: -50%;
			right: -2.5rem;
			bottom: -2rem;
			top: -2rem;
			background: var(--ui-bg-2, #E2E8F0);
		}

		.arrow-link {
			position: relative;
		}
	}

	.check_box__item {
		background: var(--ui-bg-3);
	}
}

.owl-nav {
	display: flex;
	align-self: center;

	&.disabled {
		display: none;
	}

	button {
		width: 2rem;
		height: 2rem;

		&:not(:last-child) {
			margin-right: 1rem;
		}

		svg {
			width: 100%;
			height: 100%;
			fill: var(--ui-accent-1);
			transition: all .2s ease;
			will-change: transform;
		}

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		@include hover {
			svg {
				fill: var(--ui-accent-2)
			}
		}
	}

	.owl-prev {
		svg {
			transform: scaleX(-1);
		}
	}
}

.style-table {
	position: relative;
	width: 100%;
	border-spacing: 0;
	z-index: 1;

	.sort {
		position: relative;
		cursor: pointer;
		transition: all .3s;
		padding-right: 1.5rem;

		@include hover {
			color: var(--ui-accent-1);
		}

		&__text {
			position: relative;
			display: inline-block;
		}

		&__ico {
			position: absolute;
			left: 100%;
			top: 50%;
			width: 1rem;
			height: 1rem;
			margin-top: -.5rem;
			margin-left: .5rem;
			opacity: 0;
			transition: all .3s;
			transform: rotate(180deg);
		}

		&.sort-active {
			.sort__ico {
				opacity: 1;
			}
		}

		&.sort-asc {
			.sort__ico {
				transform: rotate(0deg);
			}
		}
	}

	th {
		@extend .s4;
		font-weight: 400;
		line-height: 110%;
		letter-spacing: 0.015rem;
		vertical-align: bottom;
		text-align: left;
		padding: 1rem 1.44rem;
	}

	td, th {
		border-bottom: 1px solid var(--ui-gray-3, #C6CBD6);
		transition: background-color .2s ease;

		&:first-child {
			padding-left: 0;
		}
	}

	td {
		font-weight: 400;
		line-height: 110%;
		letter-spacing: 0.02rem;
		padding: 1.44rem;
	}

	.tr-link {
		cursor: pointer;

		@include hover {
			td {
				color: var(--ui-accent-1);
			}
		}
	}

	&.catalog-table {
		thead tr {
			will-change: transform;
			backface-visibility: hidden;
			background: var(--ui-bg-2);
		}

		.tr-link {
			@include hover {
				td {
					color: var(--ui-accent-1);
				}
			}
		}
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem 0;

	ul {
		display: flex;
		align-items: center;
		list-style: none;
		gap: 1.5rem;
		flex-wrap: wrap;

		li:first-child {
			svg {
				transform: translateY(-1px);
			}
		}

		li:last-child {
			svg {
				transform: scaleX(-1);
			}
		}
	}

	&-itm {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		@extend .h4;

		&.active {
			color: var(--ui-accent-1);
		}

		svg {
			width: 1rem;
			height: 1rem;
			fill: var(--font-primary, #313346);
			margin-top: -.125rem;
		}

		@include hover {
			svg {
				fill: var(--ui-accent-1);
			}
		}

		&.disabled {
			pointer-events: none;
			color: var(--ui-no-active);

			svg {
				fill: var(--ui-no-active);
			}
		}
	}

	&-more {
		@extend .h4;
		color: var(--font-primary);
		transition: all .2s ease;

		@include hover {
			color: var(--ui-accent-1);
		}
	}
}

.catalog-table .row.lazyloader {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.row.lazyloader {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 2.5rem;
	margin-bottom: -2.7rem;
	margin-top: 1.4rem;

	.pagination-more {
		position: relative;
		grid-column: 4/8;
		text-align: left;
	}
}

.js-ajax-modal {
	cursor: pointer;
}

.skeleton {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: var(--ui-bg-3, #D8DFEB);
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		width: 50%;
		top: 0;
		bottom: 0;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
		animation: skeleton_after 2s ease-in-out infinite;
	}
}

.breadcrumb {
	margin-bottom: 1rem;

	&-mobile {
		display: none;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		align-items: center;
		gap: .5rem 0;
	}

	li {
		display: flex;
		align-items: center;

		&:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 1rem;
			height: 1rem;
			background: url("../img/svg/shewron-up-16.svg");
			background-size: contain;
			margin: 0 1rem
		}
	}

	&-itm {
		font-weight: 400;
		line-height: 110%;
		letter-spacing: 0.02rem;
		display: inline-block;
	}
}

li:has(span.breadcrumb-itm) {
	&:after {
		background-image: url("../img/svg/shewron-up-16-gray.svg");
		opacity: .5;
	}
}

span.breadcrumb-itm {
	color: var(--ui-no-active);
}

.page-head-nav {
	margin-top: 1rem;
	margin-bottom: 2rem;

	ul {
		list-style: none;
		@extend .h4;
		display: flex;

		li:not(:last-child) {
			margin-right: 2rem;
		}

		a, button {
			padding-bottom: 0.5rem;
			border-bottom: 2px solid var(--font-primary);
			transition: all .3s;
			@include hover {
				color: var(--ui-accent-1);
			}
		}

		.active {
			pointer-events: none;
			border-color: var(--ui-accent-1);
			color: var(--ui-accent-1);
		}

	}
}

.hide-desktop {
	display: none;
}

.static-page {
	padding-bottom: 5rem;

	&:has(.gray-widget) {
		padding-bottom: 3rem;
	}
}

.static-page-grid {
	.static-page-content {
		grid-column: span 7;
	}
}

.static-page-content {
	font-size: var(--fs-p3);
	line-height: 140%;

	table {
		font-size: 1rem;
	}

	.table-scroll {
		overflow: auto;
		@extend .style-scroll;
		margin-bottom: 2.5rem;
	}

	p {
		margin-bottom: 1.5rem;
	}

	.s1 {
		line-height: 120%;
		letter-spacing: 0.0325rem;
	}

	h2, h3, h4, h5, h6 {
		margin-bottom: 1.5rem;

		&:not(:first-child) {
			margin-top: 3rem;
		}
	}

	h2 {
		font-size: var(--fs-h2-visivig);
	}

	h3 {
		font-size: var(--fs-h3-visivig);
	}

	h4 {
		font-size: var(--fs-h4-visivig);
	}

	h5 {
		font-size: var(--fs-h5-visivig);
		font-weight: 400;
		font-family: $F_title;
	}

	h6 {
		font-size: var(--fs-h6-visivig);
		font-weight: 400;
		font-family: $F_title;
	}
}

.gray-widget {
	padding: 2.5rem;
	background: var(--ui-bg-2);
	overflow: hidden;

	.text-collapse:after {
		background: linear-gradient(0deg, var(--ui-bg-2), rgba(255, 255, 255, 0));
	}
}

.aside-nav {
	ul {
		list-style: none;

		li {
			margin-bottom: 1rem;
		}

		a {
			padding-left: 1rem;
			font-size: var(--fs-h3);
			font-family: $F_title;
			line-height: 100%;
			border-left: 0.25rem solid transparent;

			&.active {
				border-left: 0.25rem solid var(--ui-accent-1);
			}
		}
	}
}

.link-with-ico {
	display: inline-flex;
	align-items: center;
	gap: 1rem;
	@extend .h3;
	margin-bottom: 1.5rem;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		stroke: var(--ui-accent-1);
		flex-shrink: 0;
	}

	+ .arrow-link.h3:not(:first-child) {
		margin-top: 0.5rem;
	}

}


.empty-image {
	@include pos-center();
	width: 40%;
}

.accordion {

	&:not(:last-child) {
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;
		border-bottom: 1px solid #fff;
	}

	.plus {
		transition: all .3s ease;
	}

	&.active {
		.plus {
			transform: rotate(45deg);

			&:before, &:after {
				background: var(--ui-accent-1);
			}
		}
	}

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		transition: color .2s ease;

		&__arrow {
			width: 1.5rem;
			height: 1.5rem;

			.plus {
				&:before, &:after {
					background: #fff;
					transition: all .2s;
				}
			}
		}

		@include hover {
			color: var(--ui-accent-1);
		}
	}

	&__body {
		height: 0;
		overflow: hidden;

		&__text {
			padding-top: 1.5rem;

			p:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.aside-title {
	width: 40%;
	text-wrap: balance;
}

.aside-title-big {
	width: 80%;
}

.square {
	font-size: 0.7em;
	vertical-align: super;
}

.document-title {
	margin-bottom: 2.5rem;

	h1 {
		display: inline;
		line-height: 100%;
		margin-right: .5rem;
	}

	.h3 {
		display: inline-block;
	}
}

.white-nav-arrow {

	.catalog-detail-slider-itm {
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 6.25rem;
			background: var(--ui-img-gradient);
			background-blend-mode: multiply;
			transition: opacity .25s ease;
			opacity: .5;
		}
	}


	.owl-nav button {
		svg {
			fill: #fff;
		}

		@include hover {
			svg {
				fill: var(--ui-accent-1);
			}
		}
	}
}

.js-parallax {
	will-change: transform;
	backface-visibility: hidden;
	image-rendering: optimizeSpeed;
}

.parallax-wrap {
	overflow: hidden;
}

.fly-chat-block {
	position: fixed;
	right: 0;
	bottom: 0;
	width: 100%;
	text-align: right;
	pointer-events: none;
	z-index: 7;
}

.fly-chat {
	padding: .75rem;
	background: var(--ui-no-active);
	margin-bottom: 2.45rem;
	display: inline-flex;
	align-items: center;
	pointer-events: auto;
	font-size: var(--fs-h5);
	font-family: $F_title;
	color: #fff;
	cursor: pointer;
	transition: background-color .3s, width .3s;
	overflow: hidden;
	width: 20.5rem;
	backdrop-filter: blur(12px);

	@include for-retina {
		backdrop-filter: none;
		background: rgba(40, 41, 54, 0.8);
	}

	&--small {
		width: 3rem;
	}

	&__text {
		white-space: nowrap;
		margin-left: .75rem;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
		display: block;
		flex-shrink: 0;
	}

	@include hover {
		background: var(--font-gray-1);
	}
}


@include for-tablet {
	.main {
		min-height: calc(100vh - 20.1rem);
	}

	html {
		&.scroll-block {
			pointer-events: none;

			.header {
				pointer-events: auto;
			}
		}
	}

	.aside-title-big {
		width: 100%;
	}

	.aside-title {
		width: 100%;
	}

	.hide-t {
		display: none !important;
	}
	.show-t {
		display: block;
	}
	.main {
		padding-top: 4rem;
	}
	.progress-bar {
		height: 2px;

		&:before {
			height: 2px;
		}
	}

	.pagination {
		&-more {
			font-size: 1rem;
		}

		ul {
			gap: 1rem;

			li:first-child svg {
				transform: none;
			}
		}

		&-itm {
			font-size: 1rem;
		}
	}

	.breadcrumb {
		display: none;

		&-mobile {
			display: flex;
			align-items: center;
			color: var(--ui-no-active, rgba(89, 97, 117, 0.50));
			font-weight: 500;
			margin-bottom: 1rem;
			font-size: 1rem;

			&__ico {
				padding: 0.5rem;
				margin-right: 0.5rem;
				background: var(--ui-bg-2);
			}

			svg {
				display: block;
				width: 1rem;
				height: 1rem;
				fill: var(--ui-gray-1);
				transform: rotate(180deg);
				opacity: .5;
			}
		}
	}

	.podsystem-price-block {
		display: flex;
		align-items: center;

		.h3 {
			margin-right: 2rem;
		}
	}

	.pagination ul li:first-child svg {
		//transform: translateY(-2px);
		will-change: transform;
	}

	.static-page {
		padding-bottom: 3rem;

		&:has(.gray-widget) {
			padding-bottom: 0;
		}

		.h1-big {
			font-size: var(--fs-h2);
		}

	}

	.gray-widget {
		.breadcrumb-mobile__ico {
			background: var(--ui-bg-3);
		}
	}

	.static-page-content {
		font-size: var(--fs-p3);

		h2, h3, h4, h5, h6 {
			margin-bottom: 1rem;

			&:not(:first-child) {
				margin-top: 2rem;
			}
		}

		ul, ol {
			font-size: 1rem;
		}

		ul {
			li {
				&:before {
					top: .5rem;
				}
			}
		}

		ol li {
			&:before {
				font-size: 1rem;
				top: .25rem;
			}
		}


	}

	.aside-nav {
		margin-bottom: 1.25rem;

		ul {
			display: block;
			overflow: auto;

			li {
				display: inline-block;

				&:not(:last-child) {
					margin-right: 1.5rem;
				}

				a {
					padding-left: 0;
					font-size: 1rem;
					padding-bottom: .5rem;
					border-left: none;

					&.active {
						border-left: none;
						border-bottom: 3px solid var(--ui-accent-1);
					}
				}
			}
		}
	}

	.document-title {
		margin-bottom: 1rem;

		.h1 {
			display: block;
			font-size: var(--fs-h3);
			margin-bottom: .5rem;
		}

		.h3 {
			font-size: var(--fs-h5);
		}
	}

	.arrow-link {
		transition: none;

		&--document-link {
			margin-bottom: 2rem;
		}

		&.show-t {
			display: inline-flex;
		}

	}

	.link-with-ico {
		font-size: var(--fs-h6);
		font-weight: 500;
		color: var(--font-primary);
		line-height: 100%;
		margin-bottom: 1.25rem;
	}

	.content-grid-accordion {

		&__head {
			.plus {
				width: 1rem;
				height: 1rem;
			}
		}

		&__content {

			&__padding {
				padding-bottom: 1.5rem;
			}

			.arrow-link {
				font-size: var(--fs-h5);

				svg {
					width: 1rem;
					height: 1rem;
				}
			}
		}

		&:last-child {
			.content-grid-accordion__content__padding {
				padding-bottom: 0;
			}
		}
	}

	.other-news-title {
		margin-top: 3rem;
	}

	.fly-chat {
		font-size: 1rem;
		margin-bottom: 1rem;
	}

	.t-btn-absolute-container {
		position: relative;
		padding-bottom: 2.5rem;
	}

	.t-btn-absolute-bottom {
		position: absolute;
		top: 100%;
		font-size: var(--fs-h4);
		margin-top: -1rem;

		br {
			display: none;
		}
	}

	.white-nav-arrow {
		.catalog-detail-slider-itm {
			&:after {
				height: 4.375rem;
			}
		}
	}

	.form-footer {
		&--sticky {

		}
	}

}

@include for-phone {
	.hide-p {
		display: none !important;
	}

	.hide-phone {
		display: none;
	}
	.show-p {
		display: block;
	}

	.main {
		min-height: calc(100vh - 30.1rem);
	}

	.arrow-link svg {
		width: 1rem;
		height: 1rem;
	}

	.breadcrumb-mobile {
		font-size: var(--fs-p2);

		svg {
			width: .5rem;
			height: .5rem;
		}
	}

	.pagination {
		display: block;
		text-align: center;
		padding: 0;

		ul {
			gap: .75rem;
			justify-content: center;
		}

		&-more {
			margin-bottom: 1.25rem;
		}
	}

	.static-page {
		.h1-big {
			font-size: var(--fs-h1-small);
		}
	}

	.static-page-content {
		p {
			margin-bottom: .5rem;
		}

		h2, h3, h4, h5, h6 {
			margin-bottom: .5rem;
		}

		.table-scroll {
			margin-top: 1rem;
		}

		ul, ol {
			font-size: var(--fs-s3);
		}

		ul li {
			&:before {
				width: .3rem;
				height: .3rem;
				top: .35rem;
			}
		}

		ol li {
			padding-left: 1rem;

			&:before {
				font-size: var(--fs-s3);
				left: 0;
			}
		}
	}

	.aside-nav {
		margin-bottom: 1rem;
	}

	.document-title {
		.h1 {
			margin-bottom: .5rem;
			font-size: var(--fs-h1-small);
		}

	}

	.arrow-link--document-link {

	}

	.link-with-ico {
		gap: .75rem;
		font-size: var(--fs-s1);
	}

	.content-grid-accordion__content .arrow-link {
		text-align: left;
		font-size: var(--fs-h4);


		svg {
			margin-left: .25rem;
		}
	}

	.static-page .t-margin-normal {
		margin-bottom: .75rem;
	}

	.other-news-title {
		margin-top: 2.5rem;
		margin-bottom: 1.5rem;
		font-size: var(--fs-h3);
	}

	.other-news {
		.dop-services-itm .h2 {
			font-size: var(--fs-h4);
		}
	}

	.fly-chat {
		font-size: .75rem;
		width: 17.9rem;

		&--small {
			width: 3rem;
		}
	}

	.accordion__body__text {
		padding-top: 1rem;
	}


	.white-nav-arrow {
		.catalog-detail-slider-itm {
			&:after {
				height: 3.125rem;
			}
		}
	}
}