.bracing-page-image {
	position: relative;
	aspect-ratio: 56/30;
	background: var(--ui-bg-3);
	margin-bottom: 2.5rem;
}

.bracing-page-desc {
	width: 80%;
}

@include for-tablet {
	.bracing-page-desc {
		width: 100%;
	}
	.bracing-page-image {
		margin-bottom: 1.5rem;
	}
}

@include for-phone {
	.bracing-page-image {
		margin-bottom: 1rem;
	}
}