@mixin font-face($font-family, $url, $weight:400, $style: normal) {
	@font-face {
		font-family: "#{$font-family}";
		src: url("../fonts/#{$url}.woff2") format("woff2"),
		url("../fonts/#{$url}.woff") format("woff"),
		url('../fonts/#{$url}.ttf') format("truetype"),
		url("../fonts/#{$url}.otf") format("opentype");
		
		font-weight: #{$weight};
		font-display: swap;
		font-style: $style;
	}
}

@include font-face("PPRadioGrotesk", "PPRadioGrotesk-Regular");
@include font-face("PPRadioGrotesk", "PPRadioGrotesk-Bold", 900);
@include font-face("NeueMachina", "PPNeueMachina-InktrapMedium", 500);