.patent-list {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 2.5rem;
	margin-bottom: 2rem;

	&-itm {
		&__img {
			display: block;
			position: relative;
			aspect-ratio: 13/18.4;
			margin-bottom: .75rem;
		}

		&__name {
			@extend .h5;
		}
	}
}

.document-list {

	&-itm {
		position: relative;
		display: flex;
		margin-bottom: 4rem;

		&:after {
			content: "";
			position: absolute;
			bottom: -2rem;
			left: 0;
			width: 100%;
			border-bottom: 1px solid var(--ui-gray-3);
		}

		&:last-child {
			margin-bottom: 2rem;

			&:after {
				display: none;
			}
		}

		@include hover {
			.document-list-itm__name {
				color: var(--ui-accent-1);
			}
			.document-list-itm__arrow {
				opacity: 1;
			}
		}

		&__ico {
			width: 2rem;
			height: 2rem;
			fill: var(--ui-accent-1);
			margin-right: 1rem;
			flex-shrink: 0;
			transform: translateY(2px);
		}

		&__name {
			font-size: var(--fs-h4);
			color: var(--font-primary);
			font-weight: 500;
			line-height: 120%;
			font-family: $F_title;
			transition: color .3s ease;
			padding-right: 5rem;
			flex: 1;

			&__desc {
				color: var(--font-gray-1);
				font-size: var(--fs-h5);
			}
		}

		&__arrow {
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--ui-accent-1);
			flex-shrink: 0;
			opacity: 0;
			transition: opacity .3s ease;
		}
	}

}

.document-text-collapse {
	width: 71%;
	margin-top: 4rem;
}

.bim-text-collapse {
	width: 70%;
	margin-top: 0;
}

.bim-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.5rem;
	margin: 2rem 0 2.5rem;

	&-itm {

		@include hover {
			.bim-list-itm-hover {
				opacity: 1;
			}
		}

		&__img {
			position: relative;
			aspect-ratio: 18/16;
			display: block;
			background: var(--ui-bg-2);
			margin-bottom: 1rem;
		}

		&-hover {
			@include fill();
			background: rgba(40, 41, 54, 0.90);
			z-index: 2;
			color: #fff;
			padding: 1.5rem;
			opacity: 0;
			transition: opacity .3s ease;

			&__attr {
				margin-bottom: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: var(--font-gray-2);

				&:not(:last-child) {
					padding-bottom: 1.5rem;
					border-bottom: 1px solid var(--font-gray-2);
				}
			}

			&__download {
				position: absolute;
				bottom: 1.5rem;
				left: 1.5rem;
				right: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&__ico {
					width: 1.5rem;
					height: 1.5rem;
					fill: #fff;
				}
			}

		}

		&__name {
			word-break: break-word;
		}

		&__mobile {
			display: none;
		}

	}
}

.dop-services {
	&-itm {
		display: grid;
		grid-template-columns: 1fr 25.125rem;
		gap: 2.5rem;
		margin-bottom: .5rem;

		@include hover {
			.dop-services-itm__img__hover {
				opacity: 1;
			}
		}

		&:not(:last-child) {
			padding-bottom: 2rem;
			margin-bottom: 2rem;
			border-bottom: 1px solid var(--ui-gray-3);
		}

		&__content {
			.h2 {
				margin-bottom: .5rem;
			}

			.p2 {
				color: var(--font-primary);
			}
		}

		&__img {
			align-self: center;
			position: relative;
			aspect-ratio: 25.125 / 12.5;
			background: var(--ui-bg-2);

			&__hover {
				@include fill();
				background: rgba(40, 41, 54, 0.90);
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 0;
				transition: opacity .3s;

				&__text {
					position: relative;
					color: #fff;
					display: inline-flex;
					align-items: center;
					font-family: $F_title;
				}

				svg {
					width: 1.5rem;
					height: 1.5rem;
					margin-left: .5rem;
					fill: #fff;
				}
			}
		}

		&__date {
			color: var(--font-gray-1);
			@extend .h4;
			margin-bottom: .75rem;
		}
	}
}

.content-grid-aside {
	.dop-services-itm__date {
		line-height: 1.6;
	}
}

.content-grid-accordion-list {
	margin-top: 2.5rem;
	counter-reset: grid-accordion;
	margin-bottom: 5rem;

	&--modals {
		.content-grid-accordion {
			padding-top: 2.5rem;
			text-align: left;

			@include hover {
				color: var(--ui-accent-1);
			}

			&:not(:last-child) {
				margin-bottom: 0;
			}

			&__head {
				&:before {
					display: none;
				}
			}
		}
	}
}


.content-grid-accordion {
	position: relative;

	&:has(.content-grid-accordion__head:hover) {
		.plus {
			&:before, &:after {
				background: var(--ui-accent-1);
			}
		}
	}

	&.active {
		.content-grid-accordion {
			&__head {
				color: var(--ui-accent-1);
			}

			&__arrow {

				.plus {
					transform: rotate(45deg);

					&:before, &:after {
						background: var(--ui-accent-1);
					}
				}
			}
		}

		.content-grid-accordion__head__arrow {
			.plus {
				transform: rotate(45deg);

				&:before, &:after {
					background: var(--ui-accent-1);
				}
			}
		}

	}

	&:not(:last-child) {
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;
		border-bottom: 1px solid var(--ui-gray-3);
	}

	&__arrow {
		text-align: right;

		.plus {
			transition: all .3s ease;

			&:before, &:after {
				transition: all .3s ease;
			}
		}
	}

	&__num {
		&:before {
			counter-increment: grid-accordion;
			content: counter(grid-accordion, decimal-leading-zero);
			@extend .h2;
			color: var(--ui-gray-3);
		}
	}

	&__head {
		@extend .h2;
		cursor: pointer;
		transition: color .2s ease;
		display: flex;
		justify-content: space-between;
		text-wrap: balance;
		align-items: center;

		&__arrow {
			flex-shrink: 0;
			align-self: center;

			.plus {
				transition: all .3s ease;

				&:before, &:after {
					transition: all .3s ease;
				}
			}
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: -1rem;
			width: 100%;
			height: 4rem;
			z-index: 1;
		}

		@include hover {
			color: var(--ui-accent-1);
		}
	}

	&__content {
		height: 0;
		overflow: hidden;
		@extend .p2;

		&__padding {
			padding-top: 1.5rem;
		}

		p {
			margin-bottom: 2rem;
		}

		.arrow-link {
			margin-top: .5rem;
		}
	}

	h3, .h3 {
		&:not(:first-child) {
			margin-top: 2rem;
		}
	}

	.link-with-ico {
		&:not(:first-child) {
			margin-top: 0;
		}

		svg {
			stroke: none;
			width: 2.5rem;
			height: 2.5rem;
		}
	}
}

.manager-contacts {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem 4rem;

	&__itm {
		align-self: flex-end;
		text-wrap: balance;

		.p2 {
			width: 80%;
			margin-bottom: 1.5rem;
		}
	}
}

.delivery-advantages {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem 6rem;
	margin: 0;

	&__itm {
		svg {
			width: 4.5rem;
			height: 4.5rem;
			margin-bottom: 1rem;
			align-self: center;
		}
	}
}

.delivery-contact-itm {
	&:not(:last-child) {
		padding-bottom: 2rem;
		margin-bottom: 2rem;
		border-bottom: 1px solid var(--ui-stroke);
	}

	&__tel {
		display: inline-block;
		vertical-align: baseline;
		margin-right: 2.3rem;
		@extend .h4;
	}

	&__desc {
		line-height: 110%;
		letter-spacing: 0.02rem;
		color: var(--font-gray-1);
		margin-top: .25rem;
	}

	&__bot {
		margin-top: 1.3rem;
	}
}

.news-detail-title {
	width: 80%;
}

.partner-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.5rem;
	margin-bottom: 2.5rem;
	margin-top: 2.5rem;

	&-itm {
		position: relative;
		display: block;

		&__img {
			position: relative;
			display: block;
			aspect-ratio: 18/15;
			background: #fff;
			margin-bottom: 1rem;

			.fill-img {
				left: 5%;
				top: 5%;
				width: 90%;
				height: 90%;
				object-fit: contain;
			}
		}

		&__text {
			@extend .h4;
		}
	}
}

.bracing-list-itm {
	display: grid;
	grid-template-columns: 1fr 25rem;
	gap: 2.5rem;
	padding: 2rem 0;
	align-items: center;

	&:not(:last-child) {
		border-bottom: 1px solid var(--ui-gray-3);
	}

	&__img {
		position: relative;
		aspect-ratio: 25/12.5;
		background: var(--ui-bg-2);
	}

	&__title {
		margin-bottom: .5rem;
	}

	&__text {
		color: var(--font-primary);
	}

	@include hover {
		.image-link-hover {
			opacity: 1;
		}
	}
}

.image-link-hover {
	@include fill();
	background: rgba(40, 41, 54, 0.90);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: opacity .3s;
	font-family: $F_title;

	&__text {
		position: relative;
		color: #fff;
		display: inline-flex;
		align-items: center;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
		margin-left: .5rem;
		fill: #fff;
	}
}

.static-page {
	&:has(.cladding-grid) {
		.cat-list-desc__title {
			width: 45%;
		}
	}
}

.cladding-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.5rem;

	&-itm {
		display: block;

		&__img {
			position: relative;
			aspect-ratio: 18/22;
			margin-bottom: 1.5rem;
			overflow: hidden;

			img {
				will-change: transform;
				transition: transform .4s var(--ease);
			}
		}

		&__name {
			position: relative;
			padding-right: 3rem;

			svg {
				position: absolute;
				right: 0;
				bottom: .1rem;
				width: 1.5rem;
				height: 1.5rem;
				fill: var(--ui-accent-1);
				opacity: 0;
				transition: opacity .3s ease;
			}

		}

		@include hover {
			.cladding-grid-itm__name svg {
				opacity: 1;
			}

			.cladding-grid-itm__img img {
				transform: scale(1.1);
			}
		}

	}
}

.content-slider, .company-slider {
	position: relative;
	overflow: hidden;
	padding-right: 30%;
	margin: 3rem -35.876% 3rem 0;

	&:last-child {
		margin-bottom: 0;
	}

	.owl-stage-outer {
		width: auto;
		overflow: visible;
		margin-right: -2.5rem;
		position: relative;
	}


	&__itm {
		position: relative;
		aspect-ratio: 45/28;
		margin-right: 2.5rem;

		img {
			margin: 0;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2.25rem;
			background: var(--ui-img-gradient);
			background-blend-mode: multiply;
		}
	}

	.owl-nav {
		position: absolute;
		right: 2.5rem;
		bottom: 2.5rem;

		svg {
			fill: #fff;
		}

		button {
			@include hover {
				svg {
					fill: var(--ui-accent-1);
				}
			}
		}
	}
}

.content-slider {

	&__itm {
		&:after {
			height: 8.5rem;
		}
	}
}

.company-slider {
	margin-right: -20.5%;
	padding-right: 15%;

	.owl-nav {
		right: 20.5%;
		margin-right: 3rem;
	}
}

.company-year-section__year {
	display: flex;
	align-items: flex-end;
	margin-bottom: 2.8rem;
	margin-top: 4rem;

	&__val {
		font-size: 18.23963rem;
		font-weight: 500;
		font-family: $F_title;
		line-height: 50%;
		margin-right: 1.3rem;
	}

	&__desc {
		transform: translateY(13%);
	}
}

.company-gerb-img {
	position: absolute;
	width: 26.375rem;
	max-width: 26.375rem;
	height: auto;
	right: -8.5rem;
	bottom: 0;
	filter: grayscale(.9);
}

.company-FER {
	padding-bottom: 3rem;

	.arrow-link {
		svg {
			fill: var(--ui-accent-1);
			stroke: var(--ui-accent-1);
		}

		@include hover {
			svg {
				stroke: var(--ui-accent-2);
			}
		}
	}
}

.company-partners {
	.arrow-link {
		svg {
			fill: var(--ui-accent-1);
			stroke: none;
		}

		@include hover {
			svg {
				fill: var(--ui-accent-2);
				stroke: none;
			}
		}
	}
}

.project-price-page {
	.show-t {
		display: none;
	}

	.static-page-content {
		font-size: var(--fs-p2);
	}
}

.catalog-section {
	&:has(.podsystem-slider) {
		.cat-list-desc__title {
			margin-bottom: 0;
		}
	}
}

.object-list-title {
	margin-bottom: 2.5rem;
}

.grid-ventfasad-price {
	.grid-ventfasad-price-desc {
		margin-bottom: 2.5rem;
		font-size: var(--fs-s1);
	}

	.static-page-content {
		font-size: var(--fs-p2);
	}

	h3 {
		font-size: var(--fs-h2);
		margin-bottom: .75rem;

		&:not(:first-child) {
			margin-top: 2rem;
		}
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@include for-tablet {

	.static-page {
		&:has(.cladding-grid) {
			.cat-list-desc__title {
				width: 70%;
			}
		}
	}

	.gray-widget {
		margin-left: -1rem;
		margin-right: -1rem;
		padding: 1rem 1rem 3rem;
	}

	.service-detail-title {
		margin-bottom: 1.5rem;
	}

	.content-slider, .company-slider {
		margin: 2rem 0;
		padding-right: 0;


		&__itm {
			margin-right: 0;

			&:after {
				height: 5.25rem;
			}
		}

		.owl-nav {
			right: 1rem;
			bottom: 1rem;

			button {
				width: 1rem;
				height: 1rem;

				&:not(:last-child) {
					margin-right: .25rem;
				}
			}
		}
	}

	.company-slider .owl-nav {
		margin-right: 0;
	}

	.patent-list {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: .94rem;
		margin-bottom: 1.5rem;

		&-itm {
			&__name {
				font-size: var(--fs-h6);
			}
		}
	}

	.bim-list {
		gap: 1rem;
		margin-bottom: 1.5rem;
		margin-top: 1rem;

		&-itm {
			&__img {
				margin-bottom: .7rem;
			}

			&__name {
				font-size: var(--fs-p3);
			}

			&__mobile {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: .44rem;
				color: var(--font-gray-1);

				&__val {
					font-size: var(--fs-small);
				}

				&__ico {
					width: 1rem;
					height: 1rem;
					fill: var(--ui-accent-1);
				}
			}
		}
	}

	.document-list-itm {
		margin-bottom: 2.5rem;

		&:after {
			bottom: -1.25rem;
		}

		&__ico {
			display: none;
		}

		&__name {
			padding-right: 2.5rem;
			font-size: 1rem;
			line-height: 1;
			display: block;

			&__desc {
				display: block;
				font-size: 0.875rem;
				margin-top: .25rem;
			}
		}

		&__arrow {
			opacity: 1;
			align-self: center;
			width: 1rem;
			height: 1rem;
		}
	}

	.document-text-collapse {
		width: 100%;
		margin-top: 3rem;
	}

	.dop-services-itm {
		grid-template-columns: 1fr 11.1875rem;
		gap: 1rem;

		&__img {
			aspect-ratio: 1;
		}

		&__date {
			font-size: var(--fs-p3);
		}

		.h2 {
			position: relative;
			font-size: var(--fs-h5);
			color: var(--ui-accent-1);
			padding-right: 2.5rem;

			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				width: 1rem;
				height: 1rem;
				background: var(--ui-accent-1);
				mask: url("../img/svg/arrow-right.svg") no-repeat center;
				mask-size: 1rem;
				transform: translateY(-50%);
			}
		}

		.p2 {
			line-height: 130%;
			font-size: 1rem;
		}
	}

	.news-list {
		.dop-services-itm {
			.h2 {
				padding-right: 0;
				color: inherit;

				&:after {
					display: none;
				}
			}
		}
	}

	.content-grid-accordion {
		&:not(:last-child) {
			padding-bottom: 0;
			margin-bottom: 0;
		}

		&.active {
			.content-grid-accordion__head:after {
				transform: rotate(45deg);
				background: var(--ui-accent-1);
			}
		}

		&__arrow {
			position: absolute;
			right: 0;
			top: 1.75rem;
			display: none;

			.plus {
				width: 1rem;
				height: 1rem;
			}
		}

		&__head {
			position: relative;
			font-size: var(--fs-h5);
			padding-bottom: 1.5rem;
			padding-top: 1.5rem;
			padding-right: 2.5rem;


			&:after {
				content: "";
				position: absolute;
				top: calc(50% - .5rem);
				right: 0;
				height: 1rem;
				width: 1rem;
				mask: url("../img/svg/plus.svg") no-repeat center;
				background: var(--ui-primary);
				transition: all .3s ease;
			}

			&__arrow {
				opacity: 0;
			}

			&__name {
				&:before {
					counter-increment: grid-accordion;
					content: counter(grid-accordion, decimal-leading-zero) ". ";
				}
			}
		}

		&__num {
			display: none;
		}

		&__content {
			font-size: 1rem;

			&__padding {
				padding-top: 0;
			}

			p {
				margin-bottom: 1.5rem;
			}
		}

		&-list {
			margin-bottom: 1.5rem;
			margin-top: 1rem;
		}

		h3, .h3 {
			&:not(:first-child) {
				margin-top: 1.5rem;
			}
		}

		.link-with-ico {
			&:not(:first-child) {
				margin-top: 0;
			}
		}

	}

	.manager-contacts {
		gap: 2rem;
		grid-template-columns: 40% 1fr;

		&__itm {
			.p2 {
				width: 100%;
				font-size: 1rem;
				line-height: 130%;
				letter-spacing: 0.02rem;
				margin-bottom: .75rem;
			}

			.h2 {
				font-size: var(--fs-s2);
			}
		}
	}

	.delivery-advantages {
		gap: 1rem;
		margin-top: 1.5rem;

		.h3 {
			font-size: var(--fs-h5);
		}

		&__itm {
			svg {
				width: 3.5rem;
				height: 3.5rem;
			}
		}

	}

	.delivery-contact-itm {

		&:not(:last-child) {
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
		}

		.h3 {
			font-size: var(--fs-h5);
			margin-top: 1.5rem;
		}

		&__bot {
			margin-top: .75rem;

			.arrow-link {
				font-size: var(--fs-p3);

				svg {
					width: 1rem;
					height: 1rem;
				}
			}
		}

		&__tel {
			font-size: var(--fs-p3);
			font-weight: 500;
		}
	}

	.news-detail-title {
		font-size: var(--fs-h3);
		width: 100%;
		text-wrap: balance;
		margin-bottom: 1rem;
	}

	.content-grid-aside {
		.dop-services-itm__date {
			position: absolute;
			right: 1rem;
			top: 1.5rem;
		}
	}

	.vacancy-desc {
		position: relative;
		padding-bottom: 2.5rem;

		&-link {
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.content-grid-aside {
			.arrow-link {
				transform: translateY(-1px);
			}
		}
	}

	.accordion-list {
		.content-grid-accordion__head {
			padding-right: 0;
			line-height: 100%;

			&__name {
				&:before {
					display: none;
				}
			}

			&__arrow {
				.plus {
					width: 1rem;
					height: 1rem;
				}
			}
		}
	}

	.content-grid-accordion__content {
		transform: translateY(-.5rem);

		h3 {
			font-size: var(--fs-p2);
		}
	}

	.actual-vac-title {
		margin-bottom: 1rem;
	}

	.partner-grid {
		gap: 1rem;
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;

		&-itm {
			&__img {
				margin-bottom: .75rem;
			}

			&__text {
				font-size: var(--fs-p4);
			}
		}
	}

	.bracing-list {
		margin-top: .5rem;

		&-itm {
			grid-template-columns: 1fr 11.2rem;
			padding: 1.5rem 0;

			&__img {
				aspect-ratio: 1;
			}

			&__title {
				position: relative;
				padding-right: 2.5rem;
				font-size: var(--fs-h5);
				color: var(--ui-accent-1);
				margin-bottom: .5rem;

				&:after {
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					width: 1rem;
					height: 1rem;
					background: var(--ui-accent-1);
					mask: url("../img/svg/arrow-right.svg") no-repeat center;
					mask-size: 1rem;
					transform: translateY(-50%);
				}
			}

			&__text {
				font-size: 1rem;
			}
		}

		.bracing-title {
			width: 100%;
		}
	}

	.cladding-grid {
		gap: 1rem;

		&-itm__name {
			font-size: 1rem;
			padding-right: 1.25rem;

			svg {
				width: 1rem;
				height: 1rem;
				opacity: 1;
			}
		}
	}

	.grid-ventfasad-price {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0;

		.content-grid-aside {
			grid-column: 1;
			order: 2;
		}

		.content-grid-desc {
			grid-column: 1;
		}

		h3 {
			margin-bottom: .75rem;

			&:not(:first-child) {
				margin-top: 1.5rem;
			}
		}

		.arrow-link--document-link {
			display: none;
			margin-bottom: 0;
			font-size: var(--fs-h4);

			br {
				display: none;
			}
		}

	}

	.vacancy-desc {
		margin-bottom: 1.5rem;

		.s1 {
			font-size: var(--fs-p2);
		}
	}

	.buro-example-top {
		margin-bottom: 1rem;
	}

	.company-year-section {
		margin-top: 2rem;

		.aside-title-big {
			width: 100%;
		}

		&__year {
			margin-top: 0;
			display: block;
			margin-bottom: 1.5rem;

			br {
				display: none;
			}

			&__val {
				font-size: 9.375rem;
				margin-bottom: -1rem;
				line-height: 100%;
			}

			&__desc {
				font-size: var(--fs-h2);
			}
		}

		+ .buro-example-section {
			padding-top: 0;
		}
	}

	.project-price-page {
		.aside-title {
			width: 100%;
		}

		.show-t {
			display: inline-flex;
			font-size: var(--fs-h4);
			margin-bottom: 0;

			br {
				display: none;
			}
		}

		h3 {
			&:not(:first-child) {
				margin-top: 1.5rem;
			}
		}

		p {
			margin-bottom: 1.5rem;
		}
	}

	.content-grid-aside {
		.dop-services-itm__date {
			line-height: 1;
		}
	}

	.object-list-title {
		margin-bottom: 1.5rem;
	}

	.static-page {
		&:has(.grid-ventfasad-price) {
			.catalog-detail-title {
				font-size: var(--fs-h2);
				margin-bottom: 1rem;
			}
		}
	}

	.grid-ventfasad-price {
		.grid-ventfasad-price-desc {
			margin-bottom: 1.5rem;
			font-size: var(--fs-p2);
		}

		.static-page-content {
			font-size: var(--fs-p3);
		}

		h3 {
			font-size: var(--fs-h4);
			margin-bottom: .75rem;

			&:not(:first-child) {
				margin-top: 2rem;
			}
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}


}

@include for-phone {

	.static-page {
		&:has(.cladding-grid) {
			.cat-list-desc__title {
				width: 100%;
			}
		}

		.text-collapse-content {
			line-height: 140%;
		}
	}

	.news-detail-title {
		font-size: var(--fs-h1);
	}

	.service-detail-title {
		margin-bottom: 1rem;
	}

	.gray-widget {
		padding-bottom: 2.5rem;
	}

	.bim-list-itm__mobile__ico {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.content-slider {

		.owl-nav {
			right: .5rem;
			bottom: .5rem;
		}
	}

	.content-slider, .company-slider {
		&__itm {
			&:after {
				height: 3.125rem;
			}
		}
	}

	.patent-list {
		display: block;
		margin-bottom: 1.5rem;

		&-itm {
			display: grid;
			grid-template-columns: 4.125rem 1fr;
			gap: .75rem;
			margin-bottom: 1rem;

			&__img {
				align-self: center;
				margin-bottom: 0;
			}

			&__name {
				align-self: center;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.bim-list {
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem .5rem;
		margin-top: 1rem;

		&-itm {
			&__img {
				margin-bottom: .5rem;
			}

			&__mobile {
				margin-top: .44rem;

				&__val {
					font-size: var(--fs-s4);
				}
			}
		}
	}
	.bim-text-collapse {
		width: 100%;
	}

	.document-list {
		&-itm {
			&__name {
				padding-right: 1rem;
				font-size: var(--fs-p3);

				&__desc {
					font-size: var(--fs-small);
				}
			}
		}
	}

	.document-text-collapse {
		margin-top: 2.5rem;
	}

	.dop-services-itm {
		display: block;
		margin-bottom: 0;

		&:not(:last-child) {
			padding-bottom: 0;
			border-bottom: none;
			margin-bottom: 2rem;
		}

		&__img {
			aspect-ratio: 18/8.5;
			margin-top: .75rem;
		}

		&__date {
			font-size: var(--fs-h6);
			margin-bottom: .5rem;
		}

		.h2 {
			font-size: var(--fs-h3);
			margin-bottom: .75rem;
		}

		.p2 {
			font-size: var(--fs-p3);
			letter-spacing: 0.0175rem;
		}
	}

	.content-grid-accordion {
		&-list {
			margin-top: .25rem;
		}

		&__head {
			padding-top: 1.25rem;
			padding-bottom: 1.25rem;
		}

		&__arrow {
			top: 1.25rem;
		}

		&__content {
			font-size: var(--fs-p3);
			line-height: 130%;

			p {
				margin-bottom: 1.25rem;
			}
		}
	}

	.delivery-advantages {
		display: block;

		&__itm {
			display: grid;
			grid-template-columns: 2.5rem 1fr;
			gap: .75rem;

			&:not(:last-child) {
				margin-bottom: 1rem;
			}

			svg {
				width: 2.5rem;
				height: 2.5rem;
				margin-bottom: 0;
			}
		}
	}

	.delivery-contact-itm {
		&:not(:last-child) {
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}

		&__desc {
			font-size: .75rem;
		}
	}

	.news-list {
		margin-bottom: 1.5rem;

		.dop-services-itm {
			&:not(:last-child) {
				margin-bottom: 1.5rem;
				padding-bottom: 1.5rem;
				border-bottom: 1px solid #C6CBD6;
			}
		}
	}

	.vacancy-desc {
		padding-bottom: 2rem;
		margin-bottom: 1rem;

		&-link {
			font-size: 1rem;
		}
	}

	.actual-vac-title {
		margin-top: 2rem;
		margin-bottom: 0;
	}

	.partner-grid {
		margin-top: 1rem;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 1rem .5rem;

		&-itm {
			&__img {
				margin-bottom: .5rem;
			}

			&__text {
				font-size: var(--fs-small);
				line-height: 130%;
				display: block;
			}
		}
	}

	.bracing-list-itm {
		display: block;

		&__title {
			font-size: var(--fs-h3);
		}

		&__text {
			font-size: .875rem;
		}

		&__img {
			margin-top: .75rem;
			aspect-ratio: 16/10;
		}
	}

	.cladding-grid {
		grid-template-columns: 1fr;
		gap: 1.5rem;

		&-itm {
			&__img {
				margin-bottom: .75rem;
			}
		}
	}

	.grid-ventfasad-price {
		.arrow-link--document-link {
			margin-top: 1rem;
			font-size: var(--fs-h4);
		}
	}

	.company-year-section__year__val {
		font-size: 6.5rem;
	}

	.company-year-section__year {
		margin-bottom: 1rem;
	}

	.manager-contacts {
		display: block;

		&__itm {
			.p2 {
				font-size: 0.875rem;
				margin-bottom: .5rem;
			}

			.h2 {
				font-size: 1rem;
			}

			&:not(:last-child) {
				padding-bottom: 1rem;
				margin-bottom: 1rem;
				border-bottom: 1px solid #C6CBD6;
			}

		}
	}

	.static-page {
		&:has(.grid-ventfasad-price) {
			.catalog-detail-title {
				font-size: var(--fs-h1-small);
				margin-bottom: .75rem;
			}
		}
	}

	.grid-ventfasad-price {

		h3 {
			font-size: var(--fs-h3);

			&:not(:first-child) {
				margin-top: 1.5rem;
			}
		}
	}

}