@use "sass:math";

.cols {
	display: flex;
	flex-wrap: wrap;

	&.p15 {
		@include col-padding(15px);
	}

	&.p30 {
		@include col-padding(30px);
	}
}

.cols-2 {
	.col {
		width: 50%;
	}
}

.cols-3 {
	.col {
		width: 33.333%;
	}
}


@for $i from 1 through 12 {
	.col-#{$i} {
		width: math.div(100%, 12) * $i;
	}
}

@include for-tablet {
	@for $i from 1 through 12 {
		.col-t-#{$i} {
			width: math.div(100%, 12) * $i;
		}
	}
}

@include for-phone {
	@for $i from 1 through 12 {
		.col-p-#{$i} {
			width: math.div(100%, 12) * $i;
		}
	}
}