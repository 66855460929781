input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

label.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.input {
	position: relative;
	display: block;
	margin-bottom: 2rem;

	@include hover {
		input, textarea {
			border-color: var(--ui-accent-1);
		}
	}

	&--textarea {
		margin-left: -1rem;
		margin-right: -1rem;

		.textarea-border {
			@include fill(-1px);
			border: 1px solid var(--ui-gray-3, #C6CBD6);
			pointer-events: none;
			transition: all .2s;
		}

		.input-label, .input-hint {
			left: 1rem;
		}

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			height: 1.5rem;
			background: var(--ui-bg-light);
		}

		&:after {
			top: auto;
			height: 2rem;
			bottom: 0;
			right: 10px;
		}
	}

	&-field {
		position: relative;
		display: block;
	}

	&-label {
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		transform: translateY(-35%);
		@extend .h3;
		font-weight: 500;
		line-height: 1;
		transition: all .2s linear;
		transform-origin: top left;
		will-change: transform;
		font-family: $F_title;
	}

	textarea {
		background: none;
		min-width: calc(100%);
		max-width: calc(100%);
		width: calc(100%);
		padding: 2rem 1rem 2rem;
		font-family: $F_title;
		box-sizing: border-box;
		transition: border-color .2s ease-in-out;
		@extend .h3;
		font-weight: 500;
		@extend .style-scroll;
		min-height: 11rem;
		max-height: 20rem;

		~ .input-label {
			bottom: auto;
			top: 1.2rem;
			transform: translateY(0);
		}

		~ .input-hint {
			position: absolute;
			bottom: 1rem;
		}


		&:-webkit-autofill {
			box-shadow: inset 0 0 0 1000px var(--ui-gray-1);
		}


		&:not(:placeholder-shown), &:-webkit-autofill, &:focus {

			~ .input-label {
				transform: translateY(-25%) scale(0.65);
			}
		}

		@include hover {
			~ .textarea-border {
				border-color: var(--ui-accent-1);
			}
		}

		&:focus {
			~ .textarea-border {
				border-color: var(--ui-accent-1);
			}

			&:-webkit-autofill {
				box-shadow: inset 0 0 0 1000px #FCFCFC;
			}
		}

	}

	input {
		display: block;
		width: 100%;
		background: none;
		border-radius: 0;
		padding: 0;
		padding-top: 1.37rem;
		height: 3.375rem;
		box-sizing: border-box;
		outline: none;
		border-bottom: 1px solid var(--ui-gray-3);
		transition: all .2s ease-in-out;
		@extend .h3;
		font-family: $F_title;
		font-weight: 500;
		-webkit-appearance: none;

		&.small-input {
			padding: 10px 15px;
			border-radius: 4px;
		}

		&:-webkit-autofill {
			box-shadow: inset 0 0 0 1000px transparent;
		}


		&:not(:placeholder-shown), &:-webkit-autofill, &:focus {

			+ .input-label {
				transform: translateY(-100%) scale(0.65);
			}
		}


		&:focus {
			border-color: var(--ui-accent-1);

			&:-webkit-autofill {
				box-shadow: inset 0 0 0 1000px #FCFCFC;
			}
		}

	}

	&-hint {
		color: var(--font-gray-2, #93949A);
		@extend .s4;
		margin-top: 0.125rem;
		height: 0;
		z-index: 1;
		overflow: hidden;

		&.show {
			height: auto;
		}
	}

	&.error {
		.input-hint {
			color: var(--ui-error);
		}
	}

}

.date-input {
	position: relative;
	display: inline-block;

	input {
		width: 192px;
		padding-right: 50px;
	}

	&__ico {
		position: absolute;
		top: 50%;
		right: 5px;
		transform: translateY(-50%) translateX(-50%);
	}
}

.pass-hide-ico {
	cursor: pointer;
}

.pass-show-ico {
	display: none;
	cursor: pointer;
}

.js-select {
	display: none;
}

.js-select-wrap {
	position: relative;

	select {
		display: none;
	}
}

.custom-select {
	position: relative;

	&__default-val {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-52%);
	}

	&--dark {
		.custom-select__dropdown {
			background-color: var(--ui-bg-2);
		}

		.check_box__item {
			background: var(--ui-gray-3);
			border-color: var(--ui-gray-3);
		}
	}

	&__single {
		cursor: pointer;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-bottom: 1px solid var(--ui-gray-3, #C6CBD6);
		@extend .h4;
		padding-bottom: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.input-label {
			font-size: inherit;
		}

		&:after {
			content: '';
			position: relative;
			width: 1.5rem;
			height: 1.5rem;
			background: url("../img/svg/shewron-down.svg");
			transition: all .3s ease;
			will-change: transform;
			background-size: contain;
			flex-shrink: 0;
		}
	}

	&__val {
		display: block;
		height: 1.5rem;
		padding-top: 0.25rem;
	}

	&__dropdown {
		position: absolute;
		top: 100%;
		left: -1.5rem;
		right: -1.5rem;
		max-height: 250px;
		overflow: auto;
		background: var(--ui-bg-light, #F0F3FA);
		margin-top: 6px;
		pointer-events: none;
		z-index: 8;
		transform: translateY(-5px);
		scrollbar-gutter: stable;
		@extend .style-scroll;
		opacity: 0;
	}

	&__dropdown-list {
		list-style: none;
		padding: 1.125rem 0;
	}

	&__dropdown-item {
		position: relative;
		padding: 0.375rem 1.5rem;
		cursor: pointer;
		--checkbox-color: var(--ui-bg-2);

		.check_box {
			cursor: pointer;
			font-size: var(--fs-s3);
			line-height: 110%;

			&:after {
				content: "";
				@include fill();
			}
		}

		@include hover {
			background: #f3f4fb;
		}

		&.option-disabled {
			opacity: 0.5;
			pointer-events: none;
			background: #e0e1e8;
		}
	}

	&.is-open {
		.custom-select {
			&__dropdown {
				pointer-events: auto;
				transform: translateY(0px);
			}

			&__single {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}

	&.has-val, &--with-default {

		.input-label {
			transform: translateY(-85%) scale(0.65);
		}


	}

	&.has-val {
		.custom-select__default-val {
			opacity: 0;
		}
	}
}

.js-checkbox {
	display: none;

	&.round {
		+ .check_box__item {
			border-radius: 50%;
		}
	}

	&:checked {
		+ .check_box__item {
			//background: $c_main;

			&:before {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

}

.check_box {
	display: inline-flex;
	align-items: center;
	@extend .s4;
	font-weight: 400;
	line-height: 130%;
	letter-spacing: 0.015rem;

	&.error {
		.check_box__item {
			border-color: var(--ui-error);
		}

		.input-hint {
			color: var(--ui-error);
		}
	}

	&__item {
		position: relative;
		display: inline-block;
		width: 2rem;
		height: 2rem;
		background: var(--ui-bg-2);
		margin-right: .5rem;
		flex-shrink: 0;
		border: 2px solid var(--ui-bg-2);
		transition: all .2s ease-in-out;

		&:before {
			content: '';
			@include fill(-2px);
			background: url('../img/svg/check-bold.svg') no-repeat center;
			background-size: 70%;
			opacity: 0;
			transform: scale(0);
			transition: all .2s ease-in-out;
			transform-origin: center;
			will-change: transform;
		}
	}

	&__text {
		position: relative;

		a {
			color: var(--ui-accent-1);

			@include hover {
				color: var(--ui-accent-2);
			}
		}
	}

	@include hover {
		.check_box__item {
			border-color: var(--ui-accent-1);
		}
	}
}

.input-file {
	position: relative;

	&.drag {
		&:after, &:before {
			opacity: 1;
		}

		.input-file-empty {
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&.file-full {
		.input-file-empty {
			display: none;
		}
	}

	&.has-file {
		.input-file-empty {
			//display: none;
			border-color: transparent;
		}

		.input-file-fill {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	&-fill {
		display: none;
	}

	&:after {
		content: "Загрузите файл или картинку";
		width: 9.8125rem;
		@include pos-center();
		color: #fff;
		@extend .h5;
		z-index: 2;
		text-align: center;
		opacity: 0;
		pointer-events: none;
		transition: opacity .3s ease;
	}

	&:before {
		content: "";
		@include fill();
		background: rgba(0, 0, 0, 0.20);
		backdrop-filter: blur(16px);
		z-index: 1;
		opacity: 0;
		pointer-events: none;
		transition: opacity .3s ease;
	}

	&-label {
		@include fill();
		display: none;

		&.active {
			display: block;
		}
	}

	&__label {
		display: block;
		@extend .h5;
		margin-bottom: .25rem;
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		padding: 0;
	}

	&__name-empty {
		@extend .h3;
		color: var(--ui-accent-1);
	}

	&__list {
		width: 100%;
	}

	&__remove {
		cursor: pointer;

		svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--ui-accent-1);
		}

		@include hover {
			svg {
				fill: var(--ui-accent-2);
			}
		}
	}

	&-empty {
		position: initial;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: .5rem;
		border-bottom: 1px solid var(--ui-stroke);
		cursor: pointer;
		transition: border-color .2s ease;

		&__list {
			@include fill();
		}

		@include hover {
			border-color: var(--ui-accent-1);
		}
	}

	&__plus {
		position: relative;
		width: 1.5rem;
		height: 1.5rem;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -1px;
			width: 100%;
			height: 2px;
			background: var(--ui-accent-1);
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -1px;
			height: 100%;
			width: 2px;
			background: var(--ui-accent-1);
		}
	}

	&__desc {
		margin: 0.38rem 0;
		@extend .s4;
	}

	&-list-itm {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@extend .h4;
		padding: 0.62rem 0;
		border-bottom: 1px solid #C6CBD6;

		&:last-child {
			border-bottom: none;
		}

		&__remove {
			cursor: pointer;

			svg {
				width: 1.5rem;
				height: 1.5rem;
				fill: var(--ui-accent-1);
				transition: all .2s;
			}

			@include hover {
				svg {
					fill: var(--ui-accent-2);
				}
			}
		}
	}
}

.input-hint-error {
	display: none;
	color: var(--ui-error);
	@extend .s4;
	margin-top: 0.125rem;
}

.range-input-wrap {
	width: 100%;
	padding: 1.5rem;

	button {
		color: var(--ui-accent-1);
		@extend .h5;
		transition: all .3s;
		cursor: pointer;
		margin-top: 1.5rem;

		@include hover {
			color: var(--ui-accent-2);
		}
	}
}

.range-input {
	width: 100%;
	text-align: left;
	font-size: 1rem;
	padding: 0.5rem;
	font-weight: 400;
	line-height: 110%;
	letter-spacing: 0.02rem;
	background: var(--ui-bg-2, #E2E8F0);
	height: 2rem;

	&-block {
		flex: 1;
	}

	&-sep {
		position: relative;
		width: 2rem;
		height: 2rem;
		flex-shrink: 0;

		&:before {
			content: "";
			@include pos-center();
			width: 0.7rem;
			height: 2px;
			background: var(--font-primary);
		}
	}

	&-container {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;
	}
}

.range-select.ui-widget.ui-widget-content {
	position: relative;
	clear: both;
	margin: 0 .5rem;
	margin-bottom: .5rem;
	background: var(--ui-bg-3);
	height: 2px;
	border: none;

	.ui-slider-range {
		background: var(--ui-accent-1);
		position: absolute;
		height: 2px;
	}


	.ui-slider-handle {
		position: absolute;
		width: 1rem;
		height: 1rem;
		box-sizing: border-box;
		background-color: var(--ui-accent-1);
		top: -.5rem;
		margin-left: -.5rem;
		cursor: pointer;
		border-radius: 50%;
		transition: background-color .3s;

		@include hover {
			background: var(--ui-accent-2);
		}
	}
}

@include for-tablet {
	.input {
		&-label {
			font-size: var(--fs-h5);
		}

		&--textarea {
			margin-left: 0;
			margin-right: 0;
		}

		textarea {
			font-size: var(--fs-h5);
		}

		input {
			height: 2.44rem;
			font-size: var(--fs-h5);
			padding-top: 0;

			&:not(:placeholder-shown), &:-webkit-autofill, &:focus {

				+ .input-label {
					transform: translateY(-150%) scale(.65);
				}
			}
		}

		&-file {
			&__name-empty {
				font-size: var(--fs-h5);
			}

			&-list-itm__name {
				font-size: var(--fs-h5);
			}

			&__desc {
				font-size: var(--fs-small);
			}
		}
	}

	.input-file {
		.input-file__label {
			font-size: .875rem;
		}
	}

	.custom-select__single:after {
		width: 1rem;
		height: 1rem;
		right: .2rem;
	}

	.catalog-filter-itm {
		.custom-select {
			&.custom-select--with-default {
				border-color: var(--ui-accent-1);

				.custom-select__dropdown {
					position: absolute;
					width: auto;
					left: -.5rem;
					right: -.5rem;
					padding-left: .5rem;
					padding-right: .5rem;
				}

				&.has-val {
					.input-label {
						transform: translateY(-85%) scale(.65);
					}
				}

				.custom-select__val {
					visibility: visible;
				}
			}
		}
	}

	.custom-select {
		position: relative;

		&__default-val {
			top: 66%;
		}


	}

	.catalog-filter-itm .custom-select__single {
		&:after {
			top: .2rem;
		}

	}
}

@include for-phone {
	.input {
		margin-bottom: 1.1rem;

		input {
			padding-top: .5rem;
		}

		&-label {
			font-size: var(--fs-h3);
		}

		&-file {
			margin-top: -.25rem;

			&__desc {
				font-size: var(--fs-s4);
			}
		}
	}

	.check_box {
		&__item {
			width: 2.5rem;
			height: 2.5rem;
		}

		br {
			display: none;
		}
	}

	.catalog-filter-itm {
		.custom-select {
			&.custom-select--with-default {
				.custom-select {
					&__val {
						padding-top: .5rem;
						font-size: var(--fs-s1);
					}

					&__default-val {
						font-size: var(--fs-s1);
						padding-top: .15rem;
					}
				}

				.check_box {
					gap: .5rem;
				}

				.check_box__item {
					width: 1.5rem;
					height: 1.5rem;
				}
			}
		}
	}


	.input-file {
		.input-file__label {
			font-size: .75rem;
		}
	}

}