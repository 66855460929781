.cat-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.5rem;
	margin-top: 2.5rem;
	margin-bottom: 5rem;

	&--fill-img {
		.cat-list-itm {
			aspect-ratio: .75;

			&--fill {
				aspect-ratio: 1;
			}

			&__img {
				margin: 0;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

			.fill-img {
				object-fit: cover;
			}
		}
	}

	&-itm {
		position: relative;
		background: var(--ui-bg-2, #E2E8F0);
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		flex-direction: column;


		&--fill {
			padding-bottom: 100%;

			.cat-list {
				&-itm {
					&__name {
						position: absolute;
						display: block;
						left: 0;
						top: 0;
						padding: 1.5rem;
						@extend .h3;
					}

					&__img {
						@include fill();
						padding-bottom: 0;
					}
				}

				img {
					transition: transform .4s var(--ease);
				}
			}
		}

		&__img {
			display: block;
			position: relative;
			aspect-ratio: 1;
			margin: 2.5rem;
		}

		&__name {
			position: relative;
			@extend .h4;
			z-index: 1;
			padding: 1rem 1rem 0;
		}

		img {
			transition: transform .4s var(--ease);
			will-change: transform;
			object-fit: contain;
		}

		@include hover {
			img {
				transform: scale(1.1);
			}
		}
	}

	&--big {
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 0;

		.cat-list-itm__img {
			margin: 0;
		}
	}
}

.cat-list-desc {
	padding-bottom: 5rem;

	&__title {
		width: 47%;
		@extend .title-margin;
	}

	&__text {
		margin-top: 2rem;
	}
}

.content-grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 2.5rem;

	&-aside {
		grid-column-start: 1;
		grid-column-end: 4;
	}

	&-aside-medium {
		grid-column-start: 1;
		grid-column-end: 5;
	}

	&-aside-right {
		grid-column-start: 10;
		grid-column-end: 13;

		&-small {
			grid-column-start: 11;
			grid-column-end: 13;
		}
	}

	&-main {
		grid-column-start: 4;
		grid-column-end: 10;
	}

	&-main-right {
		grid-column-start: 4;
		grid-column-end: 13;
	}

	&-main-right-small {
		grid-column-start: 5;
		grid-column-end: 13;
	}

	&-main-right-small-1 {
		grid-column-start: 5;
		grid-column-end: 12;
	}

	&-main-right-smallest {
		grid-column-start: 5;
		grid-column-end: 11;
	}

	&-desc {
		grid-column-start: 4;
		grid-column-end: 11;
	}

	&-full {
		grid-column: span 12;
	}

}

.catalog-detail-title {
	width: 70%;
	margin-bottom: 2rem;
	line-height: 100%;
}

.catalog-detail-slider {
	position: relative;
	margin-right: -2.5rem;
	overflow: hidden;
	padding-right: 23%;

	&:not(.owl-loaded) {
		img {
			display: none;
		}
	}

	.owl-stage-outer {
		overflow: visible;
		width: auto;
		position: relative;
	}

	&-itm {
		display: block;
		position: relative;
		padding-bottom: 58.5%;
		background: var(--ui-bg-3, #D8DFEB);

		&[data-fancybox] {
			overflow: hidden;
			cursor: pointer;

			.fill-img {
				will-change: transform;
				transition: transform .6s ease;
			}

			@include hover {
				.fill-img {
					transform: scale(1.1);
				}
			}
		}
	}

	.owl-item {
		margin-right: 2.5rem;
	}

	.owl-nav {
		position: absolute;
		right: 2.5rem;
		bottom: 2.5rem;
		z-index: 1;
	}

	&.single-item-slider {
		padding-right: 0;

		.owl-stage, .owl-item {
			width: 100% !important;
		}

		.catalog-detail-slider-itm {
			padding-bottom: 53.6%;
		}
	}

	&--fill {
		.catalog-detail-slider-itm .fill-img {
			object-fit: cover;
		}
	}
}

.catalog-detail-slider-fill {
	.catalog-detail-slider-itm img.fill-img {
		object-fit: cover;
	}
}

.catalog-sku {
	text-align: right;
	transform: translateY(-1px);
	line-height: 150%;
}

.catalog-filter {
	background: var(--ui-bg-3, #D8DFEB);
	padding: 2.5rem;
	margin: 2.5rem -2.5rem;

	&__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.5rem;

		&__close {
			display: none;
		}
	}

	&-reset {
		display: flex;
		align-items: center;
		color: var(--ui-no-active, rgba(89, 97, 117, 0.50));
		transition: all .3s ease;
		@extend .h4;
		pointer-events: none;

		svg {
			width: 1.5rem;
			height: 1.5rem;
			margin-right: 0.5rem;
			fill: var(--ui-no-active, rgba(89, 97, 117, 0.50));
			transition: all .3s ease;
			margin-top: -.25rem;
		}

		&.active {
			pointer-events: auto;
			color: var(--ui-accent-1);

			svg {
				fill: var(--ui-accent-1);
			}

			@include hover {
				color: var(--ui-accent-2);

				svg {
					fill: var(--ui-accent-2);
				}
			}
		}


	}

	&-form {
		display: grid;
		grid-template-columns: 25.8% 1fr;
		gap: 2.5rem 0;

		.input {
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;
			border-bottom: 1px solid var(--ui-gray-3, #C6CBD6);
			cursor: pointer;
			padding-bottom: 0.5rem;
			--checkbox-color: var(--ui-stroke);

			.check_box__item {
				margin-right: 0;
			}

			input {
				display: none;
			}
		}

		.check_box {

			&__item {
				width: 1.375rem;
				height: 1.375rem;
				background-color: var(--checkbox-color);
				border-color: var(--checkbox-color);

				&:before {
					@include fill(2px);
				}
			}
		}


		&-content {
			position: relative;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 2.5rem;

			.filter-hide-itm {
				opacity: 0;
				transition: all 0s;
			}

			&.show {
				.filter-hide-itm {
					opacity: 1;
					transition: all .3s;
				}
			}
		}
	}

	&-itm {
		transition: all .3s ease-in-out;

		.custom-select__single {
			transition: all .3s ease-in-out;
		}

		.input-label, .custom-select__val {
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			line-height: 1.2;
			text-overflow: ellipsis;
		}

		@include hover {
			border-color: var(--ui-accent-1);

			.custom-select__single {
				border-color: var(--ui-accent-1);
			}
		}

		&__name {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			line-height: 1.2;
		}
	}

	&-footer {
		display: none;
	}

}

.catalog-detail {
	background: var(--ui-bg-2, #E2E8F0);
	padding: 2.5rem;
	margin-bottom: 2.5rem;

	.text-collapse {
		&:after {
			background: linear-gradient(0deg, var(--ui-bg-2) 10%, rgba(255, 255, 255, 0));
		}
	}

	.breadcrumb-mobile__ico {
		background: var(--ui-bg-3);
	}

	.catalog-detail-slider-itm .fill-img {
		object-fit: contain;
	}
}

.catalog-section {
	padding-top: 5rem;

	&:has(.object-podsystem-slider) {
		.cat-list-desc__title {
			margin-bottom: 0;
		}
	}
}

.podsystem-slider {
	position: relative;
	margin-top: 1.8rem;
	margin-right: -2.5rem;
	margin-left: -1.25rem;

	.owl-item {
		padding: 0 1.25rem;
	}

	.owl-nav {
		position: absolute;
		right: 2.5rem;
		bottom: 100%;
		margin-bottom: 2rem;
	}

	.cat-list-itm {
		padding-bottom: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: var(--ui-bg-3);

		&__name {
			position: relative;
			padding: 1rem;
			@extend .h4;
			will-change: transform;
		}

		&__img {
			position: relative;
			display: block;
		}
	}

	&.cat-list--fill-img {
		.cat-list-itm {
			aspect-ratio: .75;

			&__img {
				margin: 0;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

			.fill-img {
				object-fit: cover;
			}
		}
	}
}

.text-collapse {
	position: relative;
	overflow: hidden;
	text-wrap: balance;
	line-height: 120%;
	//height: 1000rem;

	&.has-expand {
		cursor: pointer;
		transition: color .2s ease-in-out;

		&:after {
			opacity: 1;
		}

		@include hover {
			color: var(--ui-accent-1);
		}
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 4rem;
		background: linear-gradient(0deg, var(--ui-bg-light), rgba(255, 255, 255, 0));
		pointer-events: none;
		transition: opacity .8s ease;
		opacity: 0;
	}

	&.show {
		&:after {
			opacity: 0;
		}
	}

	&--width {
		width: 71%;
	}

}

.catalog-filter-more {
	align-self: flex-end;
	@extend .h4;
	color: var(--ui-accent-1);
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: all .3s;
	margin-bottom: 0.65rem;

	svg {
		width: 1rem;
		height: 1rem;
		fill: var(--ui-accent-1);
		margin-left: 0.25rem;
		transition: all .3s;
	}

	.less-text {
		display: none;
	}

	@include hover {
		color: var(--ui-accent-2);
		svg {
			fill: var(--ui-accent-2);
		}
	}

	&.active {
		.less-text {
			display: inline;
		}

		.more-text {
			display: none;
		}

		svg {
			transform: rotate(180deg);
		}
	}
}

.catalog-sku-mobile {
	display: none;
}

.catalog-table-attr-desc {
	display: none;
}

.tr-skeleton {
	height: 3.1rem;
	position: relative;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 1rem;
		bottom: 1rem;
		right: 0;
		background: var(--ui-bg-3, #D8DFEB);
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		width: 50%;
		top: 1rem;
		bottom: 1rem;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
		animation: skeleton_after 2s ease-in-out infinite;
	}

}

@keyframes skeleton_after {
	0% {
		left: 0;
		opacity: 0;
		transform: translateX(-50%);
	}
	30% {
		opacity: 1;
	}
	70% {
		opacity: 1;
	}
	100% {
		left: 100%;
		transform: translateX(-100%);
		opacity: 0;
	}
}

.catalog-tech-block {
	width: 42.875rem;
	max-width: 100%;
	margin: 2rem auto 0;

	&--full {
		width: 100%;
	}
}

.catalog-tech-itm {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	font-size: var(--fs-s2);
	font-weight: 400;
	line-height: 110%;
	letter-spacing: 0.025rem;
	border-bottom: 1px solid var(--ui-gray-3, #C6CBD6);

	&:last-child {
		border-bottom: none;
		margin-bottom: 0;
	}

	&__name {
		color: var(--font-gray-1, #596175);
	}

	&__val {
		text-align: right;
	}
}

.catalog-detail-desc-mobile {
	display: none;
}

.catalog-filter-mobile-line {
	display: none;
}

.catalog-price-request-btn {
	display: none;
}

.podsystem-price {
	margin-top: 0.75rem;
	margin-bottom: 0.2rem;
	color: var(--ui-accent-1);
	font-family: $F_title;
	font-weight: 500;

	&__val {
		display: inline-block;
		font-size: 3.4375rem;
		line-height: 1;
	}

	&__cur {
		display: inline-block;
		font-size: var(--fs-h3);
	}

	&-desc {
		margin-bottom: 2rem;
		color: var(--font-gray-1, #596175);
		line-height: 110%;
		letter-spacing: 0.02rem;
		font-size: var(--fs-small);
	}
}

.object-podsystem-slider {
	position: relative;
	display: flex;
	margin-right: -2.5rem;
	margin-top: 1.8rem;
	padding-right: 13%;

	.owl-stage-outer {
		overflow: hidden;
		margin-right: -14%;
		width: auto;
	}

	.owl-nav {
		position: absolute;
		right: 2.5rem;
		bottom: 100%;
		margin-bottom: 2rem;
	}

	> .object-podsystem-slider-itm {
		width: 33.33%;
	}

	.owl-item {
		margin-right: 2.5rem;
	}

	&-itm {
		@include hover {
			.object-podsystem-hover {
				opacity: 1;
			}
		}
	}
}

.object-podsystem-hover {
	@include fill();
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	transition: opacity .3s;
	opacity: 0;

	&:before {
		content: "";
		@include fill();
		background: rgba(40, 41, 54, .9);
	}

	&-itm {
		position: relative;
		display: flex;
		gap: 1rem;
		justify-content: space-between;
		color: #fff;
		font-size: 1rem;
		line-height: 110%;
		letter-spacing: 0.025rem;

		&:not(:last-child) {
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
			border-bottom: 1px solid var(--font-gray-1);

		}

		&__name {
			color: var(--font-gray-2, #93949A);
		}

		&__val {
			width: 11.375rem;
			max-width: 100%;
			text-align: right;
		}
	}
}


.object-podsystem-slider-itm {
	&__img {
		position: relative;
		padding-bottom: 127.7%;
		margin-bottom: 1.5rem;
		background: var(--ui-bg-2);
	}

	&__name {
		@extend .h3;
	}
}

.podsystem-tabs {
	margin-top: 1.5rem;

	&-nav {
		position: sticky;
		top: 5rem;

		ul {
			list-style: none;

			li {
				margin-bottom: .1rem;
			}

			button {
				padding: 0.59rem 1rem 0.59rem 0;
				@extend .h4;
				font-weight: 500;
				line-height: 100%;
				text-align: left;

				@include hover {
					color: var(--ui-accent-1);
					transition: color .3s;
				}
			}
		}

		&-progress {
			width: 0.25rem;
			height: 2rem;
			background: var(--font-accent-1, #007363);
			position: absolute;
			left: -2.5rem;
			top: 0;
		}

	}

	&-title {
		margin-bottom: 1.5rem;
	}


	&-content {

		.podsystem-slider {
			margin-right: -5rem;

			.owl-nav {
				right: 5rem;
			}
		}

		&__itm {
			overflow: hidden;
			height: 0;
			margin-right: -2.5rem;
			padding-right: 2.5rem;
		}
	}

	.podsystem-slider {
		margin-bottom: 1.5rem;
	}

	&__text {
		width: 43rem;
		max-width: 100%;
	}

	.catalog-tech-block {
		margin-left: 0;
	}

}

.podsystem-video-block {
	position: relative;
	width: 100%;
	margin-bottom: 2rem;

	video {
		width: 100%;
	}
}

.tech-doc-list {
	width: 43rem;
	max-width: 100%;
	margin-top: -1.25rem;

	&--full {
		width: 100%;
	}
}

.tech-doc-itm {
	display: flex;
	align-items: center;
	padding: 1.25rem 0;
	font-size: var(--fs-s2);
	font-weight: 400;
	line-height: 110%;
	letter-spacing: 0.025rem;
	transition: color .2s ease;

	&:not(:last-child) {
		border-bottom: 1px solid var(--ui-gray-3);
	}

	&__name {
		flex: 1;
		margin-right: 3.12rem;
	}

	&__size {
		color: var(--font-gray-1, #596175);
		margin-right: 3.12rem;
		white-space: nowrap;
		width: 6.5rem;
		font-size: 1rem;
	}


	&__ico {
		flex-shrink: 0;
		display: flex;
		align-items: center;

		&:first-child {
			svg {
				margin-right: 1rem;
			}
		}

		&:has(.tech-doc-itm__ico__text) {
			svg {
				margin-right: .5rem;
			}
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--ui-accent-1);
		}

		&__text {
			font-size: var(--fs-h5);
			font-family: $F_title;
			color: var(--ui-accent-1);
			line-height: 100%;
		}
	}

	@include hover {
		color: var(--ui-accent-1);
	}
}

.podsystem-node {
	&-list {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 2.5rem;
	}

	&-itm {
		display: inline-block;
		position: relative;

		&__img {
			display: block;
			position: relative;
			padding-bottom: 88.5%;
			margin-bottom: 0.75rem;
		}

		&__name {
			display: block;
			font-size: var(--fs-h6);
			font-weight: 500;
			line-height: 120%; /* 1.05rem */
			font-family: $F_title;
		}
	}
}

.sort-modal {
	display: none;
}

td, th {
	&.catalog-empty-td {
		width: 25.8%;
		padding: 0;
		border: none;
		pointer-events: none;

		+ td, + th {
			padding-left: 0;
		}
	}
}

.catalog-table {
	th.sort {
		position: sticky;
		top: 4.5rem;
		background: var(--ui-bg-2);
	}
}

.catalog-see-more-block {
	margin-bottom: 5rem;
}

.catalog-line-label {
	position: relative;
	padding: 5rem 2.5rem;
	background: var(--ui-bg-3);
	margin: 5rem -2.5rem -2.5rem;

	&__main {
		display: flex;
		align-items: center;
		line-height: 140%; /* 1.75rem */
		letter-spacing: 0.025rem;
		font-size: var(--fs-p2);

		picture {
			flex-shrink: 0;
			margin-right: 2.5rem;
		}
	}

	&__img {
		width: 6.875rem;
		height: 6.875rem;
		object-fit: cover;
		flex-shrink: 0;
		display: block;
	}

	&__link {
		display: block;
		width: 3rem;
		margin-left: auto;

		svg {
			width: 3rem;
			height: 3rem;
			fill: var(--ui-accent-1);
			transition: all .2s;
		}

		@include hover {
			svg {
				fill: var(--ui-accent-2);
			}
		}
	}

	.content-grid {
		align-items: center;
	}
}

@media (min-width: 850px) {
	.mobile-filter-closed {
		transform: none !important;
	}
}

@include for-tablet {
	body {
		&:has(.catalog-price-request-btn) {
			.fly-chat {
				margin-bottom: 4.5rem;
			}
		}
	}

	.catalog-detail-title {
		font-size: var(--fs-h3);
	}

	.catalog-section {
		padding-top: 3rem;

		.object-podsystem-slider {
			margin-bottom: 0;
		}
	}

	.content-grid {
		grid-template-columns: 1fr;
		display: block;

		&-main, &-main-full, &-main-right, &-aside, &-aside-right {
			grid-column: 1;

			&.empty {
				display: none;
			}
		}

		&-main-right {
			//overflow: hidden;
			width: 100%;
		}
	}

	.cat-list {
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
		margin-top: 1rem;
		margin-bottom: 3rem;

		&-itm {

			&--fill {
				padding-bottom: 79.12%;
				min-height: 0;

				.cat-list-itm__name {
					font-size: var(--fs-h5);
					padding: 1rem;
				}
			}

			&__name {
				display: block;
				font-size: var(--fs-h5);
				padding: 1rem 1rem 0;
				z-index: 1;
			}

			&__img {
				margin: 1rem;
				width: calc(100% - 2rem);
			}
		}

		&-items {
			grid-template-columns: repeat(3, 1fr);

			.cat-list-itm__name {
				font-size: var(--fs-s3);
				line-height: 1;
			}
		}
	}


	.cat-list-desc {
		&__title {
			width: 70%;

			br {
				display: none;
			}
		}

		&__text {
			position: relative;
			margin-left: 0;
			width: 100%;
			margin-top: 1rem;
		}
	}

	.catalog-filter {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		pointer-events: none;
		display: flex;
		flex-direction: column;
		padding: 0;
		background: var(--ui-bg-light, #F0F3FA);
		transform: translateX(100%);
		overflow: auto;
		gap: 0;
		margin: 0;

		&.active {
			pointer-events: auto;
		}

		&__head {
			background: var(--ui-bg-2, #E2E8F0);
			padding: 1.5rem 1rem;
			margin-bottom: 0;
			position: sticky;
			top: 0;
			z-index: 2;

			&__close {
				display: block;

				.cross {
					width: 1.125rem;
					height: 1.125rem;
				}
			}

			.h3 {
				font-size: var(--fs-h1-small);
			}

			.catalog-filter-reset {
				display: none;
			}
		}

		&-form {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;

			&:has(.has-val) {
				.catalog-filter {
					&-reset, &-submit {
						pointer-events: auto;
						color: var(--ui-accent-1);

						svg {
							fill: var(--ui-accent-1);
						}

						@include hover {
							color: var(--ui-accent-2);

							svg {
								fill: var(--ui-accent-2);
							}
						}
					}
				}
			}
		}

		&-more {
			display: none;
		}

		&-form-content {
			display: block;
			height: auto !important;
			padding: 1rem;
		}

		&-footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: sticky;
			bottom: 0;
			background: var(--ui-bg-2, #E2E8F0);
			padding: 1.5rem 1rem;
			z-index: 9;
		}

		&-reset, &-submit {
			display: flex;
			font-size: var(--fs-h4);
			font-weight: 500;
			font-family: $F_title;
			color: var(--ui-no-active);
			pointer-events: none;
			transition: all .2s;

			&.active {
				pointer-events: none;
				color: var(--ui-no-active);

				svg {
					fill: var(--ui-no-active);
				}
			}
		}

		&-itm {

			&.input {
				border-bottom: 1px solid var(--ui-bg-2, #E2E8F0);
			}

			&.filter-hide-itm {
				opacity: 1;
				transform: none;
			}

			&__name, .custom-select__single {
				font-size: var(--fs-h5);
			}

			.custom-select {
				&__val {
					visibility: hidden;
				}

				&.has-val {
					.input-label {
						transform: translateY(-35%);
					}
				}
			}
		}

		.check_box {
			&__item {
				background: var(--ui-bg-2, #E2E8F0);
				border-color: var(--ui-bg-2, #E2E8F0);
			}
		}

		.range-input-wrap {
			padding: 0 0 0.5rem;
		}
	}

	.custom-select {
		border-bottom: 1px solid var(--ui-bg-2, #E2E8F0);

		&__single {
			position: relative;
			border-bottom: none;
			padding-top: 1rem;
		}

		&__dropdown {
			position: relative;
			height: 0;
			max-height: inherit;
			margin: 0;
			opacity: 1;
			overflow: hidden;
			transform: none;
			transition: none;
			left: 0;
			pointer-events: auto;

			&-item {
				padding-left: 0;
				padding-right: 0;
			}

			.check_box {
				width: 100%;
				justify-content: space-between;

				&__item {
					margin-right: 0;
				}

				&__text {
					order: -1;
				}
			}

			&-list {
				padding-bottom: 1rem;
				padding-top: .5rem;

				li {
					padding-top: 0;
					padding-bottom: 0;

					&:not(:last-child) {
						margin-bottom: 0.75rem;
					}
				}
			}
		}
	}

	.catalog-detail-content:has(.catalog-detail) {
		padding: 0;
	}

	.catalog-detail {
		padding: 1rem 1rem 2.5rem;
		margin-bottom: 0;
		overflow: hidden;

		&-title {
			width: 80%;
			margin-bottom: 0.5rem;
		}
	}

	.catalog-sku-mobile {
		display: block;
		font-weight: 500;
		line-height: 100%;
	}

	.catalog-detail-slider {
		margin: 1rem 0 0;
		padding-right: 0;

		.owl-stage-outer {
			padding-right: 0;
		}

		&-itm {
			padding-bottom: 52.81%;
		}

		.owl-item {
			margin-right: 0;
		}

		.owl-nav {
			right: 1rem;
			bottom: 1rem;

			button {
				width: 1rem;
				height: 1rem;

				&:not(:last-child) {
					margin-right: .5rem;
				}
			}
		}
	}

	.catalog-table {
		display: block;

		tbody {
			display: block;
			padding-top: 0;
		}

		thead {
			display: none;
		}

		tr {
			display: flex;
			flex-wrap: wrap;
			background: var(--ui-bg-3, #D8DFEB);
			padding: 1rem 1rem 0.5rem;
			margin-bottom: 0.75rem;
		}

		td {
			display: block;
			width: 50%;
			border-bottom: none;
			margin-bottom: 0.5rem;
			padding: 0;
			line-height: 110%;
			letter-spacing: 0.02rem;

			&.catalog-empty-td {
				display: none;
			}

			&:nth-child(2) {
				width: 100%;
				font-weight: 500;
				font-size: var(--fs-h5);
				font-family: $F_title;
			}
		}
	}

	.catalog-table-attr-desc {
		display: block;
		color: var(--font-gray-1, #596175);
		font-weight: 400;
	}

	.catalog-detail-desc-mobile {
		display: block;
		margin-top: 1rem;
		margin-bottom: 3rem;

		&.mb0 {
			margin-bottom: 0;
		}
	}

	.catalog-filter-mobile-line {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1rem;
		margin-top: 3rem;
	}

	.catalog-filter-sort {
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	.catalog-filter-btn {
		display: flex;
		align-items: center;
		color: var(--ui-accent-1);
		font-size: var(--fs-h5);
		font-weight: 500;
		line-height: 100%;
		font-family: $F_title;

		svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--ui-accent-1);
			margin-right: .25rem;
		}
	}

	.gray-widget {
		.podsystem-slider, .object-podsystem-slider {
			overflow: visible;
		}

	}

	.podsystem-slider {
		margin-left: -1rem;
		margin-right: -1rem;
		width: auto;
		margin-top: 1.18rem;
		padding: 0 .5rem;
		overflow: hidden;

		.owl-stage-outer {
			overflow: visible;
		}

		.owl-item {
			padding: 0 .5rem;

			&:not(:last-child) {
				margin-right: 0;
			}
		}

		.cat-list-itm__name {
			font-size: 1rem;
		}

		.owl-nav {
			right: 1rem;
			margin-bottom: 1.45rem;

			button {
				width: 1rem;
				height: 1rem;

				&:not(:last-child) {
					margin-right: 0.5rem;
				}
			}
		}
	}

	.catalog-tech-block {
		margin-top: 1rem;
	}
	.catalog-tech-itm {
		padding-bottom: .75rem;
		margin-bottom: .75rem;

		&__name {
			font-size: 1rem;
		}

		&__val {
			font-size: 1rem;
		}
	}

	.wrapper {
		&:has(.catalog-price-request-btn) {
			padding-bottom: 4rem;
		}
	}

	.catalog-price-request-btn {
		position: fixed;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		color: var(--ui-accent-1);
		font-size: var(--fs-h3);
		background: #fff;
		font-family: $F_title;
		font-weight: 500;
		width: 100%;
		z-index: 2;

		svg {
			width: 1rem;
			height: 1rem;
			fill: var(--ui-accent-1);
		}
	}

	.tr-skeleton {
		height: 11.375rem;
	}

	.catalog-filter-form .check_box__item:before {
		@include fill(0);
	}

	.podsystem-tabs {
		padding-top: 0;

		&-nav {
			overflow: auto;
			padding-bottom: .2rem;
			margin-bottom: 1.5rem;

			&-progress {
				display: none;
			}

			ul {
				display: flex;
				white-space: nowrap;

				li {
					&:not(:last-child) {
						margin-right: 1.5rem;
					}

					&.active {
						border-bottom: 3px solid var(--ui-accent-1);
					}
				}

				button {
					padding-right: 0;
					padding-bottom: .5rem;
				}
			}

			&-itm {
				&.active {
					border-bottom: 2px solid var(--ui-accent-1);
				}
			}
		}

		&-content {

			&__itm {
				margin-left: -1rem;
				margin-right: -1rem;
				padding: 0 1rem;
			}

			.podsystem-slider {
				margin-right: -1rem;

				.owl-nav {
					right: 1rem;
				}
			}
		}
	}

	.podsystem-tabs-content-inner {
		width: 100%;
	}

	.podsystem-node-list {
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
	}

	.tech-doc-itm {
		position: relative;
		display: block;
		padding-right: 2rem;

		&__ico {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);

			&__text {
				display: none;
			}

			&:has(.tech-doc-itm__ico__text) {
				svg {
					margin-right: 0;
				}
			}
		}

		&__name {
			margin-bottom: .5rem;
		}

		&__size {
			font-size: var(--fs-h6);
		}
	}

	.catalog-sku-mobile {
		font-family: $F_title;
	}

	.sort-modal {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		pointer-events: none;

		&.active {
			pointer-events: auto;

			.sort-modal {
				&__fade {
					opacity: 0.5;
				}

				&-content {
					transform: translateY(0%);
				}
			}
		}

		&__fade {
			@include fill();
			background: #000;
			opacity: 0;
			transition: opacity .3s;
		}

		&-content {
			position: relative;
			background: var(--ui-bg-light, #F0F3FA);
			padding: 1rem;
			transform: translateY(100%);
			transition: transform .3s;
			overflow: auto;
			max-height: 100vh;
			overscroll-behavior-y: contain;
		}

		&-close {
			position: sticky;
			top: 0;
			right: 0;
			float: right;
		}

		&__title {
			font-family: $F_title;
			font-weight: 500;
		}

		&__itm {
			display: flex;
			align-items: center;
			font-size: var(--fs-h5);
			font-family: $F_title;
			font-weight: 500;
			line-height: 100%;
			padding: 0.75rem 0;
			width: 100%;

			&:not(:last-child) {
				border-bottom: 1px solid #D8DFEB;
			}

			svg {
				width: 1rem;
				height: 1rem;
				margin-left: .25rem;
				opacity: 0;
			}

			&.active {
				color: var(--ui-accent-1);

				svg {
					opacity: 1;
				}
			}

			&.sort-asc {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	.catalog-detail-desc-mobile + .show-t .podsystem-price-mobile {
		margin-top: -2rem;
	}

	.podsystem-price-mobile {
		display: flex;
		align-items: center;
		margin-top: 1rem;

		&__title {
			font-size: var(--fs-h5);
			font-family: $F_title;
			font-weight: 500;
			line-height: 100%;
			margin-right: 2rem;
		}
	}

	.podsystem-price {
		margin-top: .5rem;

		&__val {
			font-size: 3rem;
		}

		&__cur {
			font-size: 1rem;
		}

		&-desc {
			font-size: var(--fs-p4);
		}
	}

	.podsystem-tabs .content-grid-aside {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			bottom: -2px;
			left: -1rem;
			right: -1rem;
			height: 10px;
			background: var(--ui-bg-2);
		}
	}

	.podsystem-tabs-nav {
		margin-left: -1rem;
		margin-right: -1rem;
		padding-left: 1rem;
		padding-bottom: .5rem;
		margin-bottom: 1rem;


		li {
			margin-right: 1.5rem;
		}

		ul button {
			font-size: 1rem;
		}
	}

	.object-podsystem-hover {
		display: none;
	}

	.object-podsystem-slider {
		padding: 0 .5rem;
		margin: 1.18rem -1rem 1.45rem;

		.owl-stage-outer {
			overflow: hidden;
			padding-right: 0;
			margin-right: 0;
		}

		.owl-item {
			margin-right: 0;
			padding: 0 .5rem;
		}

		.owl-nav {
			right: 1rem;
			margin-bottom: 1.45rem;

			button {
				width: 1rem;
				height: 1rem;

				&:not(:last-child) {
					margin-right: .5rem;
				}
			}
		}

		&-itm {
			&__img {
				margin-bottom: .75rem;
			}

			&__name {
				font-size: 1rem;
			}
		}
	}

	.catalog-see-more-block {
		margin-bottom: 3rem;
	}

	.catalog-line-label {
		padding: 1.5rem 1rem;
		margin: 3rem -1rem -2.5rem -1rem;

		.content-grid {
			display: flex;
		}

		picture {
			width: 6.25rem;
			margin-right: 1rem;
		}

		&__img {
			width: 6.25rem;
			height: 6.25rem;
		}

		&__desc {
			font-size: var(--fs-p4);
			line-height: 130%;
			letter-spacing: 0.0175rem;
		}

		&__link {
			width: 1.5rem;
			height: 1.5rem;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.owl-drag {
		-ms-touch-action: pan-y;
		touch-action: pan-y;
	}

	.podsystem-tabs-title {
		margin-bottom: 1.18rem;
	}
}

@include for-phone {

	.wrapper {
		&:has(.catalog-price-request-btn) {
			padding-bottom: 3rem;
		}
	}

	body {
		&:has(.catalog-price-request-btn) {
			.fly-chat {
				margin-bottom: 4rem;
			}
		}
	}

	.catalog-section:has(.object-podsystem-slider) .cat-list-desc__title {
		padding-right: 3rem;
	}

	.catalog-filter-mobile-line {
		margin-top: 2rem;
	}

	.catalog-filter {
		&__head {
			padding: 1rem;
		}

		&-itm {
			&__name, .custom-select__single {
				font-size: var(--fs-h3);
			}
		}

		&-footer {
			padding: 1rem;
		}

		&-reset, &-submit {
			font-size: var(--fs-h3);
		}

		&-reset {
			svg {
				width: 1rem;
				height: 1rem;
				margin-right: .25rem;
			}
		}
	}

	.object-podsystem-slider-itm__name {
		font-size: var(--fs-h6);
	}

	.cat-list {
		grid-template-columns: 1fr;
		gap: 0.5rem;
		margin-bottom: 2.5rem;

		&-items {
			grid-template-columns: repeat(2, 1fr);

			.cat-list-itm__name {
				font-size: var(--fs-h6);
				padding: 0.5rem 0.5rem 0;
			}
		}

		&-itm {
			&--fill {
				padding-bottom: 76.6%;

				.cat-list-itm__name {
					font-size: var(--fs-h3);
				}
			}

			&__name {
				font-size: var(--fs-h3);
			}
		}

		&-desc {
			padding-bottom: 2rem;

			&__title {
				width: 100%;
			}

			&__text {
				margin-top: 0.25rem;
			}
		}
	}

	.catalog-detail-slider-itm {
		padding-bottom: 59%;
	}

	.catalog-detail-title {
		width: 100%;
		font-size: var(--fs-h1-small);
		margin-bottom: 1rem;
	}

	.catalog-table {
		td {
			width: 100%;
		}

		tr {
			display: block;
		}
	}

	.catalog-table-attr {
		&-desc {
			display: inline;
		}

		&-val {
			display: inline;
		}
	}

	.catalog-section {
		padding-top: 2.5rem;

	}

	.podsystem-slider {
		margin-right: -.5rem;
		margin-top: .75rem;

		.owl-item {
			padding: 0 .25rem;

			&:not(:last-child) {
				margin-right: 0;
			}
		}

		.cat-list-itm {
			&__img {
				min-height: 4rem;
			}

			&__name {
				font-size: var(--fs-small);
				line-height: .9rem;
				padding: .5rem;
				margin-bottom: .5rem;
			}
		}

		.owl-nav {
			margin-bottom: .93rem;
			right: .5rem;
		}
	}

	.podsystem-price {
		margin-top: .4rem;

		&__val {
			font-size: 2.375rem;
		}

		&__cur {
			font-size: var(--fs-h6);
		}
	}

	.podsystem-price-desc {
		font-size: var(--fs-small);
		width: 12.75rem;
	}

	.podsystem-price-block .h3 {
		margin-right: 1.5rem;
	}

	.podsystem-node-list {
		grid-template-columns: repeat(2, 1fr);
		gap: .5rem;
	}

	.podsystem-node-itm {
		&__img {
			margin-bottom: .5rem;
		}

		&__name {
			font-size: var(--fs-small);

		}
	}

	.podsystem-tabs {
		padding-top: 0;
	}

	.podsystem-tabs-title {
		margin-bottom: .875rem;
		line-height: 105%;
		transform: translateY(5%);
	}

	.tech-doc-list {
		margin-top: -.75rem;
		margin-bottom: .75rem;
	}

	.tech-doc-itm {
		padding: 0.75rem 0;

		&__size {
			font-size: 0.75rem;
		}

		&__name {
			margin-right: 2.5rem;
			margin-bottom: .25rem;
		}
	}

	.catalog-tech-itm {
		padding-bottom: .75rem;
		margin-bottom: .75rem;
	}

	.sort-modal {
		font-size: .875rem;

		&-close {
			.cross {
				width: 1.25rem;
				height: 1.25rem;
				margin-top: -.25rem;
			}
		}

		&__itm {
			font-size: var(--fs-h3);
		}
	}

	.cat-list-desc__title {
		font-size: var(--fs-h3);
	}

	.catalog-tech-itm__name, .catalog-tech-itm__val {
		font-size: var(--fs-s1);
	}

	.catalog-table td {
		margin-bottom: .2rem;
		line-height: 100%;
	}
	.catalog-table {
		tbody {
			display: block;
			width: 100%;
		}

		tr {
			width: 100%;
		}

		td:nth-child(2) {
			font-size: .875rem;
		}
	}
	.catalog-table-attr-desc, .catalog-table-attr-val {
		font-size: 0.75rem;
		letter-spacing: 0.015rem;
	}

	.catalog-line-label {
		padding-top: 1.13rem;

		.cat-list-desc__title {
			margin-bottom: .8rem;

			br {
				display: none;
			}
		}

		.content-grid {
			display: block;
		}

		picture {
			margin-right: .87rem;
		}


		&__link {
			width: 1.75781rem;
			height: 1.75781rem;
			position: absolute;
			right: 1rem;
			top: .9rem;
		}
	}

	.object-podsystem-slider {
		margin-top: .75rem;

		.owl-nav {
			margin-bottom: .93rem;
		}
	}

	.text-collapse--width {
		width: 100%;
	}

}