.experience-section {
	padding: 7.5rem 0;

	+ .company-year-section {
		margin-top: 1rem;
	}
}

.experience-num {
	margin-top: 1rem;

	&-itm {
		display: grid;
		grid-template-columns: 25rem 1fr;
		padding: 2rem 0;
		gap: 3rem;

		&:not(:last-child) {
			border-bottom: 1px solid var(--ui-gray-3);
		}

		&__num {
			font-family: $F_title;
			font-size: 7.5rem;
			font-weight: 500;
			color: var(--ui-accent-1);
			line-height: 100%;
			margin-bottom: -1rem;
			//transform: translateY(-5%);
		}

		&__content {
			align-self: flex-end;
			margin-bottom: .5rem;
		}
	}
}

.buro-project-section {
	margin-bottom: 10rem;

	.content-grid-accordion-list {
		margin-top: 3rem;
	}
}

.buro-example-top {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	&__arrow {
		transform: translateY(-.4rem);
	}
}

.buro-example-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.5rem;
	margin-top: 3.5rem;
	margin-bottom: 0;
	align-items: flex-start;
	padding-bottom: 8rem;

	&-itm {
		display: block;
		text-align: left;
		transition: color .2s ease;
		will-change: transform;
		backface-visibility: hidden;

		@include hover {
			color: var(--ui-accent-1);

			.buro-example-grid-itm__img img {
				transform: scale(1.1);
			}
		}

		&:nth-child(1) {
			transform: translateY(20%);
		}

		&:nth-child(3) {
			transform: translateY(40%);
		}

		&__content {
			position: relative;
			padding-right: 3.5rem;
		}

		&__img {
			position: relative;
			height: 140%;
			width: 100%;
			will-change: transform;
			backface-visibility: hidden;

			&-wrap {
				overflow: hidden;
				aspect-ratio: 25/30;
				margin-bottom: 1.5rem;
			}

			img {
				transition: transform .6s ease;
				will-change: transform;
			}
		}

		&__name {
			margin-bottom: .5rem;
		}

		&__btn {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);

			&__name {
				display: none;
			}

			&__arrow {
				width: 1.5rem;
				height: 1.5rem;
				fill: var(--ui-accent-1);
				transition: all .2s ease;
			}
		}
	}
}

.buro-example-section {
	.aside-title-big {
		width: 50%;
	}
}

.buro-nadzor-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2.5rem;
	margin-top: 3rem;

	&-itm {
		background: var(--ui-bg-2);
		padding: 2rem;
		aspect-ratio: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__head {
			display: flex;
			justify-content: space-between;
			margin-bottom: 2rem;
			gap: 1rem;

			svg, img {
				flex-shrink: 0;
				width: 3.5rem;
				height: 3.5rem;
			}
		}

		&__text {
			font-size: var(--fs-p2);
			line-height: 140%;
			letter-spacing: 0.025rem;
		}
	}
}

.bim-widget {
	position: relative;

	&__content {
		position: relative;
		padding: 3rem 2.5rem;
		width: 50%;
		background: var(--bg-blur-dark, rgba(49, 60, 70, 0.50));
		backdrop-filter: blur(16px);
		color: #fff;
		text-wrap: balance;
	}

	.js-parallax {
		height: 130%;
		top: -15%;
	}

	&__title {
		margin-bottom: 3rem;
	}

	&__desc {
		line-height: 140%;

		p {
			margin-bottom: 2.5rem;
		}
	}

	&--buro {
		.bim-widget__content {
			width: 35%;
		}

		.bim-widget__desc {
			margin-bottom: 8rem;
		}
	}

	.accordion.active .plus {
		&:before, &:after {
			background-color: #fff;
		}
	}

	.accordion__head {
		@include hover {
			color: #fff;
		}
	}
}

.content-grid-buro-link {
	background: var(--ui-bg-2);
	margin-bottom: 1.5rem;
	align-items: center;
	padding: 2.5rem 0;
	position: sticky;
	top: 6rem;

	&:before {
		content: "";
		position: absolute;
		bottom: 100%;
		height: 1.5rem;
		left: 0;
		width: 100%;
		background-color: var(--ui-bg-light);
		z-index: 2;
	}

	@include hover {
		color: inherit;

		.content-grid-buro-link__itm .h2 {
			color: var(--ui-accent-1);
		}
	}

	&-block {
		counter-reset: link-num;
		margin-top: 3rem;
	}

	&__num {
		transition: color .2s;
		padding-left: 2.5rem;

		&:before {
			counter-increment: link-num;
			content: counter(link-num, decimal-leading-zero);
			@extend .h2;
			color: var(--font-primary);
		}
	}

	&__itm {
		.h2 {
			transition: color .2s;
			margin-bottom: 1rem;
		}

		&__desc {
			//@include line-clump(3);
		}
	}

	&__arrow {
		text-align: right;
		padding-right: 2.5rem;

		svg {
			width: 2rem;
			height: 2rem;
			fill: var(--ui-accent-1);
		}
	}
}

.aside-sert {
	width: 13rem;
}

.sert-desc-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 4rem;
	margin-top: 4rem;

	&-col {
		.h2 {
			margin-bottom: 2rem;
		}

		&__links {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-top: 2rem;

			.arrow-link {
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}
		}
	}
}

.design-cost-section {
	padding-bottom: 5rem;
}

.modal-inner-cols {
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	&__text {
		overflow: auto;
		padding: 4.5rem 2.5rem;

		.h2 {
			margin-bottom: 1.5rem;
		}

		&__desc {
			padding-left: 5rem;
			margin-bottom: 1.5rem;
		}
	}

	&__slider {
		position: relative;
		overflow: hidden;
		height: 100%;

		.modal-col-slider {
			height: 100%;

			.owl-item {
				position: relative;

				&:after {
					content: "";
					height: 6.25rem;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					background: var(--ui-img-gradient);
					background-blend-mode: multiply;
				}

			}

			.owl-nav {
				position: absolute;
				right: 2.5rem;
				bottom: 2.5rem;
				z-index: 1;

				button {
					svg {
						fill: #fff;
					}

					@include hover {
						svg {
							fill: var(--ui-accent-1);
						}
					}
				}
			}


		}
	}
}

.company-document-desc {
	grid-column: 5/10;
}

.company-document-img {
	position: relative;
	grid-column: 10/13;
	text-align: right;
}

.difficult-tasks-window {
	padding-top: 3.5rem;
	padding-bottom: 2.5rem;
	z-index: 1;

	.fill-img {
		z-index: -1;
		height: 120%;
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 30%;
		background: linear-gradient(90deg, rgba(49, 51, 70, 0.60) -0.51%, rgba(49, 51, 70, 0.00) 99.73%);
	}
}

.buro-first-window {
	overflow: hidden;

	.js-parallax {
		height: 120%;
	}
}

.content-grid-buro-link-block {
	margin-bottom: -2rem;

	.content-grid-buro-link {
		&:last-child {
			padding-bottom: 4.5rem;

			&:after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 2rem;
				background: var(--ui-bg-light);
			}
		}
	}
}

@include for-tablet {

	.buro-first-window {
		padding-top: 5.75rem;

		.window-padding {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.experience-section {
		padding: 5rem 0 3rem;

		.experience-num {
			margin-top: 1rem;
		}
	}

	.experience-num {
		margin-top: 0;
	}

	.experience-num-itm {
		display: block;
		padding: 1.5rem 0;

		&__num {
			line-height: normal;
			margin-bottom: -.5rem;
		}

		&__content {
			margin-bottom: 0;

			.h3 {
				font-size: var(--fs-h4);
				margin-bottom: .5rem;
			}
		}
	}

	.difficult-tasks-window {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;

		&:before {
			width: 100%;
		}

		.window-title {
			width: 50%;
		}
	}

	.buro-example-grid {
		grid-template-columns: repeat(2, 1fr);
		gap: 2.5rem 1rem;
	}

	.buro-example-top {
		.arrow-link {
			display: none;
		}

		.h1 {
			font-size: var(--fs-h1-small);
		}
	}

	.buro-example-section {
		padding-top: 5rem;
	}

	.buro-example-grid {
		padding-bottom: 0;
		margin-bottom: 2rem;
		margin-top: 2rem;

		&-itm {

			&__content {
				padding-right: 0;
			}

			&__btn {
				position: relative;
				top: 0;
				display: flex;
				align-items: center;
				transform: none;
				font-size: var(--fs-p2);
				font-family: $F_title;
				color: var(--ui-accent-2);

				&__name {
					display: block;
					margin-right: .5rem;
				}

				&__arrow {
					width: 1rem;
					height: 1rem;
					fill: var(--ui-accent-2);
				}
			}

			&__img {
				margin-bottom: 1rem;
			}

			&__name {
				font-size: var(--fs-h4);
				margin-bottom: .75rem;
			}

			&__place {
				margin-bottom: .5rem;
			}

			&:nth-child(1) {
				transform: none;
			}

			&:nth-child(3) {
				transform: none;
			}

		}
	}


	.buro-example-section {
		.arrow-link.show-t {
			display: flex;
		}
	}

	.buro-nadzor-grid {
		gap: 1rem;
		margin-top: 2rem;
		margin-bottom: 2rem;

		&-itm {
			padding: 1.5rem;
			aspect-ratio: auto;

			h2 {
				font-size: var(--fs-h5);
				width: 80%;
			}

			&__head {
				margin-bottom: .75rem;

				svg, img {
					width: 3rem;
					height: 3rem;
				}
			}

			&__text {
				font-size: var(--fs-p3);
			}
		}
	}

	.buro-nadzor-section {
		margin-bottom: 8.5rem;

		.arrow-link.show-t {
			display: flex;
		}
	}

	.bim-section {
		margin-top: 5rem;
		padding: 0;
	}

	.bim-widget {

		.fill-img {
			z-index: -1;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 10rem;
			pointer-events: none;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8));
			z-index: -1;
		}

		&__content {
			padding: 1.5rem 1rem;
			width: 100%;
			backdrop-filter: none;
			display: flex;
			flex-direction: column;
		}

		&__title {
			font-size: var(--fs-h1-small);
			margin-bottom: 1rem;
		}

		&__desc {
			font-size: var(--fs-p1);
			flex: 1;
		}

		.accordion {
			&:not(:last-child) {
				margin-bottom: 1.5rem;
				padding-bottom: 1.5rem;
			}

			&__head__name {
				font-size: var(--fs-h3);
			}
		}

		&--buro .bim-widget__desc {
			margin-bottom: 2rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}

	.buro-project-section {
		margin-bottom: 5rem;

		.content-grid-accordion__head {
			font-size: var(--fs-h4);
		}

	}

	.content-grid-buro-link {
		padding: 1rem;
		margin-bottom: 1rem;
		top: 4rem;

		&:before {
			height: 1rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.h2 {
			position: relative;
			color: var(--ui-accent-1);
			padding-right: 2rem;

			&:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 0;
				width: 1rem;
				height: 1rem;
				background: var(--ui-accent-1);
				mask: url("../img/svg/arrow-right-bold.svg") no-repeat center;
				mask-size: 1rem;
				transform: translateY(-50%);
			}
		}

		&__num {
			display: none;
		}

		&__itm {

			&__desc {
				font-size: var(--fs-p3);
			}

			.h2 {
				font-size: var(--fs-h4);
			}
		}

		&__arrow {
			display: none;
		}

		&-block {
			margin-top: 1.5rem;
		}
	}

	.buro-document-section {
		position: relative;

		.aside-title {
			width: 50%;
		}

		.aside-sert {
			position: absolute;
			top: 0;
			right: 1rem;
			width: 9rem;
		}

		.aside-sert-desc {
			width: 60%;
		}
	}

	.sert-desc-grid {
		display: block;
		margin-top: 2rem;

		&-col {

			&:not(:last-child) {
				padding-bottom: 2rem;
				margin-bottom: 2rem;
				border-bottom: 1px solid #C6CBD6;
			}

			.h2 {
				margin-bottom: .75rem;
			}

			&__links {
				margin-top: 1.5rem;
			}
		}
	}

	.design-cost-section {
		padding-top: 2rem;
		padding-bottom: 2rem;

		.aside-title {
			font-size: var(--fs-h1-small);
		}

		.aside-title {
			width: 80%;
		}
	}

	.bim-widget--buro {
		margin-left: -1rem;
		margin-right: -1rem;

		.bim-widget__content {
			width: 100%;
		}
	}

	.company-document {

		&-block {
			position: relative;
			padding-right: 15rem;
			padding-bottom: .1rem;

			.arrow-link {
				position: absolute;
				bottom: -3rem;
				left: 0;
			}
		}

		&-img {
			position: absolute;
			top: 0;
			right: -15rem;
		}

	}

	.company-production-section {
		.aside-title {
			width: 60%;
		}
	}

	.modal-inner-cols {
		display: block;

		&__slider {
			aspect-ratio: 1;
		}

		&__text {
			padding: 1.5rem;

			.h2 {
				margin-bottom: .75rem;
				padding-right: 2rem;
			}

			&__desc {
				padding-left: 0;
			}
		}

	}

	.buro-project-section .content-grid-accordion-list {
		margin-top: .25rem;
	}

	.content-grid-accordion__head {
		padding-right: 0;
	}

	.bim-widget__desc p {
		margin-bottom: 1rem;
	}


	.buro-example-section {
		.aside-title-big {
			width: 100%;
		}
	}

	.content-grid-buro-link-block {
		.content-grid-buro-link:last-child {
			padding-bottom: 3rem;
		}
	}

}

@include for-phone {

	.buro-first-window {
		padding-top: 0;

		.window-padding {
			padding-top: 4.2rem;
			padding-bottom: 0;
		}
	}

	.experience-section {
		padding-bottom: 1.5rem;
	}

	.experience-num {
		&-itm {
			padding: 1.5rem 0;

			&:first-child {
				padding-top: .5rem;
			}

			&__num {
				font-size: 5rem;
				margin-bottom: 0;
			}

			.h3 {
				font-size: var(--fs-h2);
				margin-bottom: .25rem;
			}

			.s2 {
				font-size: var(--fs-s1);
			}
		}
	}

	.buro-project-section {
		margin-bottom: 3.7rem;

		.content-grid-accordion-list {
			margin-top: 1rem;
		}

		.content-grid-accordion__head {
			padding-right: 0;

			.plus {
				width: 1rem;
				height: 1rem;
				display: block;
			}
		}
	}

	.buro-example-section {
		padding-top: 3.7rem;
		padding-bottom: 3.7rem;

		.buro-example-grid {
			margin-bottom: 0;
		}

		&.window-padding {
			.buro-example-grid {
				margin-bottom: 1.25rem;
			}
		}

		+ .section-big-margin {
			margin-top: 0;
		}
	}

	.buro-example-grid {
		gap: 1.25rem .5rem;
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;

		&-itm {
			will-change: transform;
			backface-visibility: hidden;

			&__name {
				font-size: var(--fs-s1);
				margin-bottom: 0.25rem;
			}

			&__btn {
				font-size: var(--fs-h6);

				&__name {
					margin-right: .2rem;
				}

				&__arrow {
					width: .5rem;
					height: .5rem;
				}
			}

			&__place {
				margin-bottom: .25rem;
			}
		}
	}

	.buro-nadzor-grid {
		display: block;
		margin-bottom: 1.5rem;

		&-itm {
			padding: 1rem;

			&__head {
				margin-bottom: .5rem;
			}

			h2 {
				font-size: var(--fs-h1-small);
			}

			&:not(:last-child) {
				margin-bottom: .62rem;
			}
		}
	}

	.bim-section {
		margin-top: 3.7rem;
	}

	.bim-widget {
		&__title {
			font-size: var(--fs-h1-big);
			margin-bottom: .75rem;
		}

		&__desc {
			font-size: var(--fs-p1);
			margin-bottom: 1rem;

			p {
				margin-bottom: .75rem;
			}
		}

		.js-parallax {
			height: 140%;
			min-height: 80vh;
			top: -20%;
		}
	}

	.content-grid-buro-link {
		margin-left: -1rem;
		margin-right: -1rem;
		margin-bottom: .6rem;

		&:before {
			height: .6rem;
		}

		&__itm {
			.h2 {
				margin-bottom: .75rem;
				font-size: var(--fs-h3);
			}

			&__desc {
				-webkit-line-clamp: 5;
			}
		}
	}

	.buro-document-section {
		overflow: hidden;

		.aside-sert {
			right: -10%;
		}
	}

	.buro-document-section .aside-title {
		margin-bottom: 1rem;
	}

	.sert-desc-grid-col {
		.h2 {
			font-size: var(--fs-h3);
		}

		&:not(:last-child) {
			margin-bottom: 1.5rem;
			padding-bottom: 1.5rem;
		}
	}

	.modal-inner-cols__text {
		padding: 1rem;

		&__desc {
			margin-bottom: .5rem;
		}
	}

	.company-document-block {
		padding-right: 6rem;

		.company-document-img {
			right: -2rem;
		}

		.aside-sert {
			width: 9rem;
			transform: translateX(90%);
		}
	}

	.buro-nadzor-section {
		margin-bottom: 6.5rem;
	}

	.bim-widget__desc p {
		margin-bottom: .75rem;
	}

	.bim-widget .accordion:not(:last-child) {
		padding-bottom: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.design-cost-section {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;


		.title-margin {
			margin-bottom: .87rem;
		}

		.aside-title {
			margin-bottom: .55rem;
		}
	}

	.modal-inner-cols__slider .modal-col-slider .owl-nav {
		right: 1rem;
		bottom: 1rem;

		button {
			width: 1rem;
			height: 1rem;

			&:not(:last-child) {
				margin-right: .5rem;
			}
		}
	}

	.buro-project-section {
		.content-grid-accordion__head {
			font-size: var(--fs-h3);
		}
	}

	.buro-example-top {
		.h1 {
			font-size: var(--fs-h1);
		}
	}

}