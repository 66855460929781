.ventfasad-hero {
	position: relative;
	overflow: hidden;

	.window {
		height: auto;
		min-height: 100lvh;

		.column-sb {
			min-height: 100lvh;
		}
	}

	&__shadow-image {
		@include fill();
		will-change: opacity;

		svg {
			@include pos-center();
			min-width: 100%;
			min-height: 100%;
			object-fit: cover;
			object-position: center;
			width: auto;
			height: auto;
		}

	}

	&__shadow {
		@include fill();
		background: rgba(41, 49, 57, 0.9);
	}

	.window {
		position: relative;
	}

	.window-title {
		line-height: 90%;
		letter-spacing: -0.05rem;
		margin-bottom: 2rem;
	}

	.window-bot-text {
		width: 40%;

		&--large {
			width: 50%;
		}
	}

	&__dots {
		display: none;
		pointer-events: none;
	}

	&__window-2 {
		.window-title {
			width: 50%;
		}
	}
}

.ventfasad-main-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	will-change: transform;
	backface-visibility: hidden;
	overflow: hidden;

	&__img {
		@include fill();
		max-height: 100lvh;
	}


}

.ventfasad-example {
	&__head {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.buro-example-grid-itm {
		&__btn__arrow {
			fill: #fff;
		}

		@include hover {
			.buro-example-grid-itm__btn__arrow {
				fill: var(--ui-accent-1);
			}
		}
	}
}

.ventfasad-advantages {
	margin-bottom: -5rem;

	&:after {
		content: "";
		display: block;
		height: 6rem;
		background: var(--ui-bg-light);
		z-index: 1;
		margin-bottom: -2rem;
	}

	&-itm {
		position: sticky;
		top: 6rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
		padding: 2.5rem 2rem;
		background: var(--ui-bg-2);

		&:before {
			content: "";
			position: absolute;
			top: 100%;
			height: 1rem;
			left: 0;
			width: 100%;
			background: var(--ui-bg-light);
		}

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		&__name {
			display: flex;
			align-items: center;

			svg, img {
				width: 3.5rem;
				height: 3.5rem;
				flex-shrink: 0;
				margin-right: 1.5rem;
				stroke: var(--ui-accent-1);
			}
		}

		&:last-child {
			position: relative;
			top: 0;
			z-index: 2;

			&:after {
				content: "";
				position: absolute;
				top: 100%;
				height: 6.1rem;
				width: 100%;
				left: 0;
				background: var(--ui-bg-light);
			}
		}

	}
}

.ventfasad-producer {

	&-content {
		padding-top: 3rem;
		padding-bottom: 10rem;
	}

	&__bg {
		z-index: -1;
		@include fill();
		overflow: hidden;

		&:after {
			content: "";
			@include fill();
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
		}
	}

	.content-grid-accordion {
		&-list {
			margin-top: 5rem;
		}

		&__num {
			&:before {
				color: #fff;
			}
		}

		.plus {
			&:before, &:after {
				background: #fff;
			}
		}
	}

	.content-grid-accordion.active .content-grid-accordion__head {
		color: inherit;

		@include hover {
			color: var(--ui-accent-1);
		}
	}
}

.garant-num {
	font-size: 40.60281rem;
	font-weight: 500;
	color: var(--ui-accent-1);
	font-family: $F_title;
	line-height: 1;
	margin-bottom: -26%;

	svg {
		width: 50rem;
		height: 30rem;
		margin-left: 4rem;
		margin-bottom: -1rem;
	}
}

.garant-subtitle {
	font-size: 6.875rem;
	font-weight: 500;
	line-height: 90%;
	font-family: $F_title;
}

.garant-title {
	font-size: 6.875rem;
	font-weight: 500;
	line-height: 90%;
	font-family: $F_title;
	color: var(--ui-accent-1);
	margin-bottom: 3.5rem;
}

.garant-text {
	line-height: 130%;
	font-family: $F_main;

	p {
		&:not(:last-child) {
			margin-bottom: 2.5rem;
		}
	}
}

@include for-tablet {

	.ventfasad-hero {

		.window {
			padding-top: 5.75rem;
		}

		.window-padding {
			padding-top: 0;
		}

		.window-bot-text {
			width: 90%;
		}

		&__shadow-image {
			background: rgba(40, 41, 54, 0.9);

			img, svg {
				display: none;

				circle, .dot-text {
					display: none;
				}
			}
		}

		&__dots {
			@include fill();
			display: block;
			z-index: 2;
		}

		&__window-2 {
			.window-title {
				width: 90%;
			}
		}
	}

	.ventfasad-hero__dots {
		position: fixed;

	}

	.ventfasad-dot {
		pointer-events: none;


		&.show {
			pointer-events: auto;
		}

		&__modal {
			position: relative;
			//aspect-ratio: 25/18;

			&__fade {
				@include fill();
				background: #000;
				opacity: 0.6;
			}

			&__content {
				position: relative;
				//background: #fff;
				width: 90%;
				height: 8rem;
			}

			.modal-content {
				background: none;
			}

			&__img {
				display: none;
			}

			&__name {
				//position: absolute;
				position: relative;
				bottom: 0;
				left: 0;
				font-size: var(--fs-h4);
				font-weight: 500;
				font-family: $F_title;
				color: #fff;
				padding: 3rem 1rem;
			}
		}

		&:nth-child(2) {
			.ventfasad-dot__round {
				left: 86%;
				top: 38%;
			}
		}

		&:nth-child(3) {
			.ventfasad-dot__round {
				left: 76%;
				top: 59%;
				display: none;
			}
		}

		&__round {
			width: 2.5rem;
			height: 2.5rem;
			background: var(--ui-bg-2);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 11%;
			top: 36%;

			.plus {
				width: 1rem;
				height: 1rem;

				&:before, &:after {
					background: var(--ui-accent-1);
				}
			}
		}

	}

	.garant-title {
		font-size: var(--fs-h1);
		margin-bottom: 1rem;
	}

	.garant-num {
		font-size: 100%;
		margin-bottom: 0;

		svg {
			width: 28rem;
			height: 15rem;
			margin-left: 0;
		}
	}


	.ventfasad-example__head {
		display: block;

		.aside-title {
			margin-bottom: 1.5rem;
		}
	}

	.ventfasad-advantages {
		margin-bottom: -6rem;
	}

	.ventfasad-advantages-itm {
		padding: 1rem;
		display: block;
		top: 4rem;

		&__name {
			justify-content: space-between;
			margin-bottom: 1rem;

			svg, img {
				order: 2;
				width: 3rem;
				height: 3rem;
				margin-right: 0;
				margin-left: 1.5rem;
			}

			.h3 {
				font-size: var(--fs-h4);
			}
		}

		&__desc {
			font-size: 1rem;
		}
	}

	.ventfasad-producer {
		position: relative;
		z-index: 1;

		.js-parallax {
			height: 120%;
		}

		&-content {
			padding-bottom: 0;
			min-height: 50lvh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.content-grid-accordion-list {
				margin-bottom: 0;
			}

			&__fill {
				flex: 1;
			}
		}

		.content-grid-accordion__head:after {
			background: #fff;
		}
	}

	.garant-subtitle {
		font-size: var(--fs-h1);
		margin-top: 2rem;
		margin-bottom: 2rem;

		br {
			display: none;
		}
	}

	.garant-text {
		font-size: var(--fs-p1);
		font-family: $F_main;

		p {
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}

	.garant-subtext {
		font-size: 1.75rem;
		font-weight: 400;
		line-height: 120%;
		font-family: $F_main;
		width: 70%;
	}

	.buro-nadzor-section {
		position: relative;
		margin-bottom: 5.5rem;

		.aside-title {
			width: 90%;
			margin-bottom: 1rem;
		}

		.arrow-link {
			position: absolute;
			top: 100%;
			margin-top: 2rem;
			font-size: var(--fs-h4);
		}

		.content-grid-aside {
			transform: none !important;
		}
	}

	.t-aside-title-big {
		width: 100%;
	}

	.section-marginw {
		margin-bottom: 5rem;
	}

	.ventfasad-example .buro-example-grid-itm__btn__arrow {
		fill: var(--ui-accent-2);
	}

}

@include for-phone {
	.ventfasad-hero {
		.window {
			padding-top: 4.25rem;
		}
	}

	.ventfasad-example__head .aside-title {
		margin-bottom: 1rem;
	}

	.ventfasad-example {
		.buro-example-grid {
			margin-top: 1rem;
		}
	}

	.garant-num {
		svg {
			width: 18rem;
			margin-bottom: -2rem;
		}
	}

	.garant-subtitle {
		font-size: 2.5rem;
		margin-top: 1.5rem;
		margin-bottom: 1rem;
	}

	.ventfasad-advantages {
		margin-top: 1.5rem;
		margin-bottom: -3rem;

		&:after {
			margin-top: -3rem;
			margin-bottom: -2rem;
		}

		&-itm {
			&__name {
				margin-bottom: .75rem;
				justify-content: flex-start;

				.h3 {
					font-size: var(--fs-h3);
				}

				svg, img {
					order: 0;
					margin-left: 0;
					margin-right: .75rem;
					width: 2.5rem;
					height: 2.5rem;
				}
			}

			&__desc {
				font-size: var(--fs-p3);
				line-height: 130%;
			}

			&:last-child {
				margin-bottom: 3rem;
			}
		}
	}

	.ventfasad-producer-content {
		padding-top: 1.5rem;

		.aside-title-big {
			width: 100%;
		}
	}

	.garant-subtext {
		width: 100%;
		font-size: 1.5rem;
	}

	.garant-title {
		font-size: 2rem;
	}

	.garant-text {
		font-size: 1.125rem;
		line-height: 120%;
	}

	.buro-nadzor-section .aside-title {
		margin-bottom: .75rem;
	}

	.ventfasad-dot__modal {

	}

	.ventfasad-hero .ventfasad-hero__window-2 {
		.column-sb {
			min-height: 130lvh;
		}
	}

	.section-marginw {
		margin-bottom: 3.75rem;
	}

}