.window {
	position: relative;
	height: 100vh;
	box-sizing: border-box;
	background-size: cover;
	background-position: center;

	.fp-tableCell {
		padding-top: 6.75rem;
		padding-bottom: 2.5rem;
		min-height: 500px;
	}

	&--sb {
		.fp-tableCell {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}
	}

	&-title {
		width: 50%;
		overflow: hidden;
		line-height: 100%;

		&.h1-big {
			width: 60%;
		}
	}

	&-footer {
		background: var(--ui-bg-3, #D8DFEB);

		.footer {
			padding-bottom: 0;
		}
	}

	&__bg {
		display: none;
	}

	&-padding {
		padding-top: 7.5rem;
		padding-bottom: 2.5rem;
	}

	&-bot-text {
		width: 35%;
	}

	.front-slider-itm__bg {
		height: 100%;
	}
}

.fp-section.fp-table {
	position: absolute;
	pointer-events: none;
	opacity: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;

	&.active {
		z-index: 1;
		pointer-events: auto;
	}
}


.front-video, .front-video-reverse {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	object-fit: cover;
	object-position: center;
	opacity: 0;
	pointer-events: none;
	//transition: all 1s ease-in-out .15s;

	&.active {
		opacity: 1;
		//transition: all .15s ease-in-out;
	}
}

.front-page-pagination {
	@extend .h3;
	color: #fff;
	font-weight: 500;
	z-index: 1;
	pointer-events: none;

	&-wrap {
		@extend .content;
		position: fixed;
		top: 7.5rem;
		left: 50%;
		transform: translateX(-50%);
		text-align: right;
		z-index: 1;
	}

	&__cur {
		@extend .h1-small;
	}
}

.window-bot {
	width: 42%;

	&__subtitle {
		@extend .s1;
		color: #fff;
		margin-bottom: 1.5rem;
		letter-spacing: 0.52px;
	}
}

.owl-dots {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.front-page {

}

.front-footer {
	flex: 1;
	padding-bottom: 2.5rem;

	.footer-main {
		height: 100%;
	}


	&__mid {
		grid-column-end: 4;
		grid-column-start: 2;
		align-self: flex-end;
	}

	&-tel {
		font-size: 5.08431rem;
		font-weight: 500;
		line-height: 100%;
		white-space: nowrap;
		margin-bottom: 1.5rem;
		display: inline-block;
		font-family: $F_title;
	}

	.arrow-link {
		color: var(--ui-accent-1);

		svg {
			fill: var(--ui-accent-1);
		}

		@include hover {
			color: var(--ui-accent-2);
			svg {
				fill: var(--ui-accent-2);
			}
		}
	}
}

.front-slider {
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: #fff;

	&-title {
		position: relative;
		z-index: 1;

		.window-title {
			width: 63%;
		}
	}

	.owl-stage {
		transform: none !important;
	}

	.owl-item {
		@include fill();
		pointer-events: none;

		&.active {
			pointer-events: auto;

			.front-slider-itm__bg {
				opacity: 1;
			}
		}
	}

	&-itm {
		position: relative;
		padding-top: 20rem;
		padding-bottom: 5.5rem;
		height: 100%;

		&__bg-wrap {
			@include fill();
		}

		.content {
			position: relative;
			z-index: 1;
			height: 100%;
			overflow: hidden;
		}

		&__bg {
			height: 110%;
		}

		&__subtitle {
			width: 50%;
			opacity: 0;
		}
	}

	.owl-dots {
		gap: 2.5rem;
		bottom: 1.75rem;
		max-width: 1920px;
		width: 100%;
		margin: 0 auto;
		box-sizing: border-box;
		padding: 0 2.5rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.owl-dot {
		position: relative;
		flex: 1;
		font-size: 2rem;
		color: #fff;
		padding-bottom: 1rem;
		text-align: left;
		transition: color .2s ease-in-out;
		font-family: $F_title;
		overflow: hidden;

		&__name {
			display: block;
		}

		.progress-bar {
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&:not(.active) {
			@include hover {
				color: var(--ui-accent-1);
			}
		}

		&.active {
			cursor: auto;
		}
	}
}

.animate-wrap {
	overflow: hidden;
}

#front-window3 {
	.window-title {
		width: 64%;
	}
}

@include for-tablet {
	body {
		--window-inner-height: 100vh;
	}

	#front-window1 {
		padding-top: 5.5rem;
	}

	.window {
		overflow: hidden;
		opacity: 1 !important;
		padding-top: 2rem;
		padding-bottom: 1rem;
		height: 100vh;
		height: var(--window-inner-height);

		&-bot {
			width: 85%;

			&__subtitle {
				font-size: var(--fs-p1);
				margin-bottom: 1rem;
			}
		}

		&-bot-text {
			width: 100%;
		}

		/*.fp-tableCell {
			padding-top: 5.5rem;
			padding-bottom: 1rem;
		}*/

		&__bg {
			display: block;
			height: 120%;
			will-change: transform;
			backface-visibility: hidden;
		}

		&-footer {
			min-height: auto;
			height: auto !important;

			.fp-tableCell {
				min-height: auto;
				height: auto !important;
			}
		}

		.js-animate-line, .window-bot__subtitle, .front-footer-tel, .footer {
			opacity: 1 !important;
			transform: none !important;
		}

		.owl-dot__name, .progress-bar {
			opacity: 1 !important;
		}

		.front-slider-itm__bg {
			height: 110%;
		}
	}

	.front-slider-title {
		pointer-events: none;

		.window-title {
			width: 100%;
		}
	}

	.front-page-pagination {
		display: none;
	}

	.fp-section.fp-table {
		position: relative;
		opacity: 1 !important;
		height: auto !important;
		pointer-events: auto;
	}


	.window-title {
		color: #fff;
		width: 100%;

		&.h1-big {
			width: 100%;
		}

		br {
			display: none;
		}
	}

	.animate-line {
		display: inline;

		.js-animate-line {
			display: inline;
		}
	}

	.animate-title {
		br {
			display: none;
		}
	}

	.front-video {
		display: none;
	}

	.front-footer-tel {
		font-size: 3.375rem;
		margin-bottom: 1rem;
	}

	.front-footer {
		.front-footer__mid {
			width: 100%;
		}
	}

	.front-slider {
		.owl-dots {
			padding-left: 1rem;
			padding-right: 1rem;
			gap: 1.5rem;
			white-space: nowrap;
			bottom: 1rem;
		}

		.owl-dot {
			font-size: var(--fs-h5);
			flex: auto;
			padding-bottom: 0.5rem;
		}

		&-itm {
			padding-top: 24rem;

			&:before {
				content: "";
				@include fill();
				background: rgba(0, 0, 0, 0.30);
				opacity: .3;
			}

			&__subtitle {
				width: 60%;
				font-size: var(--fs-p1);
				color: #fff;
			}

			&__bg-wrap {
				opacity: 0;
				height: 110%;
			}
		}
	}
}

@include for-phone {
	#front-window1 {
		padding-top: 4.5rem;
		padding-bottom: 5rem;

		/*
				.fp-tableCell {
					padding-top: 4.5rem;
				}*/
	}

	#front-window2 {
		.window-bot__subtitle {
			br {
				display: none;
			}
		}
	}

	.window {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	/*.window .fp-tableCell {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}*/
	.window-bot {
		width: 100%;
	}

	.front-slider-itm {
		padding-top: 10.75rem;

		&__subtitle {
			width: 100%;
		}
	}

	.front-slider {
		.owl-dot {
			display: inline-block;

			&:not(:last-child) {
				margin-right: 0.75rem;
			}

			&__name {
				font-size: 0.875rem;
			}
		}

		.owl-dots {
			display: block;
			white-space: nowrap;
			gap: 0.75rem;
			overflow: auto;
			width: 100%;
			padding-bottom: 1rem;
			bottom: 0;
		}
	}

	.window-footer {
		height: auto !important;

		.fp-tableCell {
			height: auto !important;
		}
	}

	.front-footer {
		padding-bottom: 0;

		.footer-main {
			display: block;

			.footer-left {
				width: 60%;
				margin-bottom: 3.5rem;
			}
		}

		&-tel {
			font-size: 1.6875rem;
			margin-bottom: 0.5rem;
		}

		&__mid {
			width: 100%;
		}
	}

}