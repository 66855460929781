$F_main: 'PPRadioGrotesk';
$F_title: 'NeueMachina';

$width_main: 1280;

:root {
	--fs-h1-big: 5rem;
	--fs-h1: 3rem;
	--fs-h1-small: 2.5rem;
	--fs-h2: 2rem;
	--fs-h3: 1.5rem;
	--fs-h4: 1.25rem;
	--fs-h5: 1rem;
	--fs-h6: 0.875rem;

	--fs-small: 0.75rem;

	--fs-p1: 1.625rem;
	--fs-p2: 1.25rem;
	--fs-p3: 1rem;
	--fs-s1: 1.625rem;
	--fs-s2: 1.25rem;
	--fs-s3: 1rem;
	--fs-s4: 0.75rem;

	--fs-h2-visivig: 2.5rem;
	--fs-h3-visivig: 2.25rem;
	--fs-h4-visivig: 2rem;
	--fs-h5-visivig: 1.75rem;
	--fs-h6-visivig: 1.5rem;

	--font-gray-1: #596175;
	--font-gray-2: #93949A;

	--ui-primary: #313346;
	--ui-accent-1: #007363;
	--ui-accent-2: #0B9B87;
	--font-primary: #313346;

	--ui-bg-light: #F0F3FA;
	--ui-bg-2: #E2E8F0;
	--ui-bg-3: #D8DFEB;

	--ui-stroke: #C6CBD6;
	--ui-error: #EE1E1E;
	--ui-gray-1: #596175;
	--ui-gray-3: #C6CBD6;

	--ui-img-gradient: linear-gradient(180deg, rgba(49, 60, 70, 0.00) 0%, #313C46 100%);
	--ui-bg-blur: rgba(240, 243, 250, 0.80);
	--ui-no-active: rgba(89, 97, 117, 0.50);

	--ease: cubic-bezier(0.4, 0.20, 0.20, 1);

	@include for-tablet {
		--fs-h1-big: 4rem;
		--fs-h1: 3.4375rem;
		--fs-h1-small: 2.5rem;
		--fs-h2: 2rem;
		--fs-h3: 1.75rem;
		--fs-h4: 1.5rem;
		--fs-h5: 1.25rem;

		--fs-p1: 1.375rem;

		--fs-p2: 1.125rem;
		--fs-s2: 1.125rem;

		--fs-p3: 1rem;
		--fs-s3: 1rem;

		--fs-p4: 0.875rem;
		--fs-s4: 0.875rem;

		--fs-h2-visivig: 1.625rem;
		--fs-h3-visivig: 1.5rem;
		--fs-h4-visivig: 1.375rem;
		--fs-h5-visivig: 1.25rem;
		--fs-h6-visivig: 1.125rem;
	}

	@include for-phone {
		--fs-h1-big: 2rem;
		--fs-h1: 1.5rem;
		--fs-h1-small: 1.375rem;
		--fs-h2: 1.25rem;
		--fs-h3: 1.125rem;
		--fs-h4: 1rem;
		--fs-h5: 1rem;
		--fs-h6: 0.75rem;

		--fs-s1: 0.875rem;
		--fs-p1: 1.125rem;

		--fs-p2: 0.875rem;
		--fs-p3: 0.875rem;

		--fs-s2: 0.75rem;
		--fs-s3: 0.75rem;
		--fs-s4: 0.625rem;


		--fs-h2-visivig: 1.25rem;
		--fs-h3-visivig: 1.125rem;
		--fs-h4-visivig: 1rem;
		--fs-h5-visivig: 0.875rem;
		--fs-h6-visivig: 0.875rem;
	}
}

$fs_h1: var(--fs-h1);
$fs_h2: var(--fs-h2);
$fs_h3: var(--fs-h3);
$fs_h4: var(--fs-h4);
$fs_h5: var(--fs-h5);

$fs_p1: var(--fs-p1);
$fs_p2: var(--fs-p2);
$fs_p3: var(--fs-p3);
$fs_s1: var(--fs-s1);
$fs_s2: var(--fs-s2);
$fs_s3: var(--fs-s3);