@mixin for-desktop-big {
	@media (min-width: 1921px) {
		@content;
	}
}

@mixin for-tablet {
	@media (max-width: 850px) {
		@content;
	}
}

@mixin for-phone {
	@media (max-width: 599px) {
		@content;
	}
}

@mixin for-retina {
	@media (-webkit-min-device-pixel-ratio: 2) and (min-width: 850px) and (min-width: 4000px){
		@content;
	}
}