.modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	transition: all .3s ease-in-out;
	pointer-events: none;

	&.active {
		pointer-events: auto;
	}

	&-close {
		position: sticky;
		top: 1.5rem;
		float: right;
		cursor: pointer;
		z-index: 1;
		align-self: flex-end;

		&:before {
			content: "";
			@include fill(-5px);
		}

		@include hover {
			.cross {
				&:before, &:after {
					background: var(--ui-accent-1);
				}
			}
		}

		&--absolute {
			top: 1rem;
			transform: translateX(1rem);
		}
	}

	&-fade {
		@include fill();
		background: var(--bg-blur-dark, rgba(49, 60, 70, 0.50));
		backdrop-filter: blur(16px);
		cursor: pointer;
		opacity: 0;
	}

	&-content {
		position: absolute;
		background: var(--ui-bg-light);
		padding: 1rem 2.5rem 2.5rem;
		right: 0;
		top: 0;
		height: 100%;
		width: 57.75rem;
		max-width: 100%;
		overflow: auto;
		transform: translateX(100%);
		display: flex;
		flex-direction: column;

		&__top {
			padding-bottom: 2rem;
		}

		&-flex {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			flex: 1;
		}
	}

	&--center, &--center-small {
		.modal {
			&-content {
				left: 50%;
				top: 50%;
				height: auto;
				max-height: 90svh;
				width: 62.4rem;
			}
		}
	}

	&--center-small {
		.modal {
			&-content {
				width: 44rem;
				padding: 2.5rem;
				padding-right: 6rem;

				h3 {
					margin-bottom: 1rem;
					font-size: var(--fs-h2);
				}

				p {
					font-size: var(--fs-p2);
				}
			}

			&-close {
				position: absolute;
				top: 2.5rem;
				right: 2.5rem;
				transform: none;
			}
		}
	}

	&--cols {
		.modal {
			&-content {
				padding: 0;
				width: 80.4rem;
			}

			&-inner-cols {
				height: 100%;
				overflow: auto;
			}

			&-close {
				position: absolute;
				right: 1.5rem;
				top: 1.5rem;
				margin: 0;

				@include hover {
					.cross {
						&:before, &:after {
							background: var(--ui-accent-1);
						}
					}
				}

				.cross {
					&:before, &:after {
						background: #fff;
					}
				}
			}
		}
	}

	&--ventfasad {
		.modal {
			&-content {
				left: 50%;
				top: 50%;
				height: auto;
				max-height: 90svh;
				width: 25rem;
				padding: 0;
				background: none;
			}
		}

		.modal-close {
			position: absolute;
			right: 1rem;
			top: 1rem;

			.cross {
				&:before, &:after {
					background: #fff;
				}
			}
		}
	}

	&-inner {
		display: flex;
		flex-direction: column;
		max-height: 100%;
		overflow: hidden;
		margin-top: -.45rem;

		&-text {
			padding: 0 5rem;
			height: 100%;
			overflow: auto;
			@extend .style-scroll;
			margin-top: 1rem;
		}
	}

	&-title {
		margin-bottom: 1rem;
	}

	&-desc {
		max-width: 100%;
		font-size: var(--fs-s2);
		line-height: 110%; /* 1.375rem */
		letter-spacing: 0.025rem;

		&--small {
			width: 28.0625rem;
			max-width: 100%;
			text-wrap: balance;
		}
	}

	&-quote {
		padding: 1rem;
		background: var(--ui-bg-2, #E2E8F0);
		margin-left: -1rem;
		margin-right: -1rem;
		margin-bottom: 2rem;

		&__desc {
			margin-bottom: .25rem;
		}
	}

}

.modal-form {
	width: 38.9375rem;
	max-width: 100%;
	margin-left: auto;
}

.modal-before-send {
	transition: opacity .6s ease;

	&.send-hide {
		opacity: 0;
		pointer-events: none;
	}
}

.modal-after-send {
	position: absolute;
	left: 0;
	top: 0;
	padding: 2.5rem;
	width: 100%;
	opacity: 0;
	transition: opacity .6s ease;
	pointer-events: none;

	&.show {
		opacity: 1;
		pointer-events: auto;
	}
}

.cookie-popup {
	position: fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	z-index: 10;
	padding: 1.1rem;
	background: var(--font-primary, #313346);
	color: #fff;
	display: flex;
	align-items: center;
	line-height: 1.1;

	&.hide {
		display: none;
	}

	&__button {
		font-size: 1.75rem;
		margin-left: 1.5rem;
		color: var(--ui-accent-2);
		font-family: $F_title;
		transition: color .2s ease;

		@include hover {
			color: var(--ui-accent-1);
		}
	}
}

@include for-tablet {


	.modal:not(.modal--center):not(.modal--center-small):not(.modal--ventfasad) {
		.modal-content {
			transform: none !important;
			padding-top: .25rem;
		}
	}

	.modal {
		&-content {
			padding: 1rem;
			left: 100%;

			&-flex {
				display: block;
			}

			&__top {
				padding-bottom: 2.5rem;
			}
		}

		&-title {
			font-size: 2.5rem;
			margin-bottom: 0.75rem;
		}

		&-desc {
			font-size: var(--fs-p1);
			max-width: 90%;
		}

		.cross {
			width: 1.25rem;
			height: 1.25rem;
		}

		&-inner-text {
			padding-right: 1rem;
			padding-left: 0;
		}

		&-close--absolute {
			transform: none;
		}

		&--center-small {
			.modal {
				&-content {
					padding: 1.5rem;
					width: calc(100% - 2rem);

					> .h2, > h3 {
						padding-right: 1.5rem;
						margin-bottom: .75rem;
					}


				}

				&-close {
					top: 1.5rem;
					right: 1.5rem;

					.cross {
						width: 1rem;
						height: 1rem;
					}
				}
			}
		}

		&--cols {
			.modal-close {
				.cross {
					&:before, &:after {
						background: var(--ui-primary);
					}
				}
			}

			.modal-content {
				max-height: 100%;
			}
		}
	}

	.form-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		padding: 1.5rem 1rem;
		background: var(--ui-bg-2, #E2E8F0);
		margin-top: 0;
		margin-bottom: 0;

		.check_box {
			font-size: var(--fs-small);
			line-height: 110%; /* 0.825rem */
			letter-spacing: 0.015rem;
		}

		.arrow-link {
			font-size: 1.5rem;
		}

		&:before {
			display: none;
		}
	}

	.modal-form {
		padding-bottom: 4rem;
	}

	.cookie-popup {
		padding: 1rem;

		&__text {
			font-size: var(--fs-small);
		}

		&__button {
			font-size: var(--fs-h4);
			margin-left: 1rem;
		}
	}

}

@include for-phone {
	.modal:not(.modal--center):not(.modal--center-small):not(.modal--ventfasad) .modal-content {
		padding-top: 1rem;

	}

	.modal-form {
		padding-bottom: 4.75rem;
	}

	.modal {

		&-close {
			position: absolute;
			top: 1rem;
		}

		&-title {
			font-size: var(--fs-h1-small);
			margin-bottom: .63rem;
			padding-right: 2rem;
		}

		&-desc {
			font-size: 1rem;
		}

		&-content {

			&__top {
				padding-bottom: 1rem;
			}
		}

		&--center-small {
			.modal {
				&-content {
					padding: 1rem;

					> .h2, > h3 {
						margin-bottom: 1rem;
					}
				}

				&-close {
					top: 1rem;
					right: 1rem;
				}
			}
		}
	}

	.form-footer {
		position: inherit;
		background: none;
		padding: 0;
		transform: none !important;
		margin-top: 1rem;

		.arrow-link {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 1rem;
			background: var(--ui-bg-2, #E2E8F0);
			justify-content: space-between;
			z-index: 1;
		}
	}

	.modal--cols .modal-close {
		top: 1.125rem;
		right: 1.125rem;
	}

	.modal-inner {
		margin-top: 0;

		> .h1-small {
			padding-right: 2rem;
		}
	}


	.cookie-popup {
		padding: .5rem;
		right: 0;
		width: 100%;
		bottom: 0;

		&__text {
			font-size: var(--fs-s4);
		}

		&__button {
			font-size: var(--fs-h3);
			margin-left: 1.3rem;
		}
	}
}