.production-section {
	position: relative;
	padding-bottom: 10rem;
	padding-top: 5rem;
	overflow: hidden;

	&--first {
		padding-top: 7.75rem;
	}

	&__bg {

		&__img {
			@include fill();
			height: 110%;
			will-change: transform;
			backface-visibility: hidden;
		}
	}

	.content-grid-accordion-list {
		margin-top: 5rem;

		.content-grid-accordion__num:before {
			color: #fff;
		}

		.plus {
			&:before, &:after {
				background: #fff;
			}
		}
	}

	.content-grid-accordion__head {
		font-size: var(--fs-h1-small);
	}
}

.production-num-block {
	margin-top: 5rem;

	&-itm {
		&:not(:last-child) {
			margin-bottom: 2.5rem;
			padding-bottom: 2.5rem;
			border-bottom: 1px solid #C6CBD6;
		}

		&__val {
			font-size: 8.5rem;
			font-family: $F_title;
			font-weight: 500;
			line-height: 90%;
		}

		&__title {
			@extend .h1;
			margin-bottom: 1rem;
		}

		&__text {
			@extend .s1;
		}
	}
}

.product-idea-grid-col {
	grid-column: 9/13;
}

.product-idea-itm {
	text-wrap: balance;

	&__title {
		margin-bottom: 1rem;
	}

	&:not(:last-child) {
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;
		border-bottom: 1px solid #CEDEE9;
	}
}

.quality-control-grid-list {
	counter-reset: control-num;
	margin-top: 10rem;
}

.quality-control-grid-itm {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 2.5rem;

	&:not(:last-child) {
		margin-bottom: 2.5rem;
		padding-bottom: 2.5rem;
		border-bottom: 1px solid #C6CBD6;
	}

	&__num {
		grid-column: 1/4;

		&:before {
			counter-increment: control-num;
			content: counter(control-num, decimal-leading-zero);
			@extend .h1-small;
		}
	}

	&__name {
		grid-column: 4/8;
	}

	&__desc {
		grid-column: 8/13;
	}
}

@include for-tablet {

	.production-section {
		padding-top: 2rem;
		padding-bottom: 4rem;

		&--first {
			padding-top: 5.7rem;
			padding-bottom: 6rem;
		}

		.aside-title {
			margin-bottom: 3rem;
		}

		.content-grid-accordion__head {
			&:after {
				background: #fff;
			}
		}

	}

	.production-num-block-itm {
		&:not(:last-child) {
			margin-bottom: 2rem;
			padding-bottom: 2rem;
		}

		&__val {
			font-size: 6.25rem;
		}

		&__title {
			font-size: var(--fs-h4);
			font-weight: 500;
			margin-bottom: .25rem;
		}

		&__text {
			font-size: var(--fs-s2);
		}
	}

	.product-idea-grid-col {
		margin-top: 6rem;
	}

	.product-idea-itm {

		&:not(:last-child) {
			padding-bottom: 2rem;
			margin-bottom: 2rem;
		}

		&__title {
			font-size: var(--fs-h2);
			margin-bottom: 1rem;
		}
	}

	.content-grid-accordion-list--modals {
		margin-bottom: 0;

		.content-grid-accordion {
			padding-top: 0;
		}
	}

	.production-section {
		.content-grid-accordion__head {
			padding-right: 0;
			align-items: center;

			&__name {
				font-size: var(--fs-h2);
				line-height: 100%;
				padding-right: 1rem;

				&:before {
					display: none;
				}
			}
		}

		.quality-control-grid-itm {
			grid-template-columns: 1fr;
			gap: 1rem;

			&:not(:last-child) {
				padding-bottom: 2rem;
				margin-bottom: 2rem;
			}

			&__num {
				display: none;
			}

			&__name {
				grid-column: 1;
				font-size: var(--fs-h2);
			}

			&__desc {
				grid-column: 1;
			}
		}
	}


}

@include for-phone {
	.production-section {

		&--first {
			padding-top: 4.25rem;
			padding-bottom: 4rem;
		}

		.aside-title {
			margin-bottom: .75rem;
		}
	}

	.production-num-block-itm {

		&:not(:last-child) {
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
		}

		&__val {
			font-size: 4rem;
		}

		&__title {
			font-size: var(--fs-h2);
		}
	}

	.product-idea-itm {

		&:not(:last-child) {
			margin-bottom: 1.5rem;
			padding-bottom: 1.5rem;
		}

		&__title {
			margin-bottom: .75rem;
			line-height: 100%;
		}

		.p2 {
			font-size: 1rem;
		}
	}

	.content-grid-accordion__head__arrow {
		.plus {
			width: 1rem;
			height: 1rem;
		}
	}

	.production-section .quality-control-grid-itm {
		gap: .75rem;
	}

}