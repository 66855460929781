.objects-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.5rem;

	.object-podsystem-hover {
		&-itm {
			font-size: var(--fs-h6);

			&:not(:last-child) {
				padding-bottom: 1rem;
				margin-bottom: 1rem;
				border-color: var(--font-gray-2);
			}
		}
	}

	.object-podsystem-slider-itm__img {
		margin-bottom: 1rem;
	}

	.object-podsystem-slider-itm__name {
		font-size: var(--fs-h4);
	}
}

.objects-filter-aside {
	.catalog-filter-itm {
		margin-bottom: 1.7rem;
	}
}

@include for-tablet {
	.objects-grid {
		gap: 1.5rem 1rem;

		.object-podsystem-slider-itm__name {
			font-size: 1rem;
		}
	}

	.objects-filter-aside {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 1rem;

		> form {
			grid-column: span 2;
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: .5rem;
		}
	}
}

@include for-phone {
	.objects-grid {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 1rem .5rem;
	}


	.objects-filter-aside {
		gap: .5rem;
	}

	.objects-grid .object-podsystem-slider-itm {
		&__name {
			font-size: var(--fs-h6);
		}

		&__img {
			margin-bottom: .5rem;
		}
	}
}