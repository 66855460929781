html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, input, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	background: transparent;
	font-size: 100%;
	vertical-align: baseline;
}

html, body {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--font-primary);
}

*{
	box-sizing: border-box;
}

body, input, textarea {
	font-family: $F_main;
}

button{
	background: none;
	border: none;
	outline: none;
	-webkit-appearance: none;
	cursor: pointer;
	font-family: inherit;
	color: inherit;
	padding: 0;
	font-size: inherit;
}

a, label, button{
	-webkit-tap-highlight-color: transparent;
}


a {
	color: inherit;
	text-decoration: none;
	transition: color .2s ease-in-out;

	&:hover {
		text-decoration: none;
	}

	@include hover{
		color: var(--ui-accent-1);
	}
}

img {
	border: 0;
	max-width: 100%;
	object-position: center;
	object-fit: cover;
}