.content {
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
	padding: 0 2.5rem;
}

h1, .h1 {
	font-size: $fs_h1;
	font-family: $F_title;
	font-weight: 400;
}

.h1-big {
	font-size: var(--fs-h1-big);
	font-family: $F_title;
	line-height: 90%;
}

.h1-small {
	font-size: var(--fs-h1-small);
	font-family: $F_title;
	font-weight: 500;
	line-height: 100%;
}

h2, .h2 {
	font-size: $fs_h2;
	font-family: $F_title;
	font-weight: 500;
	line-height: 100%;
}

h3, .h3 {
	font-size: $fs_h3;
	font-family: $F_title;
	font-weight: 500;
	line-height: 100%;
}

h4, .h4 {
	font-size: $fs_h4;
	font-family: $F_title;
	font-weight: 500;
	line-height: 100%;
}

.h5 {
	font-family: $F_title;
	font-weight: 500;
	line-height: 110%;
}

.p2 {
	font-size: var(--fs-p2);
	font-weight: 400;
	line-height: 140%;
	letter-spacing: 0.025rem;
}

.p3 {
	font-size: var(--fs-p3);
}

.s1 {
	font-size: var(--fs-s1);
}

.s2 {
	font-size: var(--fs-s2);
	line-height: 110%;
	letter-spacing: 0.025rem;
}

.p1 {
	font-size: var(--fs-p1);
	font-weight: 400;
	line-height: 130%;
	letter-spacing: 0.0325rem;
}

.s4 {
	font-size: var(--fs-s4);
}

.bg-gray {
	background: var(--ui-bg-2);
}

.text-format {
	line-height: 1.5;

	h2, h3, h4 {
		line-height: 1;

		&:not(:first-child) {
			margin-top: 3rem;
		}
	}

	h2 {
		margin-bottom: 1.5rem;
	}

	h3, h4 {
		margin-bottom: 1rem;
	}

	p {
		margin-bottom: 1rem;
	}

	.text-collapse {
		@extend .p2;
	}

	img {
		display: block;
		margin: 2rem 0;
	}

	figure {
		margin: 0;
	}

	.img-desc, figcaption {
		line-height: 110%;
		letter-spacing: 0.02rem;
		margin-top: -1rem;
		margin-bottom: 2rem;
		font-size: var(--fs-s3);
	}


	table {
		border-spacing: 0;

		th {
			font-size: var(--fs-small);
			font-weight: 400;
			color: var(--font-gray-1, #596175);
			line-height: 110%;
			letter-spacing: 0.015rem;
			text-align: left;
			border-bottom: 1px solid var(--ui-gray-3, #C6CBD6);
			padding: 0 1.2rem .75rem;
			vertical-align: bottom;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		tr:last-child {
			td {
				border-bottom: none;
			}
		}

		td {
			padding: 1rem 1.2rem;
			min-width: 9rem;
			line-height: 1.1;
			border-bottom: 1px solid var(--ui-gray-3, #C6CBD6);

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}

	ul {
		list-style: none;
		@extend .p2;

		li {
			position: relative;
			padding-left: 1rem;
			margin-bottom: 1rem;

			&:before {
				content: "";
				position: absolute;
				left: 0.1rem;
				top: .7rem;
				width: .35rem;
				height: .35rem;
				background: var(--ui-accent-1);
				transform: rotate(45deg);
			}
		}
	}

	ol {
		list-style: none;
		counter-reset: ol;
		@extend .p2;

		li {
			position: relative;
			padding-left: 2rem;
			margin-bottom: 1rem;

			&:before {
				counter-increment: ol;
				content: counter(ol) ".";
				position: absolute;
				left: 0.1rem;
				top: 0.1rem;
				color: var(--ui-accent-1);
				@extend .h4;
			}
		}
	}

	a {
		color: var(--ui-accent-1);

		@include hover {
			color: var(--ui-accent-2);
		}
	}
}

.table-scroll {
	/*overflow: auto;
	@extend .style-scroll;*/
}

.podsystem-tabs-content-inner {
	width: 77%;
}

.margin-small {
	margin-bottom: 1rem;
}

.title-margin {
	margin-bottom: 2rem;
}

.title-margin-big {
	margin-bottom: 2.5rem;
}

.section-margin {
	margin-bottom: 5rem;
}

.section-big-margin {
	margin-bottom: 10rem;
	margin-top: 10rem;
}

.normal-padding {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.flex {
	display: flex;
}

.balance {
	text-wrap: balance;
}

.o-hidden {
	overflow: hidden;
}

.c-main {
	color: var(--ui-accent-1);
}

.c-white {
	color: #fff;
}

.c-gray {
	color: var(--font-gray-1);
}

.jc-sb {
	justify-content: space-between;
}

.va-m {
	vertical-align: middle;
	align-items: center;
}

.va-b {
	vertical-align: bottom;
	align-items: flex-end;
	align-self: flex-end;
}

.ta-r {
	text-align: right;
}

.ta-c {
	text-align: center;
}

.w100 {
	width: 100%;
}

.btn {
	padding: 5px 8px;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;

	color: white;
	border: 2px solid white;

	&:hover {
		background: white;
		color: black;
	}
}

.cross {
	position: relative;
	width: 1.5rem;
	height: 1.5rem;

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		width: 100%;
		height: 2px;
		background: var(--ui-primary);
		transform: rotate(45deg);
		transition: background .2s ease-in-out;
	}

	&:after {
		transform: rotate(-45deg)
	}
}

.plus {
	display: inline-block;
	position: relative;
	width: 1.5rem;
	height: 1.5rem;
	will-change: transform;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		width: 100%;
		top: 50%;
		height: 2px;
		margin-top: -1px;
		background: var(--ui-primary);
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		height: 100%;
		left: 50%;
		width: 2px;
		margin-left: -1px;
		background: var(--ui-primary);
	}
}


.mb-2 {
	margin-bottom: 2rem;
}

.gamburger {
	display: inline-block;
	position: relative;
	width: 1.6875rem;
	height: 1.817rem;
	border-bottom: 2px solid black;
	transition: all 0.3s ease-in-out;
	will-change: transform;

	&:after, &:before {
		content: '';
		left: 0;
		width: 100%;
		height: 2px;
		position: absolute;
		top: 66.666%;
		background-color: black;
		transition: all 0.3s ease-in-out;
		will-change: transform;
	}

	&:before {
		top: 30%;
	}

	&.active {
		border-bottom: 3px solid transparent;

		&:after {
			transform: rotate(45deg);
			top: 63%;
		}

		&:before {
			transform: rotate(-45deg);
			top: 63%;
		}
	}
}

.style-scroll {
	scrollbar-color: var(--ui-accent-1) var(--ui-gray-1);
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
		border-radius: 8px;
		margin: 30px;

	}

	&::-webkit-scrollbar-thumb {
		background: var(--ui-accent-1);
		border-radius: 8px;
		margin: 30px;
		transition: background-color .2s ease-in-out;

		&:hover {
			background: var(--ui-accent-2);
		}
	}

	&::-webkit-scrollbar-track {
		background: var(--ui-gray-3);
		border-radius: 8px;
	}
}


@include for-tablet {
	.content {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.hide-tablet {
		display: none;
	}

	.h1-big {
		line-height: 1;
	}

	.t-h1 {
		font-size: $fs_h1;
	}

	.t-h1-big {
		font-size: var(--fs-h1-big);
	}

	.t-h1-small {
		font-size: var(--fs-h1-small);
	}

	.t-h2 {
		font-size: $fs_h2;
	}

	.t-h3 {
		font-size: $fs_h3;
	}

	.t-h4 {
		font-size: $fs_h4;
	}

	.t-h5 {
		font-size: var(--fs-h5);
	}

	.t-p1 {
		font-size: var(--fs-p1);
	}

	.t-p2 {
		font-size: var(--fs-p2);
	}

	.t-p3 {
		font-size: var(--fs-p3);
		font-weight: 400;
		line-height: 130%;
		letter-spacing: 0.02rem;
	}

	.t-s3 {
		font-size: var(--fs-s3);
	}

	.text-format {

		h2, h3, h4 {

			&:not(:first-child) {
				margin-top: 2rem;
			}
		}

		h2 {
			margin-bottom: 1rem;
		}

		img {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}

		.img-desc {
			margin-top: -1rem;
			margin-bottom: 2rem;
		}

		table {
			th, td {
				padding-left: 1rem;
				padding-right: 1rem;
			}

			td {
				font-size: var(--fs-h6);
			}
		}

		ol, ul {
			li {
				margin-bottom: .5rem;
			}
		}

		figcaption {
			margin-top: -1rem;
			margin-bottom: 2rem;
			font-size: var(--fs-p4);
		}

	}

	.title-margin {
		margin-bottom: 1.5rem;
	}

	.title-margin-big {
		margin-bottom: 1.5rem;
	}

	.section-margin {
		margin-bottom: 3rem;
	}

	.t-margin-normal {
		margin-bottom: 1rem;
	}

	.section-big-margin {
		margin-top: 5rem;
		margin-bottom: 5rem;
	}


}

@include for-phone {
	.p-h1 {
		font-size: $fs_h1;
	}

	.p-h1-big {
		font-size: var(--fs-h1-big);
	}

	.p-h1-small {
		font-size: var(--fs-h1-small);
	}

	.p-h2 {
		font-size: $fs_h2;
	}

	.p-h3 {
		font-size: $fs_h3;
	}

	.p-h4 {
		font-size: $fs_h4;
	}

	.p-h5 {
		font-size: var(--fs-h5);
	}

	.p-p2 {
		font-size: var(--fs-p2);
	}
	.p-s2 {
		font-size: var(--fs-s2);
	}

	.p-p3 {
		font-size: var(--fs-p3);
	}

	.text-format {

		h2, h3, h4 {
			margin-bottom: .75rem;
		}

		h2 {
			margin-bottom: .5rem;
		}

		ol, ul {
			li {
				margin-bottom: .5rem;

				&:before {
					top: .33rem;
				}
			}
		}

		ol li:before {
			top: 0.1rem;
		}

		table {
			th {
				font-size: 0.625rem;
			}

			th, td {
				padding-right: .8em;
				padding-left: .8rem;
			}

			td {
				padding-top: .6rem;
				padding-bottom: .6rem;
			}
		}

		img {
			margin: 2rem 0;
		}

	}

	.title-margin-big {
		margin-bottom: 1rem;
	}

	.title-margin {
		margin-bottom: 1rem;
	}

	.t-margin-normal {
		margin-bottom: .75rem;
	}

	.section-margin {
		margin-bottom: 2.5rem;
	}

	.section-big-margin {
		margin-top: 3.75rem;
		margin-bottom: 3.75rem;
	}

}