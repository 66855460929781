.company-title {
	font-size: 8.75rem;
	width: 60%;
	line-height: 100%;
}

.company-subtitle {
	line-height: 120%;
	text-wrap: balance;
}

.company-partners {
	.partner-grid {
		margin-bottom: 2px;
	}
}

@include for-tablet {
	.company-title {
		font-size: 5rem;
	}
	.company-subtitle {
		font-size: var(--fs-p1);
	}

	.company-production-section {
		position: relative;
		padding-bottom: 2rem;

		.arrow-link {
			position: absolute;
			bottom: -3rem;
		}
	}

	.company-document-block {
		.h1 {
			font-size: var(--fs-h1-small);
		}
	}
	.company-document-desc {
		.p1 {
			font-size: var(--fs-p2);
		}
	}

	.company-FER {
		position: relative;

		.aside-title-big {
			width: 100%;
		}

		.arrow-link {
			position: absolute;
			bottom: -3rem;
		}

		.company-document-desc {
			padding-right: 13rem;
		}

		.company-document-img {
			position: absolute;
			bottom: 0;
			right: 1rem;
		}

		.company-gerb-img {
			right: 0;
			bottom: 0;
			top: auto;
			width: 11rem;
		}

	}

	.company-partners {
		overflow: visible;

		.arrow-link {
			bottom: -3.5rem;
			font-size: var(--fs-h4);

			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}


}

@include for-phone {
	.company-title {
		width: 100%;
		font-size: 3rem;
		margin-bottom: 1rem;
	}


	.company-FER {
		.company-document-desc {
			padding-right: 0;
		}

		.company-document-img {
			display: none;
		}
	}

	.company-document-block {
		overflow: hidden;
		padding-bottom: 1rem;

		.h1 {
			font-size: var(--fs-h1);
		}
	}

	.company-partners {
		.arrow-link {
			bottom: -2.5rem;
		}
	}
}