.search-page-form {
	position: relative;
	width: 65%;
	margin-bottom: 2.5rem;

	input {
		padding-bottom: .75rem;
		border-bottom: 1px solid var(--ui-gray-3);
		width: 100%;
		font-size: var(--fs-h4);
		font-family: $F_title;
		border-radius: 0;
		-webkit-appearance: none;

		@include placeholder {
			font-weight: 500;
			font-family: $F_title;
			font-size: var(--fs-h4);
		}
	}

	&__btn {
		position: absolute;
		right: 0;
		bottom: .75rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--ui-accent-1);
		}
	}
}

.search-product-line {
	display: flex;
	padding: 1.25rem 0;
	border-bottom: 1px solid var(--ui-gray-3);

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
	}

	&__name {
		flex: 1;
		align-self: center;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
		flex-shrink: 0;
		margin-left: 2rem;
		fill: var(--ui-accent-1);
		align-self: center;
	}
}

.search-count-line {
	margin-bottom: 2.5rem;
}

.search-news-itm {
	display: block;
	padding: 1.5rem 0;

	&:not(:last-child) {
		border-bottom: 1px solid var(--ui-gray-3);
	}

	&__cat {
		display: block;
		letter-spacing: 0.02rem;
		color: var(--ui-accent-1);
		margin-bottom: .5rem;
	}

	&__name {
		margin-bottom: .75rem;
	}

	&__text {
		color: var(--font-primary);
		font-size: var(--fs-p2);
	}
}

.search-select {
	color: var(--ui-accent-1);
}

.search-empty-title {
	margin-bottom: 1rem;
}

.modal-search {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11;
	pointer-events: none;
	transition: opacity .3s;

	.modal-fade {
		background: rgba(49, 60, 70, .75)
	}

	.modal-close {
		position: absolute;
		right: 2.5rem;
		top: 2.5rem;
		opacity: 0;

		.cross {
			&:before, &:after {
				background: #fff;
			}
		}

		@include hover {
			.cross {
				&:before, &:after {
					background: var(--ui-accent-1);
				}
			}
		}
	}

	&.active {
		pointer-events: auto;
	}

	&-content {
		width: 41.875rem;
		max-width: 90%;
		opacity: 0;

		&:before {
			content: "";
			@include pos-center();
			width: 130%;
			height: 110%;
			min-height: 20rem;
		}

		.search-page-form {
			width: 100%;
			margin-bottom: 0;

			input {
				color: #fff;
				font-size: var(--fs-h3);

				@include placeholder {
					color: var(--ui-gray-3);
					font-weight: 500;
					font-family: $F_title;
					font-size: var(--fs-h3);
				}
			}

			&__btn {
				@include hover {
					svg {
						fill: var(--ui-accent-1);
					}
				}
			}

			svg {
				fill: var(--ui-gray-3);
				transition: all .3s ease;
			}
		}
	}
}

.search-tab-contents {

	.podsystem-slider {
		margin-right: -1.5rem;
		margin-top: 0;
	}

	.owl-nav {
		right: 1.5rem;
	}
}

.search-cat-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem;
}

@include for-tablet {
	.search-page-form {
		width: 100%;
		margin-top: 1rem;
		margin-bottom: 1rem;

		input {
			font-size: 1rem;

			@include placeholder {
				font-size: 1rem;
			}
		}

		button {
			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}

	.search-page-content {
		.page-head-nav {
			margin-bottom: 1rem;

			li {
				&:not(:last-child) {
					margin-right: 1.5rem;
				}
			}

			button {
				font-size: 1rem;
				padding-bottom: .5rem;
			}
		}
	}

	.search-count-line {
		margin-bottom: 2rem;
		font-size: var(--fs-p3);
	}

	.search-news-itm {
		padding: 1.25rem 0;

		&:first-child {
			padding-top: 0;
		}

		&__cat {
			font-size: var(--fs-p4);
		}

		&__name {
			font-size: var(--fs-h5);
			margin-bottom: .5rem;
		}

		&__text {
			line-height: 130%;
			letter-spacing: 0.02rem;
		}
	}

	.search-empty-title {
		margin-bottom: .5rem;
	}

	.modal-search {
		.modal-close {
			right: 1rem;
			top: 1rem;
		}

		.cross {
			width: 1rem;
			height: 1rem;
		}

		.search-page-form {
			&__btn {
				svg {
					width: 1rem;
					height: 1rem;
				}
			}

			input {
				font-size: var(--fs-h5);

				@include placeholder {
					font-size: var(--fs-h5);
				}
			}
		}
	}

	.search-product-line svg {
		width: 1rem;
		height: 1rem;
	}

	.search-cat-grid {
		grid-template-columns: repeat(3, 1fr);

		.cat-list-itm__name{
			font-size: var(--fs-h7);
		}
	}
}

@include for-phone {
	.search-page-form {
		margin-bottom: 1rem;
	}
	.search-page-content .page-head-nav {
		li {
			&:not(:last-child) {
				margin-right: 1rem;
			}
		}

		button {
			font-size: var(--fs-s1);
		}
	}

	.search-product-line {
		padding: .75rem 0;

		&__name {
			font-size: var(--fs-h6);
		}

		svg {
			margin-left: 1rem;
		}
	}

	.search-product-line-list, .search-news {
		margin-bottom: 1rem;
	}

	.search-news-itm {
		padding: 1rem 0;

		&:first-child {
			padding-top: 0;
		}

		&__name {
			font-size: var(--fs-s1);
		}

		&__text {
			font-size: var(--fs-small);
		}

		&__cat {
			font-size: 0.625rem;
		}
	}

	.search-cat-grid {
		grid-template-columns: repeat(2, 1fr);

		.cat-list-itm__name{
			font-size: var(--fs-h6);
		}
	}

}