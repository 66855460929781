/* header begin */
.wrapper:has(.front-page) {
	.header-blur {
		overflow: hidden;

		&:before {
			@include for-retina {
				background: none;
			}
		}
	}
}

.header {
	width: 100%;
	--header-text-color: var(--font-primary, #313346);
	--header-text-color-hover: var(--ui-accent-1);
	--header-bg-color: #F0F3FA;

	transform: translate3d(0, 0, 0);
	will-change: transform;

	&.has-bg {
		.header-blur {
			//	opacity: 0.9;
		}
	}

	&-blur {
		position: absolute;
		left: 0;
		top: 0;
		height: 5rem;
		width: 100%;
		//background: var(--header-bg-color);
		//opacity: 0;
		//transition: all .4s ease;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			backdrop-filter: blur(12px);
			padding: 20px 0;
			margin-top: -20px;
			box-sizing: content-box;
			-webkit-mask-image: linear-gradient(#000, #000);
			-webkit-mask-clip: content-box;
			pointer-events: none;
			transform: translate(0px, 0px);
			will-change: transform;
			backface-visibility: hidden;

			@include for-retina {
				backdrop-filter: none;
				background: #F0F3FA;
				opacity: 0.8;
			}
		}
	}

	&-wrap {
		position: fixed;
		top: 0;
		width: 100%;
		height: 0;
		z-index: 9;
		transform: translate3d(0, 0, 0);
		will-change: transform;
	}

	&__burger {
		cursor: pointer;
		padding: 0.5rem;
		margin-left: -.5rem;
		margin-top: -.5rem;

		@include hover {
			.gamburger {
				border-color: var(--ui-accent-1);

				&:before, &:after {
					background: var(--ui-accent-1);
				}
			}
		}
	}

	&__burger-tablet {
		display: none;
	}

	> .content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding-top: 1rem;
		padding-bottom: .6rem;
		width: auto;
	}

	&__logo {
		margin-left: 1.25rem;
		width: 5rem;
		height: 3.75rem;
		margin-top: -.5rem;
		display: block;
		will-change: transform;

		svg, .svg {
			width: 100%;
			height: 100%;
			display: block;
			will-change: transform;

			path {
				transition: all .5s ease-in-out;
			}
		}
	}

	a.header__logo {
		transition: opacity .2s ease-in-out;

		@include hover {
			opacity: 0.6;
		}
	}

	&-menu {
		display: flex;
		list-style: none;
		font-family: $F_title;

		> li {
			&:not(:last-child) {
				margin-right: 1.5rem;

			}

			&.active {
				> a, > button {
					&:before {
						content: "";
						position: absolute;
						bottom: -0.875rem;
						left: 0;
						width: 2.5rem;
						height: 2px;
						background: var(--ui-accent-1);
					}
				}
			}


			> a, > button {
				position: relative;
				color: inherit;
				text-decoration: none;
				transition: color .2s ease-in-out;

				@include hover {
					color: var(--ui-accent-1);

					&:after {
						border-color: var(--ui-accent-1);
					}
				}
			}

			.js-open-header-sub {
				position: relative;
				padding-right: 1.125rem;

				&:after {
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					width: .45rem;
					height: .45rem;
					border-bottom: 1px solid var(--header-text-color);
					border-right: 1px solid var(--header-text-color);
					transform: rotate(45deg) translateY(-50%);
					transform-origin: 60% center;
					transition: all .2s ease-in-out;
					margin-top: -.3rem;
				}

				&.active {
					&:after {
						transform: rotate(225deg);
					}
				}

			}
		}

		&-sub {
			position: absolute;
			left: 2.5rem;
			top: 100%;
			margin-top: .5rem;
			right: 2.5rem;
			background: var(--ui-bg-light, #F0F3FA);
			z-index: 1;
			height: 0;
			max-height: calc(100vh - 5.75rem - 3rem);
			//opacity: 0;
			//transition: all .3s ease-in-out;
			//transform: translateY(-10px);
			//pointer-events: none;
			//display: none;
			color: var(--font-primary);
			overflow: hidden;

			&.active {
				overflow: auto;
				@extend .style-scroll;
			}

			ul {
				background: var(--ui-bg-light, #F0F3FA);
			}
		}

		&-sublist-li {
			position: relative;

			&.show-sub {
				.header-menu-sublist-btn:after {
					transform: rotate(225deg);
				}

				.header-menu-sublist {
					pointer-events: auto;
				}
			}
		}

		&-sublist-btn {
			position: relative;
			padding-right: 1.125rem;

			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -.3rem;
				width: .45rem;
				height: .45rem;
				border-bottom: 1px solid var(--header-text-color);
				border-right: 1px solid var(--header-text-color);
				transform: rotate(45deg) translateY(-50%);
				transform-origin: 60% center;
				transition: all .2s ease-in-out;
			}
		}

		&-sublist {
			position: absolute;
			left: -1.75rem;
			top: 100%;
			margin-top: 1.25rem;
			background: var(--ui-bg-2);
			padding: 1rem 0;
			list-style: none;
			white-space: nowrap;
			height: 0;
			opacity: 0;
			overflow: hidden;
			pointer-events: none;
			color: var(--font-primary);

			li {
				overflow: hidden;
				margin: 1rem 0;
			}

			a {
				display: block;
				padding: 0 1.75rem;
			}

			.active {
				color: var(--ui-accent-1);
			}
		}

	}

	&-right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		flex: 1;
	}

	&-info {
		display: flex;
		align-items: center;
		gap: 1.5rem;
		margin-bottom: 1rem;
		font-family: $F_title;

		&-sep {
			width: 2px;
			height: 2px;
			background: var(--header-text-color);
			opacity: 0.5;
			transition: all .2s ease-in-out;
		}

		&__link {
			font-size: var(--fs-small);
			text-decoration: none;
			font-weight: 500;
			transition: color .2s ease-in-out;
			display: inline-flex;
			align-items: center;
			gap: 0.5rem;

			&__ico {
				transition: all .2s ease-in-out;
				display: none;

				&.show-desktop {
					display: block;
				}
			}

			@include hover {
				color: var(--header-text-color-hover);

				svg {
					fill: var(--header-text-color-hover);
				}
			}

			svg {
				width: 1rem;
				height: 1rem;
				fill: var(--header-text-color);
				transition: all .2s ease-in-out;

				&.fill-stroke {
					//stroke: var(--header-text-color);
				}
			}
		}
	}

	.gamburger {
		border-color: var(--header-text-color);

		&:before, &:after {
			background: var(--header-text-color);
		}

		&.active {
			border-bottom-color: transparent;
		}
	}

	&--white {
		--header-text-color: #fff;
		--header-bg-color: #282936;
		color: #fff;

		@include for-retina {
			.header-blur:before {
				background: #282936;
			}
		}

		&:not(.header--open-main-menu) {
			.header__logo {
				path {
					fill: #fff;
				}
			}
		}
	}

	.header__logo, .header-info, .header__burger, .header-menu > li:not(.open) {
		transition: opacity .3s ease;
	}

	&--open-menu {
		pointer-events: none;

		.js-open-header-sub.active, .header-menu-sub {
			pointer-events: auto;
		}

		.header__logo, .header-info, .header__burger, .header-menu > li:not(.open) {
			opacity: 0.5;
		}

		//--header-bg-color: #282936;
		//--header-text-color: #fff;
		//color: #fff;

		//.header__logo {
		//	path {
		//		fill: #fff;
		//	}
		//}

		&.has-bg .header-blur {
			opacity: 0.5;
		}
	}

	&--open-main-menu {
		--header-text-color: var(--font-primary, #313346);
		--header-text-color-hover: var(--ui-accent-1);

		svg {
			--fill: var(--font-primary);

			&.fill-stroke {
				//stroke: var(--font-primary);
			}
		}
	}

}

.menu-sub-num {
	list-style: none;
	counter-reset: menu-sub;
	overflow: hidden;
}

.menu-sub-accordion {
	position: relative;
	padding: 2.5rem 0 2.5rem 13.5rem;
	z-index: 1;

	&:not(:last-child) {
		border-bottom: 1px solid var(--ui-stroke);
	}

	&.active {

		.menu-sub-accordion__head {
			color: var(--ui-accent-1);
			cursor: auto;

			&:after {
				border-color: var(--ui-accent-1);
				transform: rotate(225deg);
			}
		}
	}

	&:not(.active) {
		.menu-sub-accordion__img {
			opacity: 0;
		}
	}


	&:before {
		position: absolute;
		top: 2.5rem;
		left: 2.5rem;
		counter-increment: menu-sub;
		content: "0" counter(menu-sub);
		font-size: var(--fs-h1-small);
		font-family: $F_title;
		font-weight: 500;
		color: var(--ui-stroke, #C6CBD6);
	}

	&__head {
		position: relative;
		font-size: var(--fs-h1-small);
		cursor: pointer;
		font-family: $F_title;

		@include hover {
			color: var(--ui-accent-1);
		}

		&:before {
			content: "";
			position: absolute;
			top: -2.5rem;
			bottom: -2.5rem;
			left: -13.5rem;
			right: 0;
			z-index: -1;
		}

		&:after {
			content: "";
			position: relative;
			display: inline-flex;
			vertical-align: middle;
			width: 0.9rem;
			height: 0.9rem;
			border-bottom: 4px solid var(--ui-primary);
			border-right: 4px solid var(--ui-primary);
			transform: rotate(45deg);
			transform-origin: 75% 50%;
			margin-left: 1.5rem;
			transition: all .3s ease-in-out;
		}
	}

	&__img {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 27.625rem;
		opacity: 0;
		pointer-events: none;
		transition: all .3s ease-in-out;
		will-change: transform;
		padding: 20px;

		&.active {
			opacity: 1;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__content {
		padding-right: 27.625rem;
		padding-top: 1rem;
		display: none;
	}
}

.menu-sub-cols {
	list-style: none;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin-bottom: -1rem;
	opacity: 1;
	will-change: opacity;
	gap: 0 1rem;

	li {
		margin-bottom: .75rem;

		&.current {
			a {
				color: var(--ui-accent-1);
				pointer-events: none;
			}
		}
	}

	a {
		text-decoration: none;
		transition: color .2s ease-in-out;

		@include hover {
			color: var(--ui-accent-1);
		}
	}

	&--three {
		grid-template-columns: repeat(3, 1fr);
	}
}

.header-mobile-menu {
	display: none;
}

.main-menu {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #282936;
	z-index: 12;
	//transform: translateY(-100%);
	height: 0;
	pointer-events: none;
	overflow: hidden;

	.front-slider-itm__bg {
		height: 100%;
	}

	&.active {
		pointer-events: auto;

		.main-menu-content-itm.active {
			pointer-events: auto;
		}
	}

	.content {
		height: 100%;
		padding: 0;
	}

	&-flex {
		display: grid;
		height: 100dvh;
		grid-template-columns: repeat(2, 1fr);
	}

	&-close {
		position: absolute;
		left: 1.5rem;
		top: .32rem;
		cursor: pointer;
		padding: 1rem;

		@include hover {
			.cross {
				&:before, &:after {
					background: var(--ui-accent-1);
				}
			}
		}

		.cross {
			&:before, &:after {
				background: #fff;
			}
		}

		.gamburger {
			border-color: #fff;

			&:before, &:after {
				background: #fff;
			}

			&.active {
				border-color: transparent;
			}
		}
	}

	&-list {
		height: 100%;
		display: grid;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		overflow: auto;
		@extend .style-scroll;
		padding: 1rem 0;

		ul {
			list-style: none;
		}

		li {
			margin: 0.875rem 0;
			overflow: hidden;

			&.active {
				color: var(--ui-accent-1);

				svg {
					fill: var(--ui-accent-1);
				}
			}
		}

		&__link {
			display: flex;
			align-items: center;
			transition: color .3s ease;

			svg {
				width: 1.5rem;
				height: 1.5rem;
				fill: #fff;
				margin-left: 1rem;
				flex-shrink: 0;
				transition: all .3s ease;
			}

			@include hover {
				color: var(--ui-accent-1);

				svg {
					fill: var(--ui-accent-1);
				}
			}
		}
	}

	&-content {
		position: relative;
		height: 100%;

		&-fade {
			@include fill();
			background: rgba(40, 41, 54, 0.90);
			opacity: 0;
		}

		&-itm {
			@include fill();
			overflow: auto;
			color: #fff;
			display: grid;
			pointer-events: none;
			opacity: 0;
			height: 100%;
			overflow: auto;
			@extend .style-scroll;
		}
	}

	&-sublist {
		list-style: none;
		padding: 4rem;
		width: 29rem;
		max-width: 100%;
		align-self: center;

		li {
			overflow: hidden;

			a {
				transform: translateY(-35px);
				transition: color .2s ease;
				display: block;
			}

			> span {
				display: inline-block;
			}

			&.active {
				color: var(--ui-accent-1);
			}

			&:not(:last-child) {
				margin-bottom: 2rem;
			}
		}
	}
}

.main-menu-catalog {
	flex: 1;
	display: flex;
	flex-direction: column;
	background: #313241;

	&-itm {
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		padding-left: 5rem;

		&:not(:last-child) {
			border-bottom: 1px solid #fff;
		}

		&__name {
			position: relative;
		}
	}
}

@include for-tablet {
	.header {
		backface-visibility: hidden;

		&-wrap {
			transform: translateY(-1px);
		}

		&-blur {
			padding-top: 0;
			margin-top: 0;
			height: 3.2rem;
		}

		> .content {
			margin: 0;
			padding-top: .5rem;
			padding-bottom: .5rem;
		}

		&-right {
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
		}

		&__burger-tablet {
			display: inline-block;

			.gamburger {
				width: 1rem;
				height: 1.09rem;
				border-width: 1px;

				&:before, &:after {
					height: 1px;
				}

				&.active {
					&:before {
						top: 62.5%;
					}
				}
			}
		}

		&__burger {
			display: none;
		}

		&__logo {
			width: 3.13rem;
			height: 2.25rem;
			margin-left: 0;
			margin-top: 0;
		}

		&-info {
			margin-bottom: 0;
			gap: 1.5rem;

			&__link {
				svg:not(.header-info__link__ico) {
					display: none;
				}

				svg {
					width: 1rem;
					height: 1rem;
				}

				&__ico {
					fill: #fff;
					--fill: #fff;
					width: 1rem;
					height: 1rem;
					display: block;
				}

				&__text {
					display: none;
				}
			}

			&-sep {
				display: none;
			}
		}

		&-menu {
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			opacity: 0;
			pointer-events: none;
			background: var(--ui-bg-light, #F0F3FA);
			color: var(--font-primary, #313346);
			display: none;
		}
	}

	.header-mobile-menu {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 8;
		background: var(--ui-bg-light, #F0F3FA);
		display: block;
		padding: 4.8rem 1rem 1rem;
		overflow: auto;
		gap: 0 1rem;
		opacity: 0;
		pointer-events: none;
		transition: opacity .3s ease;
		overscroll-behavior: contain;

		&.active {
			opacity: 1;
			pointer-events: auto;
		}

		ul {
			list-style: none;
		}

		&-list {
			column-count: 2;

			&-btn {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 0.5rem;

				&__arrow {
					display: inline-block;
					margin-top: -0.4rem;

					&:after {
						content: "";
						display: block;
						position: relative;
						width: 0.65rem;
						height: 0.65rem;
						border-bottom: 2px solid var(--font-primary, #313346);;
						border-right: 2px solid var(--font-primary, #313346);;
						transform: rotate(45deg);
						transform-origin: 75% 75%;
						transition: transform .2s ease;
						box-sizing: border-box;
					}
				}
			}

			> li {
				padding-bottom: 1.75rem;
				break-inside: avoid;

				&:nth-child(4) {
					break-after: column;
				}

				&.active {
					.header-mobile-menu-list-btn__arrow:after {
						transform: rotate(225deg);
					}
				}

				.active {
					color: var(--ui-accent-1);
				}

				> a, > button {
					font-size: var(--fs-h4);
					font-weight: 500;
					line-height: 100%;
					font-family: $F_title;
				}
			}

			ul {
				display: none;
				padding-top: 0.85rem;

				li {
					margin-bottom: 0.75rem;
				}
			}

			.header-menu-sub {
				position: relative;
			}
		}
	}
}

@include for-phone {
	.header {

		&-wrap {
			position: sticky;
		}

		&-blur {
			height: 3.25rem;
		}

		&__logo {
			margin-top: 0;
			height: 2.25rem;
		}

		&-info {
			&__link {
				&__ico {
					will-change: transform;
				}
			}
		}
	}

	.header-mobile-menu {
		display: block;
		padding-top: 4.25rem;

		&-list {
			column-count: auto;

			> li {
				padding-bottom: 2rem;

				> a, > button {
					font-size: var(--fs-h1-small);
				}
			}

			ul {
				padding-top: 1rem;

				li {
					margin-bottom: 1rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			&-btn {

				&__arrow {
					margin-top: -0.25rem;

					&:after {
						width: 0.6rem;
						height: 0.6rem;
					}
				}
			}
		}
	}
}