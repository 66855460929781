.footer {
	padding-bottom: 2.38em;
	padding-top: 3.31rem;
	overflow: hidden;


	&-main, &-bot {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 2.5rem;
	}

	&-logo {
		position: relative;
		width: 11.185rem;
		height: auto;
		top: -0.75rem;
	}

	&-menu {
		ul {
			list-style: none;

			li {
				margin-bottom: 0.75rem;
			}

			a {
				text-decoration: none;
				line-height: 110%;
				letter-spacing: 0.02rem;
				transition: color .2s ease-in-out;

				@include hover {
					color: var(--ui-accent-1);
				}
			}
		}
	}

	&-mid {

		ul {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&-info {
		p {
			line-height: 1;

			&:not(:last-child) {
				margin-bottom: 0.85rem;
			}
		}

		.h4 {
			font-family: $F_title;
		}
	}

	&-bot {
		padding-top: 1rem;
		letter-spacing: 0.015rem;
		@extend .s4;
		color: var(--font-gray-1, #596175);

		.footer-mid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

		&-mobile {
			display: none;
		}

		.footer-right {
			display: flex;
			justify-content: space-between;
			padding-right: 4rem;
		}
	}

	&-link-ico {
		display: inline-flex;
		align-items: center;
		color: var(--ui-accent-2);
		gap: .25rem;

		svg {
			width: 1rem;
			height: 1rem;
			fill: var(--ui-accent-2);
			transition: all .2s ease-in-out;
		}

		@include hover {
			color: var(--ui-accent-1);
			svg {
				fill: var(--ui-accent-1);
			}
		}
	}

	&--black {
		background: #282936;
		color: #fff;

		.footer-logo {
			path {
				fill: #fff;
			}
		}

		.footer-bot {
			color: var(--font-gray-2, #93949A);
		}
	}

	&-help {
		padding-bottom: 5rem;
		padding-top: 2.62rem;

		&-btn {
			border-bottom: 4px solid var(--ui-gray-1, #596175);
			font-size: 6.5rem;
			font-weight: 500;
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 1.5rem;
			font-family: $F_title;
			transition: all .3s;
			text-align: left;

			&--small {
				font-size: var(--fs-h1-big);
			}

			svg {
				width: 5rem;
				height: 5rem;
				fill: #fff;
				transition: all .3s;
			}

			@include hover {
				color: var(--ui-accent-2);

				svg {
					fill: var(--ui-accent-2);
				}
			}
		}
	}

	&-also {
		margin-bottom: 5rem;

		&__title {
			margin-bottom: 1.8rem;
		}

		&-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 2.5rem;

			&-itm {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 2px solid var(--ui-gray-1);
				padding-bottom: 1rem;

				svg {
					width: 1.5rem;
					height: 1.5rem;
					fill: var(--ui-accent-1);
				}
			}
		}
	}

}

@include for-tablet {
	.front-footer {
		margin-bottom: 5.5rem;

		.footer-main {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 4.5rem 0;

			.footer-left {
				width: 60%;
			}
		}


		&__mid {
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: center;
		}
	}

	.footer {

		&-logo {
			width: 6.13rem;
			top: 0;
		}

		&-info {
			p:not(:last-child) {
				margin-bottom: 0.7rem;
			}

			.s3 {
				margin-top: 1.19rem;
				width: 80%;
				line-height: 130%;
				letter-spacing: 0.02rem;
				margin-bottom: 0.81rem;
			}
		}

		&-main {
			grid-template-columns: 40% 1fr;
			gap: 1.25rem 1.5rem;

			.footer-left {
				width: 100%;
				grid-column-end: 3;
				grid-column-start: 1;
			}
		}

		&-menu {
			display: block;

			ul li {
				margin-bottom: 0.6rem;
			}
		}

		&-mid {
			ul {
				display: block;
			}
		}

		&-bot {
			display: none;

			&-mobile {
				display: grid;
				grid-template-columns: 40% 1fr;
				gap: 0.5rem 1.5rem;
				margin-top: 1.125rem;
				font-size: var(--fs-small);
				color: var(--font-gray-1, #596175);
			}
		}

		&--black {
			padding-top: 1.5rem;
			padding-bottom: 1rem;

			.footer-main .footer-left {
				display: none;
			}

			.footer-bot-mobile {
				color: var(--font-gray-2, #93949A);
			}
		}

		&-help {
			padding-bottom: 1.5rem;
			padding-top: 0;

			&-btn {
				font-size: 3rem;
				border-bottom: 2px solid var(--font-gray-1, #596175);
				padding-bottom: 1rem;
				text-align: left;
				gap: 2rem;
				line-height: 100%;

				&--small {
					font-size: 3rem;
				}

				svg {
					width: 3rem;
					height: 3rem;
					flex-shrink: 0;
				}
			}
		}

	}

	.footer-also {
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;

		&__title {
			font-size: var(--fs-h5);
			margin-bottom: 1.4rem;
		}

		&-grid {
			display: block;

			&-itm {
				border-bottom: none;
				padding-bottom: 1.3rem;
			}
		}
	}


}

@include for-phone {
	.footer {
		&-main {
			display: block;
		}

		&-menu {
			display: grid;
			font-size: var(--fs-s1);
			margin-bottom: 1.5rem;
			line-height: 1.1;

			ul {
				display: grid;
			}

			li {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&-logo {
			margin-bottom: 1.5rem;
		}

		&-info {
			border-top: 1px solid var(--ui-gray-1);
			padding-top: 1.5rem;

			.h4, .h3 {
				line-height: 100%;
			}

			p:not(:last-child) {
				margin-bottom: 0.75rem;
			}
		}

		&-bot-mobile {
			grid-template-columns: 1fr;
			gap: .75rem;
			margin-top: 1.5rem;
		}

		&-help {
			&-btn {
				font-size: var(--fs-h1-small);
				padding-bottom: .5rem;
				border-bottom: 1px solid var(--font-gray-1, #596175);
				gap: 1rem;

				&--small {
					font-size: var(--fs-h1-small);
				}

				svg {
					width: 1rem;
					height: 1rem;
				}
			}
		}

		&-dev {
			.c-main {
				color: var(--ui-accent-2);

				@include hover {
					color: var(--ui-accent-1);
				}
			}
		}

		&-also-grid-itm {
			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}

	.footer-also {
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;
		border-bottom: 1px solid var(--ui-gray-1);
		margin-top: 0;

		&__title {
			font-size: var(--fs-h6);
			margin-bottom: 1rem;
		}

		&-grid {
			&-itm {
				padding-bottom: 1rem;

				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}
}