.contact-block {
	position: relative;
	padding-top: 1.5rem;
	z-index: 2;

	&__content {
		pointer-events: auto;
		width: 33%;

		&.w100 {
			width: 100%;
		}
	}
}

.contact-tel {
	@extend .h1-small;
	margin-bottom: .25rem;
	display: inline-block;
}

.contact-email {
	@extend .h4;
}

.contact-office {

	&-itm {
		cursor: pointer;
		margin-bottom: 3rem;

		&.active {
			cursor: auto;

			.contact-office-itm__name {
				color: var(--ui-accent-1);
			}
		}

		&__name {
			@extend .h3;
			margin-bottom: .75rem;
			transition: color .3s;

			@include hover {
				color: var(--ui-accent-1);
			}
		}

		&__place {
			margin-bottom: .75rem;
			font-family: $F_title;
			line-height: 120%;
			font-weight: 500;
		}

		&__person-position {
			font-size: var(--fs-small);
			color: var(--font-gray-1);
			margin-bottom: .25rem;
		}

		&__person-name {
			letter-spacing: 0.02rem;
			line-height: 110%;
		}

		@include hover {
			.contact-office-itm__name {
				color: var(--ui-accent-1);
			}
		}
	}
}

.contact-info {
	margin-bottom: 2rem;
}

.contact-page {
	position: relative;
	overflow: hidden;
	min-height: 46rem;

	.page-head-nav {
		z-index: 2;
		position: relative;
	}

	&-content {
		pointer-events: none;

		.page-head-nav ul {
			pointer-events: auto;
		}
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 50vw;
		background: linear-gradient(270deg, rgba(240, 243, 250, 0.00) 0%, #F0F3FA 59.22%);
		height: 100%;
		z-index: 1;
		pointer-events: none;
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 20rem;
		width: 100%;
		z-index: 1;
		background: linear-gradient(0deg, rgba(240, 243, 250, 0.00) 0%, #F0F3FA 100%);
		pointer-events: none;
	}
}

#contact-map {
	@include fill();
	transition: all .3s;

	&.hide-map {
		opacity: 0;
	}

	[class*=-ground-pane] * {
		filter: grayscale(1);
	}

	[class*=-controls-pane] {
		width: 100%;
		height: 100%;
		pointer-events: none;
		top: 0;
	}

	[class*=-controls__control] {
		width: 100%;
		height: 100%;
		pointer-events: none;
		inset: 0 !important;
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		height: 12.5rem;
		width: 100%;
		background: linear-gradient(180deg, rgba(240, 243, 250, 0.00) 0%, #F0F3FA 100%);
	}
}

.ymap-zoom-block {
	position: absolute;
	right: 2.5rem;
	top: 12rem;
	pointer-events: auto;

	#zoom-in {
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			height: 1.5rem;
			width: 2px;
			background: var(--font-gray-2);
			transform: translateX(-50%) translateY(-50%);
			transition: all .2s;
		}
	}

	&__itm {
		position: relative;
		width: 2.5rem;
		height: 2.5rem;
		border: 2px solid var(--font-gray-2, #93949A);
		transition: all .2s;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			width: 1.5rem;
			height: 2px;
			background: var(--font-gray-2);
			transform: translateX(-50%) translateY(-50%);
			transition: all .2s;
		}

		&:not(:last-child) {
			margin-bottom: .5rem;
		}

		@include hover {
			background: var(--font-gray-2);

			&:before, &:after {
				background: #fff !important;
			}
		}
	}

}

.contact-table {
	display: flex;
	gap: 1rem 5rem;
	margin-bottom: 4rem;

	&-col {
		&-itm {
			margin-bottom: 1.7rem;
		}
	}

	&__desc {
		color: var(--font-gray-1, #596175);
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 400;
		line-height: 110%;
		letter-spacing: 0.015rem;
		margin-bottom: 0.1rem;
	}

	&__val {
		font-weight: 400;
		line-height: 110%; /* 1.1rem */
		letter-spacing: 0.02rem;

		&:not(:last-child) {
			margin-bottom: 1.7rem;
		}
	}
}

@include for-tablet {
	.contact-tel {
		margin-bottom: 1rem;
	}
	.contact-email {
		font-size: var(--fs-h3);
	}
	.contact-page {
		.page-head-nav {
			display: none;
		}
	}
	.contact-block__content {
		width: 100%;
		height: auto !important;
		opacity: 1 !important;
		transform: none !important;
	}

	.contact-info {
		border-bottom: 1px solid var(--ui-stroke);
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;
	}

	.contact-office {
		border-bottom: 1px solid #C6CBD6;
		margin-bottom: 1.5rem;

		&__tablet-title {
			margin-bottom: 1.5rem;
			font-size: var(--fs-h2);
		}

		&-itm {
			margin-bottom: 1.5rem;

			&__name {
				font-size: var(--fs-h4);
				margin-bottom: .25rem;
			}

			&__person-name {
				letter-spacing: 0.02rem;
			}

			&__place {
				margin-bottom: 0.5rem;
				font-family: $F_main;
			}

			&__map-link {
				display: inline-flex;
				align-items: center;
				font-family: $F_title;
				color: var(--ui-accent-1);
				font-weight: 500;
				margin-top: .5rem;

				svg {
					width: 1rem;
					height: 1rem;
					margin-left: .25rem;
				}
			}
		}
	}

	.contact-office-itm.active .contact-office-itm__name {
		color: inherit;
	}

	.contact-table {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		.contact-table-col {
			&:nth-child(2) {
				order: 2;
				grid-column-start: 1;
				grid-column-end: 3;
				display: flex;
				flex-wrap: wrap;

				.contact-table-col-itm {
					&:not(:last-child) {
						margin-right: 1.5rem;
					}
				}
			}

			&-itm {
				margin-bottom: 0;
			}
		}

		&__desc {
			font-size: var(--fs-small);
		}

		&__val {
			font-size: var(--fs-h6);

			&:not(:last-child) {
				margin-bottom: .75rem;
			}
		}
	}


}

@include for-phone {
	.contact-tel {
		margin-bottom: .75rem;
	}
	.contact-table {
		grid-template-columns: 1fr;
	}

	.contact-table .contact-table-col:nth-child(2) {
		grid-column-end: 1;
	}

	.contact-info {
		margin-bottom: 1.5rem;
		padding-bottom: 1.25rem;
	}

	.contact-office__tablet-title {
		font-size: var(--fs-h1);
		margin-bottom: 1rem;
	}

	.contact-office-itm {
		&__name {
			font-size: var(--fs-h4);
			margin-bottom: .5rem;
			line-height: 100%; /* 1rem */
		}

		&__place {
			font-size: var(--fs-h6);
			line-height: 110%; /* 0.9625rem */
			letter-spacing: 0.0175rem;
			margin-bottom: .5rem;
		}
	}
}